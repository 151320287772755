import { Big } from 'big.js';
import {
  productsSelector,
  optionsSelector,
  productSelector,
  packagesSelector,
  findItemByUuid,
} from './content.selectors';
import type { ContentState } from './content.constants';
import { CONNECTIONS } from '../constants';
import { formatMoney } from '../../../../utils/display/number';

export const combineConnectionSpeeds = (
  content: ContentState,
): ContentState => {
  const state: any = { content }; // disable flow checking because this state doesn't reflect actual redux state
  const products = productsSelector(state);
  const broadband = productSelector(products, { product: 'Broadband' });
  const fibreSpeeds = optionsSelector(products, {
    product: 'Broadband',
    characteristic: 'BroadbandFibreConnectionSpeed',
  }).map((speed) => ({ ...speed, availability_required: CONNECTIONS.fibre }));
  const connectionTypes = optionsSelector(products, {
    product: 'Broadband',
    characteristic: 'BroadbandConnectionType',
  })
    .filter((type) => type.name.toLowerCase() !== CONNECTIONS.fibre)
    .map((type) => ({
      ...type,
      // make sure connection types come after fibre speeds
      sort_order: type.sort_order + fibreSpeeds.length,
    }));

  if (broadband) {
    broadband.characteristics.unshift({
      id: 'BroadbandSpeed',
      provisioning_key: 'BroadbandSpeed',
      name: '',
      description: '',
      required: true,
      options: fibreSpeeds.concat(connectionTypes),
      // @ts-ignore
      is_default: false,
    });
    const broadbandIndex = products.findIndex(
      (product) => product.provisioning_key === 'Broadband',
    );
    products[broadbandIndex] = broadband;
  }
  return {
    ...content,
    products,
  };
};

// Basically gets the total price of the possible options in a package i.e. Connection Speed and Data Cap
export const packagesPriceInfoAdapter = (
  content: ContentState,
): ContentState => {
  const state: any = { content }; // disable flow checking because this state doesn't reflect actual redux state
  const packages = packagesSelector(state);

  const result = packages.map((pkg) => {
    if (!pkg) return pkg;

    const dataCap = findItemByUuid(content, pkg.included_data_cap);
    const connectionType = findItemByUuid(
      content,
      pkg.included_connection_type,
    );
    // Connection Speed defaults to either ADSL or VDSL if Fibre isn't available
    let connectionSpeed = connectionType;

    if (
      connectionType &&
      // @ts-ignore
      connectionType.item &&
      // @ts-ignore
      connectionType.item.availability_required === CONNECTIONS.fibre
    ) {
      connectionSpeed = findItemByUuid(content, pkg.included_fibre_speed);
    }
    const additional = [];
    pkg.additional_products.forEach((productId) =>
      additional.push(findItemByUuid(content, productId)),
    );
    pkg.additional_product_characteristics.forEach((optionId) =>
      additional.push(findItemByUuid(content, optionId)),
    );
    const additionalTotal: Big = additional.reduce((subTotal: Big, found) => {
      if (!found || !found.item) return subTotal;

      // Replace the original product with the discounted version.

      // Prioritise discounted products within Included Offers
      if (pkg.included_offer && pkg.included_offer.discount_products) {
        const discountedProduct = pkg.included_offer.discount_products.find(
          (product) => product.replaces_product === found.item.id,
        );
        return subTotal.add(
          (discountedProduct && discountedProduct.price_amount) ||
            found.item.price_amount ||
            0,
        );
      }
      // Then discounted products within Packages
      if (pkg.discount_products) {
        const discountedProduct = pkg.discount_products.find(
          (product) => product.replaces_product === found.item.id,
        );
        return subTotal.add(
          (discountedProduct && discountedProduct.price_amount) ||
            found.item.price_amount ||
            0,
        );
      }
      return subTotal.add(found.item.price_amount || 0);
    }, Big(0));
    const total = Big(
      connectionSpeed &&
        // @ts-ignore
        connectionSpeed.item &&
        // @ts-ignore
        connectionSpeed.item.price_amount,
    )
      // @ts-ignore
      .add(Big(dataCap && dataCap.item && dataCap.item.price_amount))
      .add(additionalTotal);

    return {
      ...pkg,
      price_amount: formatMoney(total, true),
      payment_frequency: 'monthly',
    };
  });

  return {
    ...content,
    packages: result,
  };
};
