//

import { all, put, take, takeLatest } from 'redux-saga/effects';
import { INITIALISE_DATA, type InitialiseDataAction } from './App.actions';
import {
  fetchAvailability,
  FETCH_AVAILABILITY_SUCCESS,
} from '../state/availability/availability.actions';
import { fetchContent } from '../state/content/content.actions';
import { fetchPackage } from '../state/package/package.actions';

function* initialiseData({ addressId, packageId }: InitialiseDataAction) {
  yield all([put(fetchAvailability(addressId)), put(fetchContent())]);
  yield take(FETCH_AVAILABILITY_SUCCESS);
  if (packageId) {
    yield put(fetchPackage(packageId));
  }
}

export default function* saga() {
  yield takeLatest(INITIALISE_DATA, initialiseData);
}
