import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, bindActionCreators, type Dispatch } from 'redux';
import ChooseRouterScreen from './ChooseRouterScreen';
import {
  extraInfoSelector,
  isChosenSpeedFibreSelector,
  noRouterImageSelector,
  routerOptionsSelector,
  routerPriceMonthsSelector,
  routerPriceSelector,
  willShow24MonthsPaymentSelector,
} from './ChooseRouterScreen.selectors';
import { selectRouterSelector } from '../Screen.selectors';
import { FORM_NAME } from '../Screen.constants';
import { prevScreen, nextScreen } from '../Screen.actions';
import validate from './ChooseRouterScreen.validation';

function mapStateToProps(state) {
  return {
    extraInfo: extraInfoSelector(state),
    routerOptions: routerOptionsSelector(state),
    selectedRouter: selectRouterSelector(state),
    routerPrice: routerPriceSelector(state),
    routerPriceMonths: routerPriceMonthsSelector(state),
    willShow24MonthsPaymentSelector: willShow24MonthsPaymentSelector(state),
    isFibre: isChosenSpeedFibreSelector(state),
    noRouterImage: noRouterImageSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onSubmit: () => dispatch(nextScreen()),
    ...bindActionCreators(
      {
        prevScreen,
      },
      dispatch,
    ),
  };
}

export default compose(
  connect(
    mapStateToProps,
    // @ts-ignore
    mapDispatchToProps,
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate,
    keepDirtyOnReinitialize: true, // Don't override user selections
    updateUnregisteredFields: true, // make sure initial values are set for all
  }),
)(ChooseRouterScreen);
