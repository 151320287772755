//
import { type OptionWithDiscount } from '../../apps/planPicker/state/content/content.constants';

export const REQUIRED = 'Required';

export const required = (value: any) =>
  value || typeof value === 'number' ? undefined : REQUIRED;

export const requiredAvailable = (
  value: string,
  options: Array<OptionWithDiscount>,
) =>
  options.find((opt) => opt.provisioning_key === value) ? undefined : REQUIRED;
