import { Fragment } from 'react';

export const SERVER_ERROR = 'SERVER_ERROR';
export const NO_AVAILABILITY = 'NO_AVAILABILITY';

export type ErrorType = typeof SERVER_ERROR | typeof NO_AVAILABILITY;

export type ErrorMessage = {
  title: string;
  description: JSX.Element;
};

export const NoAvailabilityErrorMessage: ErrorMessage = {
  title: "It looks like you're off the grid.",
  description: (
    <Fragment>
      We&apos;re struggling to find if you can get internet services at your
      place. This often happens if your home is in a new subdivision, or
      you&apos;re out in the wop wops (lucky you it&apos;s probably quite
      peaceful). If you think you should be able to get the internet where you
      are, give our team a call on <a href="tel:0800438669">0800 438 669</a> and
      we&apos;ll look into it.
    </Fragment>
  ),
};

export const ServerErrorMessage: ErrorMessage = {
  title: "It's not you, it's us.",
  description: (
    <Fragment>
      We&apos;re struggling to find what internet services are available at your
      address. The easiest way to get to the bottom of this is to call our team
      on <a href="tel:0800438669">0800 438 669</a> (Mon-Fri 8am - 6pm; Sat 9am -
      1pm).
    </Fragment>
  ),
};

type Errors = Record<ErrorType, ErrorMessage>;

export const errors: Errors = {
  [SERVER_ERROR]: ServerErrorMessage,
  [NO_AVAILABILITY]: NoAvailabilityErrorMessage,
};
