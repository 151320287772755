import { formatMoney } from '../../../../utils/display/number';

type DollarGroupProps = {
  amount: string;
  paymentFrequency?: string;
};

export default function DollarGroup({
  amount,
  paymentFrequency = 'per month',
}: DollarGroupProps) {
  return (
    <div className="dollars__group dollars--small">
      {amount !== '0.00' && (
        <p className="u-text-s">
          <span className="u-text-medium">+ ${formatMoney(amount)}</span>
          <br />
          <span className="dollars__period">{paymentFrequency}</span>
        </p>
      )}
    </div>
  );
}
