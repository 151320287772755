import {
  FETCH_PACKAGE,
  FETCH_PACKAGE_SUCCESS,
  FETCH_PACKAGE_FAILED,
  type FetchPackageSuccessAction,
  type FetchPackageFailedAction,
} from './package.actions';
import type { PackageState as State } from './package.constants';

const DEFAULT: State = {
  fetching: false,
};

const handlers = {
  [FETCH_PACKAGE](state: State): State {
    return {
      ...state,
      fetching: true,
    };
  },
  [FETCH_PACKAGE_SUCCESS](
    state: State,
    { payload }: FetchPackageSuccessAction,
  ): State {
    return {
      ...state,
      ...payload,
      fetching: false,
    };
  },
  [FETCH_PACKAGE_FAILED](state, { error }: FetchPackageFailedAction): State {
    return {
      ...state,
      error,
      fetching: false,
    };
  },
};

export default function (
  state: State = DEFAULT,
  action: { type: string },
): State {
  return handlers[action.type] ? handlers[action.type](state, action) : state;
}
