import { Fragment } from 'react';
import classnames from 'classnames';
import { Field } from 'redux-form';
import Icon from '../../../../components/Icon/Icon';

type ChoiceInputProps = {
  id?: string;
  type: 'radio' | 'checkbox';
  name: string;
  value: string;
  label: string;
  sublabel?: string | JSX.Element;
  img?: {
    src: string;
    alt: string;
  };
};

function ChoiceInput({
  id,
  type,
  name,
  value,
  label,
  sublabel,
  img,
}: ChoiceInputProps) {
  const inputId = id || `${name}-${value}`;
  return (
    <Fragment>
      <Field
        className={`${type}__input`}
        component="input"
        type={type}
        name={name}
        value={value}
        id={inputId}
      />

      <label
        htmlFor={inputId}
        className={classnames(`${type}-container`, {
          [`${type}-container--has-img`]: !!img,
        })}
      >
        <span className={`${type}__custom-input`}>
          {type === 'checkbox' && (
            <Icon
              title="Check"
              type="icon-checked"
              className="i--green checkbox__svg"
            />
          )}
        </span>
        <div className={`${type}__row`}>
          <div className={`${type}__col`}>
            <span className={`label--${type}`}>{label}</span>
            <span
              className={classnames('label--helptext', {
                'u-hide@small': img,
              })}
            >
              {sublabel}
            </span>
          </div>
          {img && (
            <div className={`${type}__col`}>
              <img className={`${type}__img`} src={img.src} alt={img.alt} />
              <span className="label--helptext u-hide@medium">{sublabel}</span>
            </div>
          )}
        </div>
      </label>
    </Fragment>
  );
}

export default ChoiceInput;
