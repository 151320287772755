import { createSelector } from 'reselect';
import intersection from 'lodash/intersection';
import { type State } from '../constants';
import { type PackageState, type Package } from './package.constants';
import {
  type ContentState,
  type Product,
  type Characteristic,
  type Option,
} from '../content/content.constants';
import { contentSelector, findItemByUuid } from '../content/content.selectors';

export const packageSelector = (state: State) => state && state.package;

export const findPackageDataCap = (
  pkg?: PackageState,
  content?: ContentState,
) => {
  if (!pkg) return null;
  return findItemByUuid(content, pkg.included_data_cap);
};

export const packageDataCapsSelector = createSelector(
  packageSelector,
  contentSelector,
  findPackageDataCap,
);

// A package can include a package that shows in the plan picker to preselect it
export const packageIncludedPackageIdSelector = createSelector(
  packageSelector,
  (pkg?: PackageState) => (pkg ? pkg.included_package : null),
);

export const findPackageSpeed = (
  pkg?: PackageState,
  content?: ContentState,
) => {
  if (!pkg) return null;
  if (pkg.included_fibre_speed) {
    return findItemByUuid(content, pkg.included_fibre_speed);
  }
  return findItemByUuid(content, pkg.included_connection_type);
};

export const packageSpeedSelector = createSelector(
  packageSelector,
  contentSelector,
  findPackageSpeed,
);

export const findPackageContract = (
  pkg?: PackageState,
  content?: ContentState,
) => {
  if (!pkg) return null;
  return findItemByUuid(content, pkg.included_contract_term);
};

export const packageContractSelector = createSelector(
  packageSelector,
  contentSelector,
  findPackageContract,
);

export const findPackageExtras = (
  pkg?: PackageState,
  content?: ContentState,
): (Product | Characteristic | Option)[] => {
  if (!pkg) return null;

  // Collect discounted products from package and offers
  const packageOfferDiscounts = pkg.included_offer
    ? pkg.included_offer.discount_products
    : [];
  const discountedProducts = pkg.discount_products
    ? pkg.discount_products.reduce((list, product) => {
        // @ts-ignore
        if (list.includes(product.id)) return list;

        list.push(product);
        return list;
      }, packageOfferDiscounts)
    : packageOfferDiscounts;
  const optionsData = pkg.additional_product_characteristics
    ? pkg.additional_product_characteristics.reduce((list, id) => {
        const itemData = findItemByUuid(content, id);
        if (!itemData) {
          return list;
        }
        // Override existing products/options with discounted product/option
        const discountProduct = discountedProducts.find(
          (product) => product.replaces_product === id,
        );
        if (discountProduct) {
          list.push({
            ...itemData,
            item: {
              // @ts-ignore
              ...itemData.item,
              id: discountProduct.id,
              price_amount: discountProduct.price_amount,
              name: discountProduct.name,
              summary_name: discountProduct.summary_name,
              description: discountProduct.description,
              provisioning_key: discountProduct.provisioning_key,
            },
          });
        } else {
          list.push(itemData);
        }

        return list;
      }, [])
    : [];
  const products = pkg.additional_products
    ? pkg.additional_products.reduce((list, id) => {
        const itemData = findItemByUuid(content, id);
        if (!itemData) return list;
        // Don't include product if it's free and one of its options is included
        const matchingOption = optionsData.find(
          (optionData) =>
            // @ts-ignore
            optionData.path.product === itemData.item.provisioning_key,
        );
        // @ts-ignore
        if (matchingOption && itemData.item.price_amount === '0.00')
          return list;
        // Override existing products/options with discounted product/option
        const discountProduct = discountedProducts.find(
          (product) => product.replaces_product === id,
        );

        if (discountProduct) {
          list.push({
            // @ts-ignore
            ...itemData.item,
            id: discountProduct.id,
            price_amount: discountProduct.price_amount,
            name: discountProduct.name,
            summary_name: discountProduct.summary_name,
            description: discountProduct.description,
            provisioning_key: discountProduct.provisioning_key,
          });
        } else {
          // @ts-ignore
          list.push(itemData.item);
        }

        return list;
      }, [])
    : [];
  const options = optionsData.map((optionData) => optionData.item);
  const result = products.concat(options);
  return result.length > 0 ? result : null;
};

export const packageExtrasSelector = createSelector(
  packageSelector,
  contentSelector,
  findPackageExtras,
);

export const packageInfoSelector = createSelector(
  packageDataCapsSelector,
  packageSpeedSelector,
  packageContractSelector,
  packageIncludedPackageIdSelector,
  (dataCap, speed, contract, packageId) => ({
    // @ts-ignore
    dataCap: dataCap && dataCap.item && dataCap.item.provisioning_key,
    // @ts-ignore
    speed: speed && speed.item && speed.item.provisioning_key,
    // @ts-ignore
    contract: contract && contract.item && contract.item.provisioning_key,
    includedPackage: packageId,
  }),
);

export const isPackageAvailable = (pkg?: Package, availability?: string[]) => {
  if (!pkg) return false;
  const requirements = pkg.availability_required;
  if (!requirements) return true; // Nothing's required!
  return (
    requirements &&
    intersection(requirements, availability).length === requirements.length
  );
};
