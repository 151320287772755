// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-acc-field {
  display: flex;
  flex-wrap: nowrap;
}
.bank-acc-field > div {
  display: flex;
  flex-direction: column;
}
.bank-acc-field > div > div {
  order: 2;
}`, "",{"version":3,"sources":["webpack://./core/static_src/js/apps/planPicker/components/BankAccField/bank-acc-field.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;AACJ;AACI;EACE,QAAA;AACN","sourcesContent":[".bank-acc-field {\n  display: flex;\n  flex-wrap: nowrap;\n\n  & > div {\n    display: flex;\n    flex-direction: column;\n\n    & > div {\n      order: 2;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
