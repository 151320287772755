//

import type { ScreensState } from '../screens/Screen.constants';
import type { Assets, ContentState } from './content/content.constants';
import type { OfferState } from './offer/offer.constants';
import type { PackageState } from './package/package.constants';

export type Address = {
  name?: string;
  line1?: string;
  line2?: string;
  suburb?: string;
  city?: string;
  postcode?: string;
};

type RouterPickup = {
  is_router_pickup: boolean;
  router_pickup_address: string;
};

export type State = {
  screens: ScreensState;
  content: ContentState;
  form;
  availability: {
    // adsl: boolean;
    // vdsl: boolean;
    // fibre: boolean;
    is_adsl: boolean;
    is_vdsl: boolean;
    is_fibre: boolean;
    is_voice: boolean;
    is_techspert: boolean;
    formatted_address: Address;
    router_pickup: RouterPickup;
    fetching: boolean;
  };
  package: PackageState;
  offer: OfferState;
  assets: Assets;
};

export const CONNECTIONS = {
  adsl: 'adsl',
  vdsl: 'vdsl',
  fibre: 'fibre',
};

export const EXTRAS = {
  voice: 'voice',
  techspert: 'techspert',
};

export type Connections = keyof typeof CONNECTIONS;
export type Extras = keyof typeof EXTRAS;
export type AvailabilityTypes = Connections & Extras;
