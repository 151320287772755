import { createSelector } from 'reselect';
import {
  bankAccountNameSelector,
  bankAccountOtherNameSelector,
} from '../Screen.selectors';
import { contractSelector } from '../../components/CartSummary/CartSummary.selectors';
import { OPTIONS } from '../Screen.constants';
import { assetsSelector, cmsSelector } from '../../state/content/content.selectors';

export const bankNameSelector = createSelector(
  bankAccountNameSelector,
  bankAccountOtherNameSelector,
  (bankName, bankNameOther) =>
    (bankName && bankName.toLowerCase()) ===
    OPTIONS.BANK_NAME.OTHER.value.toLowerCase()
      ? bankNameOther
      : bankName,
);

export const willShowContractCheckboxSelector = createSelector(
  contractSelector,
  (contract) => !!contract && contract.fixed_term,
);

// eslint-disable-next-line import/prefer-default-export
export const reviewCMSSelector = createSelector(
  cmsSelector,
  (cms) => cms && cms.review,
);

export const directDebitTermsAndConditionsSelector = createSelector(
  assetsSelector,
  (assets): string => assets?.['terms_and_conditions_pdf'],
);