import type { DiscountProduct } from '../content/content.constants';
import type { Offer } from '../offer/offer.constants';

export type Package = {
  id: string;
  name: string;
  description: string;
  show_in_picker: boolean;
  included_connection_type: string;
  included_fibre_speed: string;
  included_data_cap: string;
  included_contract_term: string;
  included_package: string;
  included_offer?: Offer;
  additional_products: Array<string>;
  additional_product_characteristics: Array<string>;
  availability_required: Array<string>;
  price_amount: string;
  payment_frequency: string;
  discount_products: Array<DiscountProduct>;
};

export type PackageState = Partial<Package> & {
  fetching: boolean;
  error?: string;
};

// eslint-disable-next-line
export const PACKAGE_NOT_AVAILABLE_ERROR = {
  PACKAGE_NOT_AVAILABLE: 'PACKAGE_NOT_AVAILABLE',
};
