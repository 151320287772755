//
import { createSelector } from 'reselect';
import Big from 'big.js';
import { availabilitySelector } from '../../state/availability/availability.selectors';
import {
  currentScreenSelector,
  chosenPackageSelector,
  chosenBroadbandDataCapSelector,
  chosenBroadbandConnectionSpeedSelector,
  chosenContractSelector,
  isRouterMonthlyPaymentSelector,
  phoneBundleSelectedSelector,
  landlineCallingSelector,
  landlineCallingOptionSelector,
  mobileCallingSelector,
  mobileCallingOptionSelector,
  techAssistSelector,
  chosenBillDeliverySelector,
  hardwareDeliverySelector,
  hasIncludedRouterSelector,
  routerPaymentMonthsSelector,
  selectRouterSelector,
} from '../../screens/Screen.selectors';
import {
  broadbandDataCapSelector,
  broadbandConnectionSpeedSelector,
} from '../../screens/PlanDetailsScreen/PlanDetailsScreen.selectors';

import {
  techAssistOptionsSelector,
  phonelineSelectedSelector,
  phoneLineSelector as phoneLineProductSelector,
  phoneBundleSelector as phoneBundleProductSelector,
  landlineCallingOptionsSelector,
  mobileCallingOptionsSelector,
} from '../../screens/ExtrasScreen/ExtrasScreen.selectors';

import {
  optionSelector,
  contractsSelector,
  contractSelector as contractOptionSelector,
  productsSelector,
  productSelector,
} from '../../state/content/content.selectors';
import type { Option, Product } from '../../state/content/content.constants';
import {
  SCREENS,
  OPTIONS,
  NO_CONTRACT_KEY,
  FIELDNAMES,
  fieldnameToProduct,
} from '../../screens/Screen.constants';
import {
  offerSelector,
  offerDefaultContractSelector,
} from '../../state/offer/offer.selectors';
import { offerInvalidErrorSelector } from '../../state/offer/offer.validation.selectors';
import { routerOptionsSelector } from '../../screens/ChooseRouterScreen/ChooseRouterScreen.selectors';

const { TECH_ASSIST } = FIELDNAMES;

export const planSelector = createSelector(
  broadbandDataCapSelector,
  chosenBroadbandDataCapSelector,
  (options, userSelection) => optionSelector(options, userSelection),
);

export const speedSelector = createSelector(
  broadbandConnectionSpeedSelector,
  chosenBroadbandConnectionSpeedSelector,
  (options, userSelection) => optionSelector(options, userSelection),
);

export const routerSelector = createSelector(
  hasIncludedRouterSelector,
  routerOptionsSelector,
  selectRouterSelector,
  (hasIncludedRouter, options, userSelection): Option | undefined =>
    hasIncludedRouter
      ? optionSelector(
          // @ts-ignore
          options,
          userSelection,
        )
      : null,
);

export const willShowRouterSelector = createSelector(
  hasIncludedRouterSelector,
  (hasIncludedRouter): boolean => hasIncludedRouter,
);

export const routerDeliveryFeeSelector = createSelector(
  productsSelector,
  hasIncludedRouterSelector,
  hardwareDeliverySelector,
  (products, hasIncludedRouter, hardwareDeliveryOption): Product | null => {
    if (!hasIncludedRouter) return null;

    return hardwareDeliveryOption !== OPTIONS.HARDWARE_DELIVERY.PICK_UP
      ? productSelector(products, { product: 'router_delivery__local' })
      : null;
  },
);

export const willShowRouterDeliveryFeeSelector = createSelector(
  routerDeliveryFeeSelector,
  (routerDeliveryFee) => !!routerDeliveryFee,
);

export const willPayRouterMonthlySelector = createSelector(
  routerSelector,
  isRouterMonthlyPaymentSelector,
  (router, monthly) => router && router.price_amount !== '0.00' && !!monthly,
);

export const routerMonthlyPaymentSelector = createSelector(
  routerSelector,
  willPayRouterMonthlySelector,
  routerPaymentMonthsSelector,
  (router, isMonthly, months) => {
    if (!router) return null;
    if (router && isMonthly) {
      return Big(router.price_amount).div(months).toString();
    }

    return router.price_amount;
  },
);

export const phoneLineSelector = createSelector(
  phoneLineProductSelector,
  phonelineSelectedSelector,
  (phoneLine, isSelected) => (isSelected ? phoneLine : null),
);

export const phoneBundleSelector = createSelector(
  phoneBundleProductSelector,
  phoneBundleSelectedSelector,
  phoneLineSelector,
  (phoneBundle, isSelected, isPhoneLineSelected) =>
    isPhoneLineSelected && isSelected ? phoneBundle : null,
);

export const landlineOptionSelector = createSelector(
  landlineCallingOptionsSelector,
  landlineCallingOptionSelector,
  (options, userSelection) => optionSelector(options, userSelection),
);

export const chosenLandlineOptionSelector = createSelector(
  landlineOptionSelector,
  landlineCallingSelector,
  phoneLineSelector,
  (landlineOption, isSelected, isPhoneLineSelected) =>
    isPhoneLineSelected && isSelected ? landlineOption : null,
);

export const mobileOptionSelector = createSelector(
  mobileCallingOptionsSelector,
  mobileCallingOptionSelector,
  (options, userSelection) => optionSelector(options, userSelection),
);

export const chosenMobileOptionSelector = createSelector(
  mobileOptionSelector,
  mobileCallingSelector,
  phoneLineSelector,
  (mobileOption, isSelected, isPhoneLineSelected) =>
    isPhoneLineSelected && isSelected ? mobileOption : null,
);

export const techAssistOptionSelector = createSelector(
  techAssistOptionsSelector,
  techAssistSelector,
  (options, userSelection) =>
    optionSelector(
      // @ts-ignore
      options,
      userSelection,
    ),
);

export const willShowTechAssistSelector = createSelector(
  productsSelector,
  chosenPackageSelector,
  techAssistOptionSelector,
  (products, pkg, techAssist): boolean => {
    const product = productSelector(products, fieldnameToProduct[TECH_ASSIST]);

    // Will not show Tech Assist section if it's already included in the package.
    if (pkg && pkg.additional_products.includes(product && product.id))
      return false;

    return !!(techAssist && techAssist.price_amount !== '0.00');
  },
);

export const addressSelector = createSelector(
  availabilitySelector,
  (availability) => availability.formatted_address,
);

export const contractSelector = createSelector(
  contractsSelector,
  chosenContractSelector,
  offerDefaultContractSelector,
  (contracts, userSelection, offerDefaultContract) =>
    offerDefaultContract || contractOptionSelector(contracts, userSelection),
);

export const oneOffFeeContractNameSelector = createSelector(
  contractSelector,
  (contract) => {
    if (contract) {
      return contract.provisioning_key === NO_CONTRACT_KEY
        ? 'Connection fee'
        : contract.name;
    }
    return '';
  },
);

export const willShowContractInSummarySelector = createSelector(
  contractSelector,
  (contract) => !!contract && contract.fixed_term,
);

export const willShowExtrasSelector = createSelector(
  currentScreenSelector,
  (currentScreen) => SCREENS.Extras <= currentScreen,
);

export const willShowOneOffPaymentSelector = createSelector(
  hasIncludedRouterSelector,
  contractSelector,
  (router, contract): boolean =>
    router || !!(contract && contract.price_amount > 0),
);

export const willShowMonthlyPromo = createSelector(
  offerSelector,
  (offer) => !!(offer && offer.discount_frequency === 'monthly'),
);
export const willShowOneOffPromo = createSelector(
  offerSelector,
  (offer) => !!(offer && offer.discount_frequency === 'once'),
);

export const monthlyPaymentSelector = createSelector(
  chosenPackageSelector,
  planSelector,
  speedSelector,
  techAssistOptionSelector,
  phoneLineSelector,
  phoneBundleSelector,
  chosenLandlineOptionSelector,
  chosenMobileOptionSelector,
  offerSelector,
  willShowMonthlyPromo,
  offerInvalidErrorSelector,
  chosenBillDeliverySelector,
  (
    packagedPlan,
    dataCap,
    speed,
    techAssist,
    phoneline,
    phoneBundle,
    landline,
    mobile,
    offer,
    isOfferMonthly,
    offerError,
    billDelivery,
  ) => {
    let total = [
      packagedPlan ? packagedPlan.price_amount : 0,
      dataCap && !packagedPlan ? dataCap.price_amount : 0,
      speed && !packagedPlan ? speed.price_amount : 0,
      techAssist ? techAssist.price_amount : 0,
      phoneline ? phoneline.price_amount : 0,
      phoneBundle ? phoneBundle.price_amount : 0,
      landline ? landline.price_amount : 0,
      mobile ? mobile.price_amount : 0,
      billDelivery ? billDelivery.price_amount : 0,
    ].reduce((prev, curr = 0) => Big(prev).plus(curr), 0);
    if (isOfferMonthly && offer && !offerError) {
      total = Big(total).minus(offer.discount_amount);
      total = Big(total).lt(0) ? Big(0) : total;
    }
    return total;
  },
);

export const oneOffPaymentSelector = createSelector(
  routerSelector,
  willPayRouterMonthlySelector,
  routerMonthlyPaymentSelector,
  routerDeliveryFeeSelector,
  contractSelector,
  offerSelector,
  willShowOneOffPromo,
  offerInvalidErrorSelector,
  (
    router,
    isRouterMonthly,
    routerMonthlyPrice,
    routerDeliveryFee,
    contract,
    offer,
    isOfferOneOff,
    offerError,
  ) => {
    let total = [
      router && !isRouterMonthly ? router.price_amount : 0,
      router && isRouterMonthly ? routerMonthlyPrice : 0,
      routerDeliveryFee ? routerDeliveryFee.price_amount : 0,
      contract && contract.price_amount,
    ].reduce((prev, curr = 0) => Big(prev).plus(curr), 0);
    if (isOfferOneOff && offer && !offerError) {
      total = Big(total).minus(offer.discount_amount);
      total = Big(total).lt(0) ? Big(0) : total;
    }
    return total;
  },
);
