import { SCREENS, type Screen } from '../../screens/Screen.constants';
import Icon from '../../../../components/Icon/Icon';
import './progressTracker.scss';

// future means it comes after the current screen and is gray and not clickable
type SectionStatus = 'future' | 'current' | 'complete' | 'skipped';

type SectionProps = {
  status: SectionStatus;
  screen: Screen;
  goToScreen: Function;
  label: string;
  step: number;
  total?: number;
};

const ProgressSection = ({
  status,
  screen,
  goToScreen,
  label,
  step,
  total = 6,
}: SectionProps) => (
  <div
    className={`width-1/6@medium progress-tracker__section progress-tracker__section--${status}`}
  >
    <div className="u-hide@small">
      <span className="progress-tracker__line" />
      <button
        className={`progress-tracker__button progress-tracker__button--${status}`}
        type="button"
        onClick={() => goToScreen(screen)}
        disabled={['future', 'current'].includes(status)}
      >
        <Icon
          type="icon-checked"
          className="i--white progress-tracker__icon progress-tracker__icon--complete"
        />
        <Icon
          type="icon-cancel"
          className="progress-tracker__icon progress-tracker__icon--skipped"
        />
        <span className="progress-tracker__label">{label}</span>
      </button>
    </div>
    <div className="u-hide@medium">
      <p>
        <span className="u-text-medium">
          Step {step} of {total}:{' '}
        </span>
        {label}
      </p>
    </div>
  </div>
);

const getStatus = (
  screens: Array<Screen>,
  current: Screen,
  skipped?: Screen[],
): SectionStatus => {
  if (screens.includes(current)) {
    return 'current';
  }
  if (screens.reduce((result, screen) => result || current < screen, false)) {
    return 'future';
  } else if (screens.every((screen) => !!skipped?.includes(screen))) {
    return 'skipped';
  }
  return 'complete';
};

type Props = {
  currentScreen: Screen;
  goToScreen: Function;
  skipped: Screen[];
};

const ProgressTracker = ({ currentScreen, goToScreen, skipped }: Props) => (
  <div className="width-full u-overflow-hidden">
    <div className="grid-flex progress-tracker">
      <ProgressSection
        label="Choose plan"
        step={1}
        status={getStatus([SCREENS.PlanDetails], currentScreen, skipped)}
        screen={SCREENS.PlanDetails}
        goToScreen={goToScreen}
      />
      <ProgressSection
        label="Wi-Fi setup"
        step={2}
        status={getStatus([SCREENS.ChooseRouter], currentScreen, skipped)}
        screen={SCREENS.ChooseRouter}
        goToScreen={goToScreen}
      />
      <ProgressSection
        label="Extras"
        step={3}
        status={getStatus([SCREENS.Extras], currentScreen, skipped)}
        screen={SCREENS.Extras}
        goToScreen={goToScreen}
      />
      <ProgressSection
        label="Account details"
        step={4}
        status={getStatus(
          [SCREENS.PersonalAccount, SCREENS.ProviderTransfer],
          currentScreen,
          skipped,
        )}
        screen={SCREENS.PersonalAccount}
        goToScreen={goToScreen}
      />
      <ProgressSection
        label="Payment"
        step={5}
        status={getStatus([SCREENS.PaymentOption], currentScreen, skipped)}
        screen={SCREENS.PaymentOption}
        goToScreen={goToScreen}
      />
      <ProgressSection
        label="Review"
        step={6}
        status={getStatus([SCREENS.Review], currentScreen, skipped)}
        screen={SCREENS.Review}
        goToScreen={goToScreen}
      />
    </div>
  </div>
);

export default ProgressTracker;
