import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import ScreenReducer from '../screens/Screen.reducers';
import ContentReducer from './content/content.reducers';
import AvailabilityReducer from './availability/availability.reducers';
import OfferReducer from './offer/offer.reducers';
import PackageReducer from './package/package.reducers';

const reducers = combineReducers({
    screens: ScreenReducer,
    form: formReducer,
    content: ContentReducer,
    availability: AvailabilityReducer,
    offer: OfferReducer,
    package: PackageReducer,
});

export default reducers;
