//
import { createSelector } from 'reselect';
import {
  productsSelector,
  productSelector,
  characteristicSelector,
  optionsSelector,
  cmsSelector,
} from '../../state/content/content.selectors';
import { availableTypesSelector } from '../../state/availability/availability.selectors';
import { FIELDNAMES, fieldnameToProduct, OPTIONS } from '../Screen.constants';
import {
  selectPhonelineSelector,
  landlineCallingSelector,
  mobileCallingSelector as mobileCallingFormSelector,
  chosenPackageSelector,
  chosenPackageDiscountProductsSelector,
  selectDiscountProductOptions,
} from '../Screen.selectors';
import { type State, EXTRAS } from '../../state/constants';
import { offerDiscountProductsSelector } from '../../state/offer/offer.selectors';
import { isValidOfferSelector } from '../../state/offer/offer.validation.selectors';

const {
  SELECT_PHONELINE,
  PHONE_BUNDLE,
  LANDLINE_CALLING,
  LANDLINE_CALLING_OPTION,
  MOBILE_CALLING,
  MOBILE_CALLING_OPTION,
  TECH_ASSIST,
} = FIELDNAMES;

export const extrasCMSSelector = createSelector(
  cmsSelector,
  (cms) =>
    cms &&
    // @ts-ignore
    cms.extras,
);

export const phoneLineSelector = createSelector(productsSelector, (products) =>
  productSelector(products, fieldnameToProduct[SELECT_PHONELINE]),
);

export const phoneLineDefaultSelector = createSelector(
  phoneLineSelector,
  (product) =>
    product &&
    // @ts-ignore
    product.is_default === true &&
    product.provisioning_key,
);

export const phoneBundleSelector = createSelector(
  productsSelector,
  (products) => productSelector(products, fieldnameToProduct[PHONE_BUNDLE]),
);

export const phoneBundleDefaultSelector = createSelector(
  phoneBundleSelector,
  (product) =>
    product &&
    // @ts-ignore
    product.is_default === true,
);

export const landLineCallingSelector = createSelector(
  productsSelector,
  (products) => {
    const landlineCalling = characteristicSelector(
      products,
      fieldnameToProduct[LANDLINE_CALLING],
    );
    return landlineCalling;
  },
);

export const landlineCallingOptionsSelector = createSelector(
  productsSelector,
  (products) =>
    optionsSelector(products, fieldnameToProduct[LANDLINE_CALLING_OPTION]),
);

export const landlineCallingOptionsDefaultSelector = createSelector(
  landlineCallingOptionsSelector,
  (options) => {
    const result = options.find((option) => option.is_default === true);
    return result && result.provisioning_key;
  },
);

export const mobileCallingSelector = createSelector(
  productsSelector,
  (products) =>
    characteristicSelector(products, fieldnameToProduct[MOBILE_CALLING]),
);

export const mobileCallingOptionsSelector = createSelector(
  productsSelector,
  (products) =>
    optionsSelector(products, fieldnameToProduct[MOBILE_CALLING_OPTION]),
);

export const mobileCallingOptionsDefaultSelector = createSelector(
  mobileCallingOptionsSelector,
  (options) => {
    const result = options.find((option) => option.is_default === true);
    return result && result.provisioning_key;
  },
);

//
//
// *** Stages display ******

export const willShowSelectPhonelineSelector = (): boolean => true;

export const phonelineSelectedSelector: (State) => boolean = createSelector(
  selectPhonelineSelector,
  // "Phoneline selected?"
  (selectPhoneline): boolean =>
    selectPhoneline && selectPhoneline !== OPTIONS.SELECT_PHONELINE.NO,
);

export const willShowLandlineCallingSelector: (State) => boolean =
  createSelector(
    phonelineSelectedSelector,
    // "Phoneline selected?"
    (phonelineSelected): boolean => phonelineSelected,
  );

export const willShowLandlineCallingOptionSelector: (State) => boolean =
  createSelector(
    landlineCallingSelector,
    phonelineSelectedSelector,
    // "Phoneline selected?"
    // +
    // "Landline calling selected?"
    (landlineCalling, phonelineSelected): boolean =>
      phonelineSelected && landlineCalling,
  );

export const willShowMobileCallingSelector: (State) => boolean = createSelector(
  phonelineSelectedSelector,
  // "Phoneline selected?"
  (phonelineSelected: boolean) => phonelineSelected,
);

export const willShowMobileCallingOptionSelector: (State) => boolean =
  createSelector(
    mobileCallingFormSelector,
    phonelineSelectedSelector,
    // "Phoneline selected?"
    // +
    // "Landline calling selected?"
    (mobileCalling, phonelineSelected): boolean =>
      phonelineSelected && mobileCalling,
  );

export const willShowPhoneBundleSelector: (State) => boolean = createSelector(
  phonelineSelectedSelector,
  // "Phoneline selected?"
  (phonelineSelected): boolean => phonelineSelected,
);

export const willShowTechspertSelector = createSelector(
  availableTypesSelector,
  (availableTypes) => availableTypes.includes(EXTRAS.techspert),
);

export const techAssistOptionsSelector = createSelector(
  productsSelector,
  availableTypesSelector,
  offerDiscountProductsSelector,
  chosenPackageDiscountProductsSelector,
  isValidOfferSelector,
  (
    products,
    availableTypes,
    offerDiscountProducts,
    chosenPackageDiscountProducts,
    isOfferValid,
  ) => {
    const options = optionsSelector(products, fieldnameToProduct[TECH_ASSIST]);

    const filteredOptions = options.filter((option) => {
      if (option.availability_required === '') return true;

      if (!availableTypes) return false;

      return availableTypes.includes(option.availability_required);
    });

    return selectDiscountProductOptions(
      filteredOptions,
      offerDiscountProducts,
      chosenPackageDiscountProducts,
      isOfferValid,
    );
  },
);

export const techAssistOptionsDefaultSelector = createSelector(
  techAssistOptionsSelector,
  (options) => {
    const found = options.find(
      (option) =>
        // @ts-ignore
        option.is_default === true,
    );
    return found && found.provisioning_key;
  },
);

export const willShowTechAssistSelector = createSelector(
  productsSelector,
  chosenPackageSelector,
  (products, pkg) => {
    if (!pkg) return true;

    const product = productSelector(products, fieldnameToProduct[TECH_ASSIST]);
    // Will not show Tech Assist section if it's already included in the package.
    return !pkg.additional_products.includes(product && product.id);
  },
);
