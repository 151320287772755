import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, bindActionCreators, type Dispatch } from 'redux';
import ProviderTransferScreen from './ProviderTransferScreen';
import { prevScreen, nextScreen } from '../Screen.actions';
import { FORM_NAME } from '../Screen.constants';
import { phonelineSelectedSelector } from '../ExtrasScreen/ExtrasScreen.selectors';
import {
  chosenProviderSelector,
  chosenServiceTypeSelector,
  providerTransferDetailsSelector,
} from '../Screen.selectors';
import { type State } from '../../state/constants';
import { providerTransferCMSSelector } from './ProviderTransferScreen.selectors';
import { validate, asyncValidate } from './ProviderTransferScreen.validation';
import { post } from '../../../../utils/http/http';

function mapStateToProps(state: State) {
  return {
    phonelineSelected: phonelineSelectedSelector(state),
    chosenProvider: chosenProviderSelector(state),
    chosenServiceType: chosenServiceTypeSelector(state),
    cms: providerTransferCMSSelector(state),
    providerTransferData: providerTransferDetailsSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    ...bindActionCreators(
      {
        prevScreen,
      },
      dispatch,
    ),
  };
}

export default compose(
  connect(
    mapStateToProps,
    // @ts-ignore
    mapDispatchToProps,
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate,
    onSubmit: (
      // @ts-ignore
      values: any,
      dispatch,
      { providerTransferData },
    ) =>
      post({
        path: '/acquisitions/api/v1/validate/existing_provider/',
        payload: {
          provider: providerTransferData.provider,
          account_number: providerTransferData.accountNumber,
          existing_phone_number: providerTransferData.phoneNumber,
          has_existing_phone: providerTransferData.transferPhone,
          keep_phone_number: providerTransferData.keepPhoneNumber,
        },
      })
        .then(asyncValidate)
        .then(() => {
          dispatch(nextScreen());
        }),
  }),
)(
  // @ts-ignore
  ProviderTransferScreen,
);
