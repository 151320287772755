//

import { SCREENS, type ScreensState } from './Screen.constants';
import {
  GO_TO_SCREEN,
  type GoToScreenAction,
  SUBMIT_SCREEN_SUCCESS,
  SUBMIT_SCREEN_FAILED,
  ORDER_ID,
  type StoreOrderId,
  type SubmitScreenFailedAction,
  type SubmitScreenSuccessAction,
} from './Screen.actions';

const DEFAULT = {
  currentScreen: SCREENS.PlanDetails,
};

const handlers = {
  [GO_TO_SCREEN](state, { screen }: GoToScreenAction) {
    return {
      ...state,
      currentScreen: screen,
    };
  },
  [SUBMIT_SCREEN_SUCCESS](state, { orderId }: SubmitScreenSuccessAction) {
    return {
      ...state,
      error: '',
      orderId,
    };
  },
  [SUBMIT_SCREEN_FAILED](state, { error }: SubmitScreenFailedAction) {
    return {
      ...state,
      error,
    };
  },
  [ORDER_ID](state, { orderId }: StoreOrderId) {
    return {
      ...state,
      orderId,
    };
  },
};

export default function (
  state: ScreensState = DEFAULT,
  action: { type: string },
) {
  return handlers[action.type] ? handlers[action.type](state, action) : state;
}
