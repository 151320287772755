import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import {
  FIELDNAMES,
  OPTIONS,
  SCREEN_VALIDATION_ERROR_MESSAGE,
} from '../Screen.constants';
import type {
  CMSExtras,
  Option,
  OptionWithDiscount,
} from '../../state/content/content.constants';
import CartSummary from '../../components/CartSummary/CartSummary.container';
import ProgressTracker from '../../components/ProgressTracker/ProgressTracker.container';
import ErrorMessage from '../../components/InputError/InputErrorMessage';
import RouterCard from '../../components/RouterCard/RouterCard';
import FieldInputError from '../../components/InputError/InputErrorMessage.container';
import InputErrorBlock from '../../components/InputError/InputErrorBlock.container';
import Icon from '../../../../components/Icon/Icon';
import { formatMoney } from '../../../../utils/display/number';

export type ChooseRouterProps = {
  prevScreen: () => void;
  handleSubmit: () => void;
  submitFailed: boolean;
  invalid: boolean;
  cms: CMSExtras;
  extraInfo: any;
  selectedRouter: string;
  willShow24MonthsPaymentSelector: boolean;
  routerOptions: Array<OptionWithDiscount>;
  routerPrice: string;
  routerPriceMonths: (months: number) => string;
  isFibre: boolean;
  noRouterImage?: string;
};

export const createPaymentOptionsProp = (
  router,
  selectedRouter,
  routerPriceMonths,
  routerPrice,
  willShow24MonthsPaymentSelector,
) => {
  const lumpSumPayment = {
    name: FIELDNAMES.ROUTER_PAYMENT,
    value: OPTIONS.ROUTER_PAYMENT.NO,
    label: `$${formatMoney(routerPrice)} one off payment`,
  };

  const twelveMonthPayment = {
    name: FIELDNAMES.ROUTER_PAYMENT,
    value: OPTIONS.ROUTER_PAYMENT.MONTHLY_PAYMENTS_12,
    label: `$${formatMoney(
      routerPriceMonths(12),
    )} monthly payments over 12 months.`,
  };

  const twentyFourMonthPayment = {
    name: FIELDNAMES.ROUTER_PAYMENT,
    value: OPTIONS.ROUTER_PAYMENT.MONTHLY_PAYMENTS_24,
    label: `$${formatMoney(
      routerPriceMonths(24),
    )} monthly payments over 24 months.`,
  };

  const isDiscounted = router.original_product
    ? router.original_product.price_amount > routerPrice
    : false;

  if (selectedRouter !== router.provisioning_key) {
    return undefined;
  } else if (isDiscounted) {
    return [lumpSumPayment];
  } else if (willShow24MonthsPaymentSelector) {
    return [lumpSumPayment, twelveMonthPayment, twentyFourMonthPayment];
  } else {
    return [lumpSumPayment, twelveMonthPayment];
  }
};

const ChooseRouterScreen = ({
  prevScreen,
  handleSubmit,
  submitFailed,
  invalid,
  cms,
  extraInfo,
  selectedRouter,
  willShow24MonthsPaymentSelector,
  routerOptions,
  routerPriceMonths,
  routerPrice,
  isFibre,
}: ChooseRouterProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="banner">
        <div className="banner__content line-length-large">
          <h1>Your Wi-Fi setup</h1>
          {/* {!isFetchingContent &&
            !isFetchingAvailability &&
            !selectedPkg.fetching && (
            )} */}
          <h1
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cms && cms.screen && cms.screen.heading,
            }}
          />
        </div>
      </div>
      {/* @ts-ignore */}
      <ProgressTracker />
      <div className="section section--no-bottom section--no-top@small">
        <div className="grid-flex grid--row-spacing-s grid--col-spacing">
          <div className="width-2/3@large">
            <div className="section section--stacked">
              <FieldInputError name={FIELDNAMES.SELECT_ROUTER} />
              <InputErrorBlock name={FIELDNAMES.SELECT_ROUTER}>
                <h3>Need a router from us?</h3>
                <p className="u-mb-m">
                  Choose the Wi-Fi setup that best suits your home from the
                  options below
                </p>
                <RouterCard
                  id="no_router"
                  name={FIELDNAMES.SELECT_ROUTER}
                  value={OPTIONS.INCLUDE_ROUTER.NO}
                  title="I'll use my own router"
                  description=""
                  link="/support/technical-support/router-help/use-my-router/"
                  linkText="Check what routers are supported"
                />
                {routerOptions.map((router) => {
                  const routerOption: Option = router as Option;
                  const isMostCommon = routerOption.is_default;

                  return (
                    <RouterCard
                      key={router.provisioning_key}
                      id={router.provisioning_key}
                      name={FIELDNAMES.SELECT_ROUTER}
                      value={router.provisioning_key}
                      title={router.name}
                      description={router.description}
                      imgSrc={router.image!}
                      imgAlt={router.summary_name}
                      ribbon={isMostCommon ? 'Most common' : undefined}
                      paymentOptions={createPaymentOptionsProp(
                        router,
                        selectedRouter,
                        routerPriceMonths,
                        routerPrice,
                        willShow24MonthsPaymentSelector,
                      )}
                    />
                  );
                })}
              </InputErrorBlock>

              {isFibre && extraInfo && extraInfo.options_info && (
                <div className="section section--stacked">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton className="router-accordion__heading">
                          {extraInfo.options_info.title}
                          <Icon
                            title="Chevron"
                            type="icon-arrowdown"
                            className="i--medium router-accordion__icon"
                          />
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="router-accordion__panel">
                        <div
                          className="u-mb-xxl"
                          dangerouslySetInnerHTML={{
                            __html: extraInfo.options_info.text,
                          }}
                        ></div>
                        {extraInfo.options_info.options &&
                          extraInfo.options_info.options.map((option, i) => (
                            <div key={i} className="u-mb-xxl">
                              <div className="width-2/3@medium u-inline-block">
                                <p className="u-text-xl u-text-bold">
                                  {option.title}
                                </p>
                                <div
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html: option.text,
                                  }}
                                ></div>
                              </div>
                              <div className="width-1/3@medium u-inline-block">
                                <img
                                  src={option.image}
                                  alt=""
                                  className="router-accordion__image"
                                />
                              </div>
                            </div>
                          ))}
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              )}

              <div className="section section--stacked">
                <p>
                  Looking for a static IP address? Don’t worry, you can add one
                  once you’ve signed up, just{' '}
                  <a
                    href="/contact-us"
                    target="_blank"
                    className="link link--underline"
                  >
                    get in touch
                  </a>
                  .
                </p>
              </div>
              <div className="section section--stacked">
                <p>
                  <button
                    type="button"
                    className="btn btn--hollow-dark"
                    onClick={prevScreen}
                    data-key="BackPlanDetails"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn u-float-right"
                    data-key="NextAccountDetails"
                  >
                    Next
                  </button>
                </p>
                {submitFailed && invalid && (
                  <ErrorMessage
                    className="u-text-right"
                    error={SCREEN_VALIDATION_ERROR_MESSAGE}
                  />
                )}
              </div>
              <div className="section section--stacked">
                <div className="line-length">
                  <div
                    className="u-text-s"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html:
                        cms && cms.disclaimer && cms.disclaimer.description,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="width-1/3@large">
            {/* @ts-ignore */}
            <CartSummary />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChooseRouterScreen;
