//

import { compose } from 'redux';
import { put, takeLatest, call } from 'redux-saga/effects';
import {
  FETCH_CONTENT,
  fetchContentSuccess,
  fetchContentFailed,
} from './content.actions';
import {
  combineConnectionSpeeds,
  packagesPriceInfoAdapter,
} from './content.utils';
import { get } from '../../../../utils/http/http';
import { SERVER_ERROR } from '../../ErrorPage/ErrorPage.constants';

export async function getContent() {
  const { data } = await get({
    path: '/acquisitions/api/v1/descriptions/',
  });
  return data;
}

function* fetchContent() {
  try {
    const content = yield call(getContent);
    const result = compose(
      packagesPriceInfoAdapter,
      combineConnectionSpeeds,
    )(content);
    yield put(fetchContentSuccess(result));
  } catch (e) {
    yield put(fetchContentFailed(e.message, SERVER_ERROR));
  }
}

export default function* saga() {
  yield takeLatest(FETCH_CONTENT, fetchContent);
}
