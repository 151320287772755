import { createSelector } from 'reselect';
import type { State } from '../constants';
import type {
  Product,
  Characteristic,
  Option,
  Contract,
  ContentState,
} from './content.constants';
import type { Package } from '../package/package.constants';

export const contentSelector = (state: State) => state.content;

export const contentErrorSelector = createSelector(
  contentSelector,
  (content) => content && content.error,
);
export const contentErrorTypeSelector = createSelector(
  contentSelector,
  (content) => content && content.errorType,
);

export const productsSelector = createSelector(contentSelector, (base) =>
  base ? base.products : [],
);

export const contractsSelector = createSelector(contentSelector, (base) =>
  base ? base.contracts : [],
);

export const packagesSelector: (State) => Array<Package> = createSelector(
  contentSelector,
  (base) => (base ? base.packages : []),
);

export const cmsSelector = createSelector(contentSelector, (base) =>
  base ? base.cms : undefined,
);

export const isFetchingSelector: (State) => boolean = createSelector(
  contentSelector,
  (base) => base && base.fetching,
);

export const isNowOfficeSelector = createSelector(
  contentSelector,
  (base) => base && !!base.now_office,
);

export const assetsSelector = createSelector(
  contentSelector,
  (base) => base.assets,
);

// The following selectors take an array of products.
// They are meant to be used with reslect's createSelector,
// with the above productSelector as first argument.

export const productSelector = (
  products: Array<Product>,
  { product: productKey }: { product: string },
): Product | undefined =>
  products.find((product) => product.provisioning_key === productKey);

export const characteristicsSelector = (
  products: Array<Product>,
  path: { product: string },
): Array<Characteristic> => {
  const product = productSelector(products, path);
  return product ? product.characteristics : [];
};

export const characteristicSelector = (
  products: Array<Product>,
  {
    product,
    characteristic: characteristicKey,
  }: { product: string; characteristic?: string },
): Characteristic | undefined => {
  const characteristics = characteristicsSelector(products, { product });
  const characteristic = characteristics.find(
    (char) => char.provisioning_key === characteristicKey,
  );
  return characteristic;
};

export const optionsSelector = (
  products: Array<Product>,
  {
    product: productName,
    characteristic: characteristicName,
  }: { product: string; characteristic?: string },
): Array<Option> => {
  const characteristic = characteristicSelector(products, {
    product: productName,
    characteristic: characteristicName,
  });
  return characteristic
    ? characteristic.options.sort((a, b) => a.sort_order - b.sort_order)
    : [];
};

// To select a single option, there should be a optionsSelector used for displaying the options.
// This selector is meant to be used with it. See examples in the cartSummary selectors.
export const optionSelector = (
  options: Array<Option>,
  optionKey: string,
): Option | undefined => {
  const option = options.find((opt) => opt.provisioning_key === optionKey);
  return option;
};

export const contractSelector = (
  contracts: Array<Contract>,
  contractKey: string,
): Contract | undefined => {
  const contract = contracts.find(
    (item) => item.provisioning_key === contractKey,
  );
  return contract;
};

// This returns {
// path: { type: 'option' | 'characteristic' | etc..., product: provisioning key, etc... },
// item: Option | Characteristic | Product | Contract
//  }
export const findItemByUuid = (
  { products, contracts }: ContentState,
  uuid?: string,
) => {
  if (!uuid) {
    return null;
  }
  const contract = contracts.find((item) => item.id === uuid);
  if (contract)
    return {
      path: {
        type: 'contract',
        contract: contract.provisioning_key,
      },
      item: contract,
    };
  let result = null;
  // using some() to break out of the loop when found
  products.some((product) => {
    if (product.id === uuid) {
      result = {
        path: {
          type: 'product',
          product: product.provisioning_key,
        },
        item: product,
      };
      return true;
    }
    product.characteristics.some((char) => {
      if (char.id === uuid) {
        result = {
          path: {
            type: 'characteristic',
            product: product.provisioning_key,
            characteristic: char.provisioning_key,
          },
          item: char,
        };
        return true;
      }
      char.options.some((option) => {
        if (option.id === uuid) {
          result = {
            path: {
              type: 'option',
              product: product.provisioning_key,
              characteristic: char.provisioning_key,
            },
            item: option,
          };
          return true;
        }
        return !!result;
      });
      return !!result;
    });
    return !!result;
  });
  return result;
};

export const extrasSelector = createSelector(contentSelector, (base) =>
  base ? base.extra : [],
);
