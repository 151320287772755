import { put, takeLatest, call, select } from 'redux-saga/effects';
import {
  FETCH_AVAILABILITY,
  type FetchAvailabilityAction,
  fetchAvailabilitySuccess,
  fetchAvailabilityFailed,
} from './availability.actions';
import { get } from '../../../../utils/http/http';
import {
  SERVER_ERROR,
  NO_AVAILABILITY,
} from '../../ErrorPage/ErrorPage.constants';
import { availableConnectionTypesSelector } from './availability.selectors';

function* getAvailability(addressId: string) {
  const { data } = yield get({
    path: `/addresses/api/v1/availability/${addressId}/`,
  });

  return data;
}

function* fetchAvailability({ addressId }: FetchAvailabilityAction) {
  try {
    const availability = yield call(getAvailability, addressId);
    yield put(fetchAvailabilitySuccess(availability));
    const availableTypes = yield select(availableConnectionTypesSelector);
    if (availableTypes.length === 0) {
      yield put(fetchAvailabilityFailed('no availability', NO_AVAILABILITY));
    }
  } catch (e) {
    yield put(fetchAvailabilityFailed(e.message, SERVER_ERROR));
  }
}

export default function* saga() {
  yield takeLatest(FETCH_AVAILABILITY, fetchAvailability);
}
