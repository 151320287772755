import { createSelector } from 'reselect';
import { cmsSelector } from '../../state/content/content.selectors';
import { billDeliverySelector } from '../Screen.selectors';
import { OPTIONS } from '../Screen.constants';

// eslint-disable-next-line import/prefer-default-export
export const paymentOptionCMSSelector = createSelector(
  cmsSelector,
  (cms) => cms && cms.payment_option,
);

export const willShowBillDeliveryDetailsSelector = createSelector(
  billDeliverySelector,
  /*
   * "Step 23b will not display if the user has not selected post to Other Address (Step 23a)"
   * +
   * "Other address selected?"
   */
  (billDelivery): boolean =>
    billDelivery === OPTIONS.BILL_DELIVERY.OTHER_ADDRESS,
);
