import { type CMSSuccess } from '../../state/content/content.constants';
import TrackingPixels from '../../components/TrackingPixels/TrackingPixels.container';

type SuccessScreenProps = {
  cms: CMSSuccess;
};

export default function SuccessScreen({ cms }: SuccessScreenProps) {
  return (
    <div>
      <div className="banner">
        <div className="banner__content line-length-large">
          <h1
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cms && cms.screen && cms.screen.heading,
            }}
          />
        </div>
      </div>
      <div className="section section--no-top@small">
        <div className="grid-flex grid--row-spacing-s grid--col-spacing">
          <div className="width-2/3@large">
            <div className="line-length">
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cms && cms.screen && cms.screen.description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <TrackingPixels />
    </div>
  );
}
