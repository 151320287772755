import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, bindActionCreators, type Dispatch } from 'redux';
import ReviewScreen from './ReviewScreen';
import {
  prevScreen,
  nextScreen,
  goToScreen,
  submitScreen,
} from '../Screen.actions';
import { FORM_NAME } from '../Screen.constants';
import { type State } from '../../state/constants';
import {
  dateOfBirthSelector,
  fullNameSelector,
  emailAddressSelector,
  mobilePhoneSelector,
  additionalAuthDetailsSelector,
  chosenPaymentMethodSelector,
  bankAccountHolderSelector,
  bankAccountTextSelecltor,
  chosenContractSelector,
} from '../Screen.selectors';
import {
  reviewCMSSelector,
  bankNameSelector,
  willShowContractCheckboxSelector,
  directDebitTermsAndConditionsSelector,
} from './ReviewScreen.selectors';
import { isNowOfficeSelector } from '../../state/content/content.selectors';
import { offerSelector } from '../../state/offer/offer.selectors';
import validate from './ReviewScreen.validation';

function mapStateToProps(state: State) {
  return {
    fullName: fullNameSelector(state),
    emailAddress: emailAddressSelector(state),
    mobilePhone: mobilePhoneSelector(state),
    dateOfBirth: dateOfBirthSelector(state),
    additionalAuthDetails: additionalAuthDetailsSelector(state),
    chosenPaymentMethod: chosenPaymentMethodSelector(state),
    bankName: bankNameSelector(state),
    bankAccountHolder: bankAccountHolderSelector(state),
    bankAccount: bankAccountTextSelecltor(state),
    isNowOffice: isNowOfficeSelector(state),
    cms: reviewCMSSelector(state),
    offer: offerSelector(state),
    showContractCheckbox: willShowContractCheckboxSelector(state),
    choosenContract: chosenContractSelector(state),
    directDebitTermsPDF: directDebitTermsAndConditionsSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return bindActionCreators(
    {
      prevScreen,
      nextScreen,
      goToScreen,
      submitScreen,
    },
    dispatch,
  );
}

export default compose(
  connect(
    mapStateToProps,
    // @ts-ignore
    mapDispatchToProps,
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate,
    onSubmit: (
      // @ts-ignore
      values: any,
      dispatch: Dispatch<any>,
    ) =>
      new Promise((resolve, reject) => {
        dispatch(submitScreen({ resolve, reject }));
      }).catch(() => {
        // TODO: using alert for now
        // eslint-disable-next-line no-alert
        alert(
          'Whoops! It looks like there was some kind of problem with your connection. Please stay on this page and click ‘confirm’ again. If that doesn’t work then give us a call on 0800 438 669.',
        );
      }),
  }),
)(ReviewScreen);
