import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, bindActionCreators, type Dispatch } from 'redux';
import ExtrasScreen from './ExtrasScreen';
import { prevScreen, nextScreen } from '../Screen.actions';
import { FORM_NAME } from '../Screen.constants';
import {
  phoneLineSelector,
  phoneBundleSelector,
  landLineCallingSelector,
  landlineCallingOptionsSelector,
  mobileCallingSelector,
  mobileCallingOptionsSelector,
  techAssistOptionsSelector,
  extrasCMSSelector,
  willShowSelectPhonelineSelector,
  willShowLandlineCallingSelector,
  willShowLandlineCallingOptionSelector,
  willShowMobileCallingSelector,
  willShowMobileCallingOptionSelector,
  willShowPhoneBundleSelector,
  willShowTechspertSelector,
  willShowTechAssistSelector,
} from './ExtrasScreen.selectors';
import validate from './ExtrasScreen.validation';

function mapStateToProps(state) {
  return {
    phoneLine: phoneLineSelector(state),
    phoneBundle: phoneBundleSelector(state),
    landlineCalling: landLineCallingSelector(state),
    landlineCallingOptions: landlineCallingOptionsSelector(state),
    mobileCalling: mobileCallingSelector(state),
    techAssistOptions: techAssistOptionsSelector(state),
    mobileCallingOptions: mobileCallingOptionsSelector(state),
    willShowSelectPhoneline: willShowSelectPhonelineSelector(),
    willShowLandlineCalling: willShowLandlineCallingSelector(state),
    willShowLandlineCallingOption: willShowLandlineCallingOptionSelector(state),
    willShowMobileCalling: willShowMobileCallingSelector(state),
    willShowMobileCallingOption: willShowMobileCallingOptionSelector(state),
    willShowPhoneBundle: willShowPhoneBundleSelector(state),
    willShowTechspert: willShowTechspertSelector(state),
    willShowTechAssist: willShowTechAssistSelector(state),
    cms: extrasCMSSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onSubmit: () => dispatch(nextScreen()),
    ...bindActionCreators(
      {
        prevScreen,
      },
      dispatch,
    ),
  };
}

export default compose(
  connect(
    mapStateToProps,
    // @ts-ignore
    mapDispatchToProps,
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate,
  }),
)(ExtrasScreen);
