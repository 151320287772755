// @ts-ignore
import React,  { Fragment } from 'react';
import queryString from 'query-string';
import { SCREENS, type Screen } from '../../screens/Screen.constants';
import { type GoToScreenAction } from '../../screens/Screen.actions';
import DollarGroup from '../DollarGroup/DollarGroup';
import type {
  Contract,
  Option,
  Product,
  OptionWithDiscount,
} from '../../state/content/content.constants';
import { type Address } from '../../state/constants';
import { formatMoney } from '../../../../utils/display/number';
import Promo from '../Promo/Promo.container';
import PromoCode from '../PromoCode/PromoCode.container';

type CartSummaryProps = {
  address: Address;
  packagedPlan?: Option;
  packageDataCap?: Option;
  packageSpeed?: Option;
  packageExtras?: Array<Product>;
  plan?: Option;
  speed?: Option;
  router?: OptionWithDiscount;
  routerMonthlyPayment: number;
  phoneline?: Product;
  phoneBundle?: Product;
  landlineOption?: Option;
  mobileOption?: Option;
  techAssist?: OptionWithDiscount;
  showTechAssist: boolean;
  goToScreen: (screen: Screen) => GoToScreenAction;
  currentScreen: Screen;
  contract: Contract;
  showContract: boolean;
  monthlyPayment: number;
  oneOffPayment: number;
  billDelivery?: Product;
  showExtras: boolean;
  showOneOffPayment: boolean;
  showMonthlyPromo: boolean;
  showOneOffPromo: boolean;
  showRouter: boolean;
  payRouterMonthly: boolean;
  routerPaymentMonths: number;
  routerDeliveryFee?: Product;
  showRouterDeliveryFee: boolean;
  oneOffFeeContractName: string;
};

export default function CartSummary({
  address,
  currentScreen,
  goToScreen,
  packagedPlan,
  packageDataCap,
  packageSpeed,
  packageExtras,
  plan,
  speed,
  router,
  routerMonthlyPayment,
  phoneline,
  phoneBundle,
  landlineOption,
  mobileOption,
  techAssist,
  showTechAssist,
  contract,
  showContract,
  monthlyPayment,
  oneOffPayment,
  billDelivery,
  showExtras,
  showOneOffPayment,
  showMonthlyPromo,
  showOneOffPromo,
  showRouter,
  payRouterMonthly,
  routerPaymentMonths,
  routerDeliveryFee,
  showRouterDeliveryFee,
  oneOffFeeContractName,
}: CartSummaryProps) {
  const params = queryString.parse(window.location.search);
  const changeAddressLocation = `/address-finder/?${queryString.stringify(
    params,
  )}`;
  const { line1, ...addressRemaining } = address;

  return (
    <div className="card card-summary card--slate">
      <div className="card-summary__section">
        <div className="card-summary__content">
          <div className="card-summary__col--left">
            <h4 className="card__title u-text-uppercase u-mb-0">{line1}</h4>
            <p className="card-summary__subline">
              {addressRemaining &&
                Object.values(addressRemaining)
                  .filter((line) => !!line)
                  .join(', ')}
            </p>
          </div>
          <div className="card-summary__col--right">
            <a
              href={changeAddressLocation}
              className="u-text-s link--underline link--underline-white"
            >
              Change
            </a>
          </div>
        </div>
      </div>
      <div className="card-summary__section">
        <div className="card-summary__content">
          <div className="card-summary__col--left u-mb-xxs">
            <h5>Your plan</h5>
          </div>
          <div className="card-summary__col--right">
            <p>
              {currentScreen !== SCREENS.PlanDetails && (
                <button
                  type="button"
                  className="u-text-s link link--underline link--underline-white"
                  onClick={() => goToScreen(SCREENS.PlanDetails)}
                >
                  Edit
                </button>
              )}
            </p>
          </div>
        </div>
        <div className="card-summary__content">
          <ul className="list-unstyled">
            {!packagedPlan && !plan && !speed && (
              <li className="u-text-muted">Choose your plan</li>
            )}
            {packagedPlan && packagedPlan.name && (
              <li className="card-summary__item">
                <span className="card-summary__col--left">
                  {packagedPlan.name}
                </span>
                {packagedPlan.price_amount && (
                  <span className="card-summary__col--right">{`$${formatMoney(
                    packagedPlan.price_amount,
                  )}`}</span>
                )}
              </li>
            )}

            {packagedPlan && packageDataCap && packageDataCap.name && (
              <li className="card-summary__item">
                <span className="card-summary__col--left">
                  {packageDataCap.summary_name}
                </span>
              </li>
            )}

            {packagedPlan && packageSpeed && packageSpeed.name && (
              <li className="card-summary__item">
                <span className="card-summary__col--left">
                  {packageSpeed.summary_name}
                </span>
              </li>
            )}

            {plan && plan.summary_name && plan.price_amount && (
              <li className="card-summary__item">
                <span className="card-summary__col--left">
                  {plan.summary_name}
                </span>
                <span className="card-summary__col--right">{`$${formatMoney(
                  plan.price_amount,
                )}`}</span>
              </li>
            )}
            {!packagedPlan && speed && speed.summary_name && (
              <li className="card-summary__item">
                <span className="card-summary__col--left">
                  {speed.summary_name}
                </span>
                {speed.price_amount && speed.price_amount !== '0.00' && (
                  <span className="card-summary__col--right">{`$${formatMoney(
                    speed.price_amount,
                  )}`}</span>
                )}
              </li>
            )}
            {packageExtras &&
              packageExtras.map((product) => (
                <li key={product.id} className="card-summary__item">
                  <span className="card-summary__col--left">
                    {product.summary_name}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      {showExtras && (
        <div className="card-summary__section card-summary__section--no-bottom">
          <div className="card-summary__content">
            <div className="card-summary__col--left u-mb-xxs">
              <h5>Your extras</h5>
            </div>
            <div className="card-summary__col--right">
              <p>
                {currentScreen !== SCREENS.Extras && (
                  <button
                    type="button"
                    onClick={() => goToScreen(SCREENS.Extras)}
                    className="u-text-s link link--underline link--underline-white"
                  >
                    Edit
                  </button>
                )}
              </p>
            </div>
          </div>
          <div className="card-summary__content">
            <ul className="list-unstyled">
              {showRouter && router && (
                <li className="card-summary__item">
                  <span className="card-summary__col--left">
                    {router.summary_name}
                  </span>
                </li>
              )}
              {phoneline && (
                <li className="card-summary__item">
                  <span className="card-summary__col--left">
                    {phoneline.summary_name}
                  </span>
                  {phoneline.price_amount && (
                    <span className="card-summary__col--right">
                      {`$${formatMoney(phoneline.price_amount)}`}
                    </span>
                  )}
                </li>
              )}
              {phoneBundle && (
                <li className="card-summary__item">
                  <span className="card-summary__col--left">
                    {phoneBundle.summary_name}
                  </span>
                  {phoneBundle.price_amount && (
                    <span className="card-summary__col--right">
                      {`$${formatMoney(phoneBundle.price_amount)}`}
                    </span>
                  )}
                </li>
              )}
              {landlineOption && (
                <li className="card-summary__item">
                  <span className="card-summary__col--left">
                    {landlineOption.summary_name}
                  </span>
                  {landlineOption.price_amount && (
                    <span className="card-summary__col--right">
                      {`$${formatMoney(landlineOption.price_amount)}`}
                    </span>
                  )}
                </li>
              )}
              {mobileOption && (
                <li className="card-summary__item">
                  <span className="card-summary__col--left">
                    {mobileOption.summary_name}
                  </span>
                  {mobileOption.price_amount && (
                    <span className="card-summary__col--right">
                      {`$${formatMoney(mobileOption.price_amount)}`}
                    </span>
                  )}
                </li>
              )}
              {showTechAssist && techAssist && (
                <Fragment>
                  {techAssist.original_product &&
                    // @ts-ignore
                    techAssist.type === 'offer' && (
                      <li className="card-summary__item card-summary__item--crossed">
                        <span className="card-summary__col--left">
                          {techAssist.original_product.summary_name
                            ? techAssist.original_product.summary_name
                            : techAssist.original_product.name}
                        </span>
                        {techAssist.original_product.price_amount && (
                          <span className="card-summary__col--right">
                            {`$${formatMoney(
                              techAssist.original_product.price_amount,
                            )}`}
                          </span>
                        )}
                      </li>
                    )}
                  <li className="card-summary__item">
                    <span className="card-summary__col--left">
                      {techAssist.summary_name
                        ? techAssist.summary_name
                        : techAssist.name}
                    </span>
                    {techAssist.price_amount && (
                      <span className="card-summary__col--right">
                        {`$${formatMoney(techAssist.price_amount)}`}
                      </span>
                    )}
                  </li>
                </Fragment>
              )}

              {billDelivery && (
                <li className="card-summary__item">
                  <span className="card-summary__col--left">
                    {billDelivery.summary_name}
                  </span>
                  <span className="card-summary__col--right">
                    {`$${formatMoney(billDelivery.price_amount)}`}
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      {/* @ts-ignore */}
      <PromoCode />
      {showMonthlyPromo && <Promo />}
      <div className="card-summary__section card-summary__section--lg">
        <div className="card-summary__content">
          <div className="card-summary__col--left">
            <h5>Total monthly</h5>
          </div>
          <div className="card-summary__col--right">
            <DollarGroup amount={`${monthlyPayment}`} size="large" />
          </div>
        </div>
      </div>
      {showContract && (
        <div className="card-summary__section">
          <div className="card-summary__content">
            <div className="card-summary__col--left">
              <p className="u-text-medium">Contract</p>
            </div>

            <div className="card-summary__col--right">
              <p>{contract.name}</p>
            </div>
          </div>
        </div>
      )}

      {showOneOffPayment && (
        <div className="card-summary__section card-summary__section">
          <div className="card-summary__content">
            <div className="card-summary__col--left u-mb-xs">
              <h5>Additional payments</h5>
            </div>
          </div>
          <div className="card-summary__content u-mb-s">
            <ul className="list-unstyled u-text-s">
              {showRouter && router && (
                <Fragment>
                  {router.original_product &&
                    // @ts-ignore
                    router.type === 'offer' && (
                      <li className="card-summary__item card-summary__item--strikethrough">
                        <span className="card-summary__col--left">
                          {router.original_product.summary_name}
                        </span>
                        {router.original_product.price_amount && (
                          <span className="card-summary__col--right">
                            {`$${formatMoney(
                              router.original_product.price_amount,
                            )}`}
                          </span>
                        )}
                      </li>
                    )}

                  <li className="card-summary__item">
                    <span className="card-summary__col--left">
                      {router.summary_name}
                      {payRouterMonthly && (
                        <span className="u-text-muted u-text-xs">
                          <br />
                          {routerPaymentMonths} x
                          {` $${formatMoney(routerMonthlyPayment)}`} monthly
                          payments. First payment included below. Future
                          payments on subsequent bills.
                        </span>
                      )}
                    </span>
                    {router.price_amount && (
                      <span className="card-summary__col--right">
                        {`$${formatMoney(router.price_amount)}`}
                      </span>
                    )}
                  </li>
                </Fragment>
              )}
              {showRouterDeliveryFee && routerDeliveryFee && (
                <li className="card-summary__item">
                  <span className="card-summary__col--left">
                    {routerDeliveryFee.summary_name}
                  </span>
                  {routerDeliveryFee.price_amount && (
                    <span className="card-summary__col--right">
                      {`$${formatMoney(routerDeliveryFee.price_amount)}`}
                    </span>
                  )}
                </li>
              )}
              {contract && contract.price_amount !== '0.00' && (
                <li className="card-summary__item">
                  <span className="card-summary__col--left">
                    {oneOffFeeContractName}
                  </span>
                  {contract.price_amount && (
                    <span className="card-summary__col--right">
                      {`$${formatMoney(contract.price_amount)}`}
                    </span>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      {showOneOffPromo && <Promo />}
      {showOneOffPayment && (
        <div className="card-summary__section">
          <div className="card-summary__content">
            <div className="card-summary__col--left">
              <p>
                {payRouterMonthly
                  ? 'Initial one off payment'
                  : 'Total one off payment'}
                {payRouterMonthly && (
                  <span className="u-text-muted u-text-xs">
                    <br />
                    (Included on 1st bill)
                  </span>
                )}
              </p>
            </div>
            <div className="card-summary__col--right">
              <p>{`$${formatMoney(oneOffPayment)}`}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
