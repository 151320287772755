//

import { put, takeLatest, call, select } from 'redux-saga/effects';
import { clearFields } from 'redux-form';
import {
  FETCH_OFFER,
  type FetchOfferAction,
  setOffer,
  fetchOfferSuccess,
  fetchOfferFailed,
} from './offer.actions';
import { post } from '../../../../utils/http/http';
import { offerAvailabilitySelector } from './offer.selectors';
import { FORM_NAME, FIELDNAMES } from '../../screens/Screen.constants';

function* getOffer(promoCode: string) {
  const { data } = yield post({
    path: '/acquisitions/api/v1/offer/',
    payload: { offer_code: promoCode },
  });

  return data;
}

function* fetchOffer({ promoCode }: FetchOfferAction) {
  try {
    const data = yield call(getOffer, promoCode);
    yield put(setOffer(data.offer));
    const isOfferAvailable = yield select(offerAvailabilitySelector);
    if (isOfferAvailable) {
      yield put(fetchOfferSuccess());
      yield put(clearFields(FORM_NAME, false, false, FIELDNAMES.CONTRACT));
    } else {
      yield put(
        fetchOfferFailed('Sorry, this offer is not available at your address'),
      );
    }
  } catch (e) {
    if (e.response.status === 404) {
      yield put(
        fetchOfferFailed('Sorry you have entered an invalid promo code'),
      );
    } else {
      yield put(fetchOfferFailed(e.message));
    }
  }
}

export default function* saga() {
  yield takeLatest(FETCH_OFFER, fetchOffer);
}
