export const SCREENS = {
  PlanDetails: 3,
  ChooseRouter: 4,
  Extras: 5,
  PersonalAccount: 6,
  ProviderTransfer: 7,
  PaymentOption: 8,
  Review: 9,
  Success: 10,
};

export type Screen = typeof SCREENS[keyof typeof SCREENS];

export const FORM_NAME = 'Screen';

export const SCREEN_VALIDATION_ERROR_MESSAGE =
  'Looks like you forgot some things above';

export const FIELDNAMES = {
  // Screen 3 - Plan Details
  DATA_CAP: 'DATA_CAP',
  CONNECTION_SPEED: 'CONNECTION_SPEED',
  CONTRACT: 'CONTRACT',
  // Screen 4 - Phoneline and Other Extras
  // INCLUDE_ROUTER: 'INCLUDE_ROUTER',
  SELECT_ROUTER: 'SELECT_ROUTER',
  ROUTER_PAYMENT: 'ROUTER_PAYMENT',
  SELECT_PHONELINE: 'SELECT_PHONELINE',
  LANDLINE_CALLING: 'LANDLINE_CALLING',
  LANDLINE_CALLING_OPTION: 'LANDLINE_CALLING_OPTION',
  MOBILE_CALLING: 'MOBILE_CALLING',
  MOBILE_CALLING_OPTION: 'MOBILE_CALLING_OPTION',
  PHONE_BUNDLE: 'PHONE_BUNDLE',
  TECH_ASSIST: 'TECH_ASSIST',
  // Screen 5 – Personal Account Details
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  DATE_OF_BIRTH: {
    DD: 'DATE_OF_BIRTH-DD',
    MM: 'DATE_OF_BIRTH-MM',
    YYYY: 'DATE_OF_BIRTH-YYYY',
  },
  MOBILE_PHONE: 'MOBILE_PHONE',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  ADDITIONAL_AUTH: 'ADDITIONAL_AUTH',
  AUTH_FIRST_NAME: 'AUTH_FIRST_NAME',
  AUTH_LAST_NAME: 'AUTH_LAST_NAME',
  AUTH_CONTACT_NUMBER: 'AUTH_CONTACT_NUMBER',
  PREFERRED_START_DATE: 'PREFERRED_START_DATE',
  PREFERRED_START_DATE_DETAILS: {
    DD: 'PREFERRED_START_DATE_DETAILS-DD',
    MM: 'PREFERRED_START_DATE_DETAILS-MM',
    YYYY: 'PREFERRED_START_DATE_DETAILS-YYYY',
  },
  HARDWARE_DELIVERY: 'HARDWARE_DELIVERY',
  HARDWARE_DELIVERY_ADDRESS: {
    NAME: 'HARDWARE_DELIVERY_ADDRESS_NAME',
    ADDRESS_LINE_1: 'HARDWARE_DELIVERY_ADDRESS_ADDRESS_LINE_1',
    ADDRESS_LINE_2: 'HARDWARE_DELIVERY_ADDRESS_ADDRESS_LINE_2',
    SUBURB: 'HARDWARE_DELIVERY_ADDRESS_SUBURB',
    CITY: 'HARDWARE_DELIVERY_ADDRESS_CITY',
    POSTCODE: 'HARDWARE_DELIVERY_ADDRESS_POSTCODE',
  },
  PROVIDER_TRANSFER: 'PROVIDER_TRANSFER',
  // Screen 6
  TRANSFERRING_SERVICE_TYPES: 'TRANSFERRING_SERVICE_TYPES',
  TRANSFERRING_PROVIDER_DETAILS: 'TRANSFERRING_PROVIDER_DETAILS',
  TRANSFERRING_PROVIDER_DETAILS_OTHER: 'TRANSFERRING_PROVIDER_DETAILS_OTHER',
  ACCOUNT_NUMBER: 'ACCOUNT_NUMBER',
  EXISTING_NUMBER: 'EXISTING_NUMBER',
  KEEP_EXISTING_NUMBER: 'KEEP_EXISTING_NUMBER',
  // Screen 7
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  BANK_NAME: 'BANK_NAME',
  BANK_NAME_OTHER: 'BANK_NAME_OTHER',
  BANK_ACCOUNT_HOLDER: 'BANK_ACCOUNT_HOLDER',
  BANK_ACCOUNT_NUMBER: {
    PREFIX: 'PREFIX',
    BRANCH: 'BRANCH',
    ACCOUNT: 'ACCOUNT',
    SUFFIX: 'SUFFIX',
  },
  ACCOUNT_OPERATING_AUTHORITY: 'ACCOUNT_OPERATING_AUTHORITY',
  BILL_DELIVERY: 'BILL_DELIVERY',
  BILL_DELIVERY_ADDRESS: {
    NAME: 'BILL_DELIVERY_ADDRESS_NAME',
    ADDRESS_LINE_1: 'BILL_DELIVERY_ADDRESS_ADDRESS_LINE_1',
    ADDRESS_LINE_2: 'BILL_DELIVERY_ADDRESS_ADDRESS_LINE_2',
    SUBURB: 'BILL_DELIVERY_ADDRESS_SUBURB',
    CITY: 'BILL_DELIVERY_ADDRESS_CITY',
    POSTCODE: 'BILL_DELIVERY_ADDRESS_POSTCODE',
  },
  FRIEND_NAME: 'FRIEND_NAME',
  // Screen 8
  CONTRACT_AGREEMENT: 'CONTRACT_AGREEMENT',
  CREDIT_CHECK: 'CREDIT_CHECK',
  OTHER_NOTES: 'OTHER_NOTES',
  CSR_ID: 'CSR_ID',
};

export type Fieldname = string &
  (
    | typeof FIELDNAMES[keyof typeof FIELDNAMES]
    | typeof FIELDNAMES.DATE_OF_BIRTH[keyof typeof FIELDNAMES.DATE_OF_BIRTH]
    | typeof FIELDNAMES.PREFERRED_START_DATE_DETAILS[keyof typeof FIELDNAMES.PREFERRED_START_DATE_DETAILS]
    | typeof FIELDNAMES.HARDWARE_DELIVERY_ADDRESS[keyof typeof FIELDNAMES.HARDWARE_DELIVERY_ADDRESS]
    | typeof FIELDNAMES.BILL_DELIVERY_ADDRESS[keyof typeof FIELDNAMES.BILL_DELIVERY_ADDRESS]
    | typeof FIELDNAMES.BANK_ACCOUNT_NUMBER[keyof typeof FIELDNAMES.BANK_ACCOUNT_NUMBER]
  );

type FieldnameToProduct = {
  [Fieldname: string]:
    | {
        type: 'product';
        product: string;
      }
    | {
        type: 'characteristic';
        product: string;
        characteristic: string;
      }
    | {
        type: 'option';
        product: string;
        characteristic: string;
      };
};

// Associate fieldnames with the product and characteristics `provisioning_key` for use with selectors and to build end response
export const fieldnameToProduct: FieldnameToProduct = {
  DATA_CAP: {
    type: 'option',
    product: 'Broadband',
    characteristic: 'BroadbandDataCap',
  },
  CONNECTION_SPEED: {
    type: 'option',
    product: 'Broadband',
    characteristic: 'BroadbandSpeed',
  },
  SELECT_ROUTER: {
    type: 'option',
    product: 'Router',
    characteristic: 'RouterType',
  },
  SELECT_PHONELINE: {
    type: 'product',
    product: 'Voiceline',
  },
  PHONE_BUNDLE: {
    type: 'product',
    product: 'PhoneAddOns',
  },
  LANDLINE_CALLING: {
    type: 'characteristic',
    product: 'CallingPlan',
    characteristic: 'Unlimited2HoursPlan',
  },
  LANDLINE_CALLING_OPTION: {
    type: 'option',
    product: 'CallingPlan',
    characteristic: 'Unlimited2HoursPlan',
  },
  MOBILE_CALLING: {
    type: 'characteristic',
    product: 'CallingPlan',
    characteristic: 'NZMobileCallingPlan',
  },
  MOBILE_CALLING_OPTION: {
    type: 'option',
    product: 'CallingPlan',
    characteristic: 'NZMobileCallingPlan',
  },
  TECH_ASSIST: {
    type: 'option',
    product: 'HomeAssistMembership',
    characteristic: 'HomeAssistType',
  },
  BILL_DELIVERY: {
    type: 'product',
    product: 'paper_invoice__local',
  },
};

export const OPTIONS: { [Fieldname: string]: any } = {
  // Screen 3 - Extras Router
  INCLUDE_ROUTER: {
    YES: 'YES',
    NO: 'NO',
  },
  // Screen 4 - Phoneline and Other Extras
  ROUTER_PAYMENT: {
    NO: 'NO',
    MONTHLY_PAYMENTS_12: 'MONTHLY_PAYMENTS_12',
    MONTHLY_PAYMENTS_24: 'MONTHLY_PAYMENTS_24',
  },
  SELECT_PHONELINE: {
    NO: 'NO',
  },
  // Screen 5 – Personal Account Details
  ADDITIONAL_AUTH: {
    NOT_SELECTED: 'NOT_SELECTED',
    SELECTED: 'SELECTED',
  },
  PREFERRED_START_DATE: {
    ASAP: 'ASAP',
    A_SPECIFIC_DATE: 'A_SPECIFIC_DATE',
  },
  HARDWARE_DELIVERY: {
    SERVICE_ADDRESS: 'SERVICE_ADDRESS',
    ANOTHER_ADDRESS: 'ANOTHER_ADDRESS',
    PICK_UP: 'PICK_UP',
  },
  PROVIDER_TRANSFER: {
    YES: 'YES',
    NO: 'NO',
  },
  // Screen 6 – Provider Transfer Information
  TRANSFERRING_SERVICE_TYPES: {
    BROADBAND_ONLY: 'BROADBAND_ONLY',
    BROADBAND_AND_PHONE: 'BROADBAND_AND_PHONE',
  },
  TRANSFERRING_PROVIDER_DETAILS: {
    SPARK: {
      value: 'SPARK',
      label: 'Spark',
    },
    VODAFONE: {
      value: 'VODAFONE',
      label: 'Vodafone',
    },
    SLINGSHOT: {
      value: 'SLINGSHOT',
      label: 'Slingshot',
    },
    ORCON: {
      value: 'ORCON',
      label: 'Orcon',
    },
    TRUSTPOWER: {
      value: 'TRUSTPOWER',
      label: 'Trustpower',
    },
    TWO_DEGREES: {
      value: 'TWO_DEGREES',
      label: '2 Degrees',
    },
    SKINNY: {
      value: 'SKINNY',
      label: 'Skinny',
    },
    BIG_PIPE: {
      value: 'BIG_PIPE',
      label: 'Big Pipe',
    },
    MY_REPUBLIC: {
      value: 'MY_REPUBLIC',
      label: 'My Republic',
    },
    VOYAGER: {
      value: 'VOYAGER',
      label: 'Voyager',
    },
    OTHER: {
      value: 'OTHER',
      label: 'Other',
    },
  },
  KEEP_EXISTING_NUMBER: {
    YES: 'YES',
  },
  // Screen 7 – Payment Options
  PAYMENT_METHOD: {
    DIRECT_DEBIT: 'DIRECT_DEBIT',
    OTHER: 'OTHER',
  },
  BANK_NAME: {
    KIWIBANK: {
      value: 'Kiwibank',
      label: 'Kiwibank',
    },
    ANZ: {
      value: 'ANZ',
      label: 'ANZ',
    },
    WESTPAC: {
      value: 'Westpac',
      label: 'Westpac',
    },
    BNZ: {
      value: 'BNZ',
      label: 'BNZ',
    },
    ASB: {
      value: 'ASB',
      label: 'ASB',
    },
    TSB: {
      value: 'TSB',
      label: 'TSB',
    },
    'CO-OPERATIVE': {
      value: 'Co-Operative',
      label: 'Co-Operative',
    },
    SBS: {
      value: 'SBS',
      label: 'SBS',
    },
    HEARTLAND: {
      value: 'Heartland',
      label: 'Heartland',
    },
    OTHER: {
      value: 'Other',
      label: 'Other',
    },
  },
  ACCOUNT_OPERATING_AUTHORITY: {
    YES: 'YES',
  },
  BILL_DELIVERY: {
    EMAIL: 'EMAIL',
    SERVICE_ADDRESS: 'SERVICE_ADDRESS',
    OTHER_ADDRESS: 'OTHER_ADDRESS',
  },
  // Screen 8 – Review and Confirmation
  CONTRACT_AGREEMENT: {
    YES: 'YES',
  },
  CREDIT_CHECK: {
    YES: 'YES',
  },
};

export type Provider = keyof typeof OPTIONS.TRANSFERRING_PROVIDER_DETAILS;
export type ServiceTypes = keyof typeof OPTIONS.TRANSFERRING_SERVICE_TYPES;

export type Option = typeof OPTIONS[keyof typeof OPTIONS];

export const DATA_CAP_UNLIMITED_KEY = '0';
export const FREE_NO_CONTRACT_KEY = 'free_nocontract__local';
export const NO_CONTRACT_KEY = 'nocontract__local';

// TODO: The default values are specified by the API. This might not be required anymore.
// Default values are set on form initialisation in the plan details screen container (first screen)
export const DEFAULTS: { [Fieldname: string]: string | Array<string> } = {
  // Screen 4 - Phoneline and Other Extras
  // LANDLINE_CALLING_OPTION, no default provided.
  // INCLUDE_ROUTER: OPTIONS.INCLUDE_ROUTER.NO,
  ROUTER_PAYMENT: OPTIONS.ROUTER_PAYMENT.MONTHLY_PAYMENTS_12,
  SELECT_PHONELINE: OPTIONS.SELECT_PHONELINE.NO,
  // Screen 5 – Personal Account Details
  ADDITIONAL_AUTH: OPTIONS.ADDITIONAL_AUTH.NOT_SELECTED,
  PREFERRED_START_DATE: OPTIONS.PREFERRED_START_DATE.ASAP,
  HARDWARE_DELIVERY: OPTIONS.HARDWARE_DELIVERY.SERVICE_ADDRESS,
  // Screen 6 – Provider Transfer Information
  TRANSFERRING_SERVICE_TYPES: OPTIONS.TRANSFERRING_SERVICE_TYPES.BROADBAND_ONLY,
  // Screen 7 – Payment Options
  PAYMENT_METHOD: OPTIONS.PAYMENT_METHOD.DIRECT_DEBIT,
  BILL_DELIVERY: OPTIONS.BILL_DELIVERY.EMAIL,
  // Screen 8 – Review and Confirmation
  // N/A
};

export type ScreensState = {
  currentScreen: Screen;
  orderId?: string;
  error?: string;
};

export type PlanPickerPayload = {
  cart: Array<string>;
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
  date_of_birth: string;
  additional_authority?: {
    first_name: string;
    last_name: string;
  };
  connection_date: string;
  hardware_delivery_address: {
    name: string;
    company: string;
    line1: string;
    line2: string;
    suburb: string;
    city: string;
    postcode: string;
  };
  existing_provider: boolean;
  existing_provider_details: {
    provider: string;
    account_number: string;
    existing_phone_number: string;
    has_existing_phone: boolean;
    keep_phone_number: boolean;
  };
  payment_details: {
    payment_method: string;
    bank_name: string;
    account_holder_name: string;
    account_number: string;
    authority_consent: boolean;
  };
  contract_agreement: boolean;
  contract: string;
  additional_notes: string;
  now_sales_rep_id: string;
  offer: string;
  friend_name: string;
};