import { Fragment } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';
import ChoiceInput from '../../components/ChoiceInput/ChoiceInput';
import {
  FIELDNAMES,
  SCREENS,
  OPTIONS,
  SCREEN_VALIDATION_ERROR_MESSAGE,
  type Screen,
} from '../Screen.constants';
import CartSummary from '../../components/CartSummary/CartSummary.container';
import ProgressTracker from '../../components/ProgressTracker/ProgressTracker.container';
import Icon from '../../../../components/Icon/Icon';
import {
  type CMSPaymentOption,
  type Product,
} from '../../state/content/content.constants';
// import { normalizeNumbers } from '../../../../utils/input/normalize';
import InputErrorBlock from '../../components/InputError/InputErrorBlock.container';
import InputErrorMessage from '../../components/InputError/InputErrorMessage.container';
import ErrorMessage from '../../components/InputError/InputErrorMessage';
import InputWrapper from '../../components/InputWrapper/InputWrapper.container';
import { type Address } from '../../state/constants';
// import { formatMoney } from '../../../../utils/display/number';
import BankAccField from '../../components/BankAccField/BankAccField';

type PaymentOptionScreenProps = {
  prevScreen: () => void;
  goToScreen: (Screen) => void;
  emailAddress: string;
  providerTransfer: boolean;
  address: Address;
  billDeliveryProduct?: Product;
  chosenPaymentMethod: (typeof OPTIONS.PAYMENT_METHOD)[keyof typeof OPTIONS.PAYMENT_METHOD];
  chosenBank: keyof typeof OPTIONS.BANK_NAME;
  cms: CMSPaymentOption;
  willShowBillDeliveryDetails: boolean;
  handleSubmit: () => void;
  submitFailed: boolean;
  invalid: boolean;
};

export default function PaymentOptionScreen({
  prevScreen,
  goToScreen,
  emailAddress,
  providerTransfer,
  chosenPaymentMethod,
  chosenBank,
  cms,
  // willShowBillDeliveryDetails,
  handleSubmit,
  submitFailed,
  invalid,
  // address,
  // billDeliveryProduct,
}: PaymentOptionScreenProps) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="banner">
        <div className="banner__content line-length-large">
          <h1
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cms && cms.screen && cms.screen.heading,
            }}
          />
        </div>
      </div>
      {/* @ts-ignore */}
      <ProgressTracker />
      <div className="section section--no-bottom section--no-top@small">
        <div className="grid-flex grid--row-spacing-s grid--col-spacing">
          <div className="width-2/3@large">
            <div className="line-length">
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cms && cms.screen && cms.screen.description,
                }}
              />
            </div>
            <div className="section section--no-top section--stacked line-length">
              <div className="icon-block icon-block--lg">
                <div className="icon-block__image">
                  <Icon type="icon-pay" className="icon-block__svg" />
                </div>
                <div className="icon-block__content">
                  <InputErrorBlock name={FIELDNAMES.PAYMENT_METHOD}>
                    <InputErrorMessage name={FIELDNAMES.PAYMENT_METHOD} />
                    <h3 className="u-mb-xxs">
                      How do you want to pay your monthly bill?
                    </h3>
                    <p className="u-mb-m">
                      You’ll be able to review your plan before purchasing. All
                      payment options are laid out in your welcome pack in more
                      detail.{' '}
                    </p>

                    <div
                      className={classNames(
                        'width-full',
                        chosenPaymentMethod ===
                          OPTIONS.PAYMENT_METHOD.DIRECT_DEBIT && 'u-mb-l',
                      )}
                    >
                      <ChoiceInput
                        type="radio"
                        name={FIELDNAMES.PAYMENT_METHOD}
                        value={OPTIONS.PAYMENT_METHOD.DIRECT_DEBIT}
                        label="Set up a Direct Debit Instruction with NOW NZ"
                        sublabel="Payments will come out on the 20th of the month (or the next working day)"
                      />
                      <ChoiceInput
                        type="radio"
                        name={FIELDNAMES.PAYMENT_METHOD}
                        value={OPTIONS.PAYMENT_METHOD.OTHER}
                        label="Other"
                        sublabel="Includes one off payments by credit card or internet banking"
                      />
                    </div>
                    {chosenPaymentMethod ===
                      OPTIONS.PAYMENT_METHOD.DIRECT_DEBIT && (
                      <Fragment>
                        <div className="grid-flex grid--row-spacing-s grid--col-spacing-s">
                          <div className="width-2/3@large">
                            <InputWrapper
                              label="Name of your bank"
                              name={FIELDNAMES.BANK_NAME}
                              content={({ error }) => (
                                <div className="select">
                                  <Field
                                    name={FIELDNAMES.BANK_NAME}
                                    component="select"
                                    type="select"
                                    className={classNames(
                                      'input',
                                      error && 'input--error',
                                    )}
                                  >
                                    <option>Please choose</option>
                                    {Object.keys(OPTIONS.BANK_NAME).map(
                                      (key) => (
                                        <option
                                          key={key}
                                          value={OPTIONS.BANK_NAME[key].value}
                                        >
                                          {OPTIONS.BANK_NAME[key].label}
                                        </option>
                                      ),
                                    )}
                                  </Field>
                                </div>
                              )}
                            />
                          </div>
                          {chosenBank === OPTIONS.BANK_NAME.OTHER.value && (
                            <div className="width-2/3@large">
                              <InputWrapper
                                label="Other bank name"
                                name={FIELDNAMES.BANK_NAME_OTHER}
                                content={({ error }) => (
                                  <Field
                                    name={FIELDNAMES.BANK_NAME_OTHER}
                                    component="input"
                                    type="text"
                                    className={classNames(
                                      'input',
                                      error && 'input--error',
                                    )}
                                  />
                                )}
                              />
                            </div>
                          )}
                          <div className="width-2/3@large">
                            <InputWrapper
                              label="Account Name"
                              name={FIELDNAMES.BANK_ACCOUNT_HOLDER}
                              content={({ error }) => (
                                <Field
                                  name={FIELDNAMES.BANK_ACCOUNT_HOLDER}
                                  component="input"
                                  type="text"
                                  className={classNames(
                                    'input',
                                    error && 'input--error',
                                  )}
                                />
                              )}
                            />
                          </div>
                          <div className="width-full">
                            <InputWrapper
                              label="Bank Account Number"
                              helpText="(Not your credit card number)"
                              multi
                              names={[
                                FIELDNAMES.BANK_ACCOUNT_NUMBER.PREFIX,
                                FIELDNAMES.BANK_ACCOUNT_NUMBER.BRANCH,
                                FIELDNAMES.BANK_ACCOUNT_NUMBER.ACCOUNT,
                                FIELDNAMES.BANK_ACCOUNT_NUMBER.SUFFIX,
                              ]}
                              content={({ error }) => (
                                <BankAccField error={error} />
                              )}
                            />
                          </div>
                        </div>
                        <div className="section section--no-bottom">
                          <div className="width-full u-mb-xl">
                            <h4>Confirmation Details:</h4>
                            <p>
                              We will send you written confirmation of this
                              direct debit authority including the full terms
                              and conditions to <strong>{emailAddress}</strong>
                            </p>
                            <p className="u-text-s">
                              If the above email address is wrong please go back
                              to the{' '}
                              <button
                                type="button"
                                className="link link--underline u-text-s"
                                onClick={() => {
                                  goToScreen(SCREENS.PersonalAccount);
                                }}
                              >
                                personal details
                              </button>{' '}
                              screen to change it.
                            </p>
                            <p className="u-text-s">
                              Payments by GoCardless. Read the{' '}
                              <a
                                href="https://gocardless.com/privacy/"
                                className="link--plain u-text-underline"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                GoCardless privacy notice
                              </a>
                              .
                            </p>
                          </div>
                          <div className="width-full">
                            <InputErrorBlock
                              name={FIELDNAMES.ACCOUNT_OPERATING_AUTHORITY}
                            >
                              <InputErrorMessage
                                name={FIELDNAMES.ACCOUNT_OPERATING_AUTHORITY}
                              />
                              <ChoiceInput
                                type="checkbox"
                                name={FIELDNAMES.ACCOUNT_OPERATING_AUTHORITY}
                                value={OPTIONS.ACCOUNT_OPERATING_AUTHORITY.YES}
                                label="Authorisation confirmation"
                                sublabel="I authorise my bank to accept direct debits from NOW New Zealand Ltd with authorisation code 1220559, and confirm that I am able to operate the nominated account for this purpose. I accept the Direct Debit terms and conditions."
                              />
                            </InputErrorBlock>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {chosenPaymentMethod !==
                      OPTIONS.PAYMENT_METHOD.DIRECT_DEBIT && (
                      <p>
                        We’ll send you a welcome email that has all the details
                        about ways to pay.
                      </p>
                    )}
                  </InputErrorBlock>
                </div>
              </div>
            </div>

            {/* Commented out as part of https://springload-nz.atlassian.net/browse/NNS-62 */}
            {/* <div className="section section--stacked line-length">
              <div className="icon-block icon-block--lg">
                <div className="icon-block__image">
                  <Icon type="icon-receive-bill" className="icon-block__svg" />
                </div>
                <div className="icon-block__content">
                  <InputErrorBlock name={FIELDNAMES.BILL_DELIVERY}>
                    <InputErrorMessage name={FIELDNAMES.BILL_DELIVERY} />
                    <h3 className="u-mb-xxs">
                      How would you like to get your bill?
                    </h3>
                    <p className="u-mb-m">
                      We can send your bills to you by email, or by post if you
                      prefer.
                    </p>
                    <div className="width-full">
                      <ChoiceInput
                        name={FIELDNAMES.BILL_DELIVERY}
                        type="radio"
                        label="By email"
                        value={OPTIONS.BILL_DELIVERY.EMAIL}
                      />
                      <ChoiceInput
                        name={FIELDNAMES.BILL_DELIVERY}
                        type="radio"
                        label={`By post to ${
                          address &&
                          Object.values(address)
                            .filter((line) => !!line)
                            .join(', ')
                        }`}
                        sublabel={
                          billDeliveryProduct
                            ? `$${formatMoney(
                                billDeliveryProduct.price_amount,
                              )}/mth`
                            : undefined
                        }
                        value={OPTIONS.BILL_DELIVERY.SERVICE_ADDRESS}
                      />
                      <ChoiceInput
                        name={FIELDNAMES.BILL_DELIVERY}
                        type="radio"
                        label="By post to another address"
                        sublabel={
                          billDeliveryProduct
                            ? `$${formatMoney(
                                billDeliveryProduct.price_amount,
                              )}/mth`
                            : undefined
                        }
                        value={OPTIONS.BILL_DELIVERY.OTHER_ADDRESS}
                      />
                      {willShowBillDeliveryDetails && (
                        <div className="form-group form-group--inset">
                          <div className="grid-flex grid--row-spacing-m grid--col-spacing-s">
                            <div className="width-1/2@medium">
                              <InputWrapper
                                label="Name"
                                name={FIELDNAMES.BILL_DELIVERY_ADDRESS.NAME}
                                content={({ error }) => (
                                  <Field
                                    type="text"
                                    component="input"
                                    name={FIELDNAMES.BILL_DELIVERY_ADDRESS.NAME}
                                    className={classNames(
                                      'input',
                                      error && 'input--error',
                                    )}
                                  />
                                )}
                              />
                            </div>
                            <div className="width-1/2@medium">
                              <InputWrapper
                                label="Address line 1"
                                name={
                                  FIELDNAMES.BILL_DELIVERY_ADDRESS
                                    .ADDRESS_LINE_1
                                }
                                content={({ error }) => (
                                  <Field
                                    type="text"
                                    component="input"
                                    name={
                                      FIELDNAMES.BILL_DELIVERY_ADDRESS
                                        .ADDRESS_LINE_1
                                    }
                                    className={classNames(
                                      'input',
                                      error && 'input--error',
                                    )}
                                  />
                                )}
                              />
                            </div>
                            <div className="width-1/2@medium">
                              <InputWrapper
                                label="Address line 2 (optional)"
                                name={
                                  FIELDNAMES.BILL_DELIVERY_ADDRESS
                                    .ADDRESS_LINE_2
                                }
                                content={({ error }) => (
                                  <Field
                                    type="text"
                                    component="input"
                                    name={
                                      FIELDNAMES.BILL_DELIVERY_ADDRESS
                                        .ADDRESS_LINE_2
                                    }
                                    className={classNames(
                                      'input',
                                      error && 'input--error',
                                    )}
                                  />
                                )}
                              />
                            </div>
                            <div className="width-1/3@medium">
                              <InputWrapper
                                label="Suburb"
                                name={FIELDNAMES.BILL_DELIVERY_ADDRESS.SUBURB}
                                content={({ error }) => (
                                  <Field
                                    type="text"
                                    component="input"
                                    name={
                                      FIELDNAMES.BILL_DELIVERY_ADDRESS.SUBURB
                                    }
                                    className={classNames(
                                      'input',
                                      error && 'input--error',
                                    )}
                                  />
                                )}
                              />
                            </div>
                            <div className="width-1/3@medium">
                              <InputWrapper
                                label="City"
                                name={FIELDNAMES.BILL_DELIVERY_ADDRESS.CITY}
                                content={({ error }) => (
                                  <Field
                                    type="text"
                                    component="input"
                                    name={FIELDNAMES.BILL_DELIVERY_ADDRESS.CITY}
                                    className={classNames(
                                      'input',
                                      error && 'input--error',
                                    )}
                                  />
                                )}
                              />
                            </div>
                            <div className="width-1/3@medium">
                              <InputWrapper
                                label="Postcode"
                                name={FIELDNAMES.BILL_DELIVERY_ADDRESS.POSTCODE}
                                content={({ error }) => (
                                  <Field
                                    type="text"
                                    component="input"
                                    name={
                                      FIELDNAMES.BILL_DELIVERY_ADDRESS.POSTCODE
                                    }
                                    normalize={normalizeNumbers}
                                    className={classNames(
                                      'input',
                                      error && 'input--error',
                                    )}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </InputErrorBlock>
                </div>
              </div>
            </div> */}

            <div className="section section--stacked">
              <div className="line-length">
                <p>
                  <button
                    type="button"
                    className="btn btn--hollow-dark"
                    onClick={prevScreen}
                    data-key={
                      providerTransfer
                        ? 'BackProviderTransfer'
                        : 'BackAccountDetails'
                    }
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn u-float-right"
                    data-key="NextReview"
                  >
                    Next
                  </button>
                </p>
                {submitFailed && invalid && (
                  <ErrorMessage
                    className="u-text-right"
                    error={SCREEN_VALIDATION_ERROR_MESSAGE}
                  />
                )}
              </div>
            </div>
            <div className="section section--stacked">
              <div className="line-length">
                <div
                  className="u-text-s"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cms && cms.disclaimer && cms.disclaimer.description,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="width-1/3@large">
            {/* @ts-ignore */}
            <CartSummary />
          </div>
        </div>
      </div>
    </form>
  );
}
