import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, bindActionCreators, type Dispatch } from 'redux';
import PersonalAccountScreen from './PersonalAccountScreen';
import {
  willShowAdditionalAuthDetails,
  willShowPreferredStartDateDetails,
  willShowHardwareDeliveryDetails,
  psersonAccountCMSSelector,
  willShowHardwareDelivery,
  personalAccountPayloadSelector,
} from './PersonalAccountScreen.selectors';
import { prevScreen, nextScreen, captureFormDetails } from '../Screen.actions';
import { addressSelector } from '../../state/availability/availability.selectors';
import { phonelineSelectedSelector } from '../ExtrasScreen/ExtrasScreen.selectors';
import { type State } from '../../state/constants';
import { FORM_NAME } from '../Screen.constants';
import {
  syncValidate,
  asyncValidate,
} from './PersonalAccountScreen.validation';
import { providerTransferSelector } from '../Screen.selectors';
import { post } from '../../../../utils/http/http';

function mapStateToProps(state: State) {
  return {
    address: addressSelector(state),
    phonelineSelected: phonelineSelectedSelector(state),
    providerTransfer: providerTransferSelector(state),
    willShowAdditionalAuthDetails: willShowAdditionalAuthDetails(state),
    willShowPreferredStartDateDetails: willShowPreferredStartDateDetails(state),
    willShowHardwareDelivery: willShowHardwareDelivery(state),
    willShowHardwareDeliveryDetails: willShowHardwareDeliveryDetails(state),
    personalAccount: personalAccountPayloadSelector(state),
    cms: psersonAccountCMSSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return bindActionCreators(
    {
      prevScreen,
      captureFormDetails,
    },
    dispatch,
  );
}

export default compose(
  connect(
    mapStateToProps,
    // @ts-ignore
    mapDispatchToProps,
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate: syncValidate,
    onSubmit: (
      // @ts-ignore
      values: any,
      dispatch: Dispatch<any>,
      { personalAccount },
    ) =>
      post({
        path: '/acquisitions/api/v1/validate/personal_details/',
        payload: {
          first_name: personalAccount && personalAccount.firstName,
          last_name: personalAccount && personalAccount.lastName,
          email: personalAccount && personalAccount.email,
          mobile_number: personalAccount && personalAccount.mobilePhone,
          date_of_birth: personalAccount && personalAccount.dateOfBirth,
          additional_authority: {
            first_name:
              personalAccount &&
              personalAccount.additionalAuth &&
              personalAccount.additionalAuth.firstName,
            last_name:
              personalAccount &&
              personalAccount.additionalAuth &&
              personalAccount.additionalAuth.lastName,
            mobile:
              personalAccount &&
              personalAccount.additionalAuth &&
              personalAccount.additionalAuth.mobilePhone,
          },
          connection_date: personalAccount.connectionDate,
          hardware_pickup: personalAccount.pickup,
          hardware_delivery_address: {
            name:
              personalAccount &&
              personalAccount.deliveryAddress &&
              personalAccount.deliveryAddress.name,
            line1:
              personalAccount &&
              personalAccount.deliveryAddress &&
              personalAccount.deliveryAddress.line1,
            line2:
              personalAccount &&
              personalAccount.deliveryAddress &&
              personalAccount.deliveryAddress.line2,
            suburb:
              personalAccount &&
              personalAccount.deliveryAddress &&
              personalAccount.deliveryAddress.suburb,
            city:
              personalAccount &&
              personalAccount.deliveryAddress &&
              personalAccount.deliveryAddress.city,
            postcode:
              personalAccount &&
              personalAccount.deliveryAddress &&
              personalAccount.deliveryAddress.postcode,
          },
        },
      })
        .then(asyncValidate)
        .then(() => {
          dispatch(nextScreen());
        }),
  }),
)(
  // @ts-ignore
  PersonalAccountScreen,
);
