import { connect } from 'react-redux';
import TrackingPixels from './TrackingPixels';
import {
    orderIdSelector,
    chosenContractSelector,
} from '../../screens/Screen.selectors';
import { monthlyPaymentSelector } from '../CartSummary/CartSummary.selectors';

const mapStateToProps = state => ({
    orderId: orderIdSelector(state),
    contract: chosenContractSelector(state),
    price: monthlyPaymentSelector(state),
});

export default connect(mapStateToProps)(TrackingPixels);
