import { put, call, select, takeLatest } from 'redux-saga/effects';
import { SCREENS } from './Screen.constants';
import {
  PREV_SCREEN,
  NEXT_SCREEN,
  CAPTURE_FORM_DETAILS,
  goToScreen,
  SUBMIT_SCREEN,
  submitScreenSuccess,
  submitScreenFailed,
  storeOrderId,
} from './Screen.actions';
import {
  currentScreenSelector,
  providerTransferSelector,
} from './Screen.selectors';
import { planPickerPayloadSelector } from '../state/payload/payload.selectors';
import { type PlanPickerPayload } from '../state/payload/payload.constants';
import { post } from '../../../utils/http/http';

async function postCapturedFormDetails(payload: PlanPickerPayload) {
  const response = await post({
    path: '/acquisitions/api/v1/track_lead/',
    payload,
  });
  return response;
}

function* prevScreen() {
  const currentScreen = yield select(currentScreenSelector);

  switch (currentScreen) {
    case SCREENS.PlanDetails:
      window.location.replace(`/address-finder/?${window.location.search}`);
      break;
    case SCREENS.ChooseRouter:
      yield put(goToScreen(SCREENS.PlanDetails));
      break;
    case SCREENS.Extras:
      yield put(goToScreen(SCREENS.ChooseRouter));
      break;
    case SCREENS.PersonalAccount:
      yield put(goToScreen(SCREENS.Extras));
      break;
    case SCREENS.ProviderTransfer:
      yield put(goToScreen(SCREENS.PersonalAccount));
      break;
    case SCREENS.PaymentOption: {
      const providerTransfer = yield select(providerTransferSelector);
      if (providerTransfer) {
        yield put(goToScreen(SCREENS.ProviderTransfer));
      } else {
        yield put(goToScreen(SCREENS.PersonalAccount));
      }
      break;
    }
    case SCREENS.Review:
      yield put(goToScreen(SCREENS.PaymentOption));
      break;
    default:
      break;
  }
}

function* nextScreen() {
  const currentScreen = yield select(currentScreenSelector);

  if (currentScreen === SCREENS.PlanDetails) {
    yield put(goToScreen(SCREENS.ChooseRouter));
    return;
  }
  if (currentScreen === SCREENS.ChooseRouter) {
    yield put(goToScreen(SCREENS.Extras));
    return;
  }
  if (currentScreen === SCREENS.Extras) {
    yield put(goToScreen(SCREENS.PersonalAccount));
    return;
  }
  if (currentScreen === SCREENS.PersonalAccount) {
    const providerTransfer = yield select(providerTransferSelector);
    if (providerTransfer) {
      yield put(goToScreen(SCREENS.ProviderTransfer));
    } else {
      yield put(goToScreen(SCREENS.PaymentOption));
    }

    const payload = yield select(planPickerPayloadSelector);
    yield call(postCapturedFormDetails, payload);
    return;
  }

  if (currentScreen === SCREENS.ProviderTransfer) {
    yield put(goToScreen(SCREENS.PaymentOption));

    const payload = yield select(planPickerPayloadSelector);
    yield call(postCapturedFormDetails, payload);
    return;
  }

  if (currentScreen === SCREENS.PaymentOption) {
    yield put(goToScreen(SCREENS.Review));

    const payload = yield select(planPickerPayloadSelector);
    yield call(postCapturedFormDetails, payload);
    return;
  }
  if (currentScreen === SCREENS.Review) {
    yield put(goToScreen(SCREENS.Success));
  }
}

function* submitCapturedFormDetails() {
  const payload = yield select(planPickerPayloadSelector);
  try {
    const response = yield call(postCapturedFormDetails, payload);
    const { order_id: orderId } = response.data;
    yield put(storeOrderId(orderId));
  } catch (e) {
    // eslint-disable-next-line
    console.error('could not post form details', e);
  }
}

async function postPlanPicker(payload: PlanPickerPayload) {
  const response = await post({
    path: '/acquisitions/api/v1/provision/',
    payload,
  });
  return response;
}

function* submitScreen({ form: { resolve, reject } }) {
  const payload = yield select(planPickerPayloadSelector);
  try {
    const { data } = yield call(postPlanPicker, payload);
    yield put(submitScreenSuccess(data.order_id));
    yield put(goToScreen(SCREENS.Success));
    yield call(resolve);
  } catch (e) {
    yield put(submitScreenFailed(e.message));
    yield call(reject, e);
  }
}

export default function* saga() {
  yield takeLatest(PREV_SCREEN, prevScreen);
  yield takeLatest(NEXT_SCREEN, nextScreen);

  // @ts-ignore
  yield takeLatest(SUBMIT_SCREEN, submitScreen);
  yield takeLatest(CAPTURE_FORM_DETAILS, submitCapturedFormDetails);
}
