import CartSummary from '../../components/CartSummary/CartSummary.container';
import ProgressTracker from '../../components/ProgressTracker/ProgressTracker.container';
import ChoiceInput from '../../components/ChoiceInput/ChoiceInput';
import Icon from '../../../../components/Icon/Icon';
import {
  FIELDNAMES,
  OPTIONS,
  SCREEN_VALIDATION_ERROR_MESSAGE,
} from '../Screen.constants';
import type {
  Option,
  Product,
  Characteristic,
  CMSExtras,
} from '../../state/content/content.constants';
import InputErrorBlock from '../../components/InputError/InputErrorBlock.container';
import InputErrorMessage from '../../components/InputError/InputErrorMessage.container';
import ErrorMessage from '../../components/InputError/InputErrorMessage';

export type ExtrasScreenProps = {
  prevScreen: () => void;
  phoneLine: Product;
  phoneBundle: Product;
  landlineCalling?: Characteristic;
  landlineCallingOptions: Array<Option>;
  mobileCalling?: Characteristic;
  mobileCallingOptions: Array<Option>;
  techAssistOptions: Array<Option>;
  willShowSelectPhoneline: boolean;
  willShowLandlineCalling: boolean;
  willShowLandlineCallingOption: boolean;
  willShowMobileCalling: boolean;
  willShowMobileCallingOption: boolean;
  willShowPhoneBundle: boolean;
  willShowTechAssist: boolean;
  handleSubmit: () => void;
  submitFailed: boolean;
  invalid: boolean;
  cms: CMSExtras;
};

export default function ExtrasScreen({
  prevScreen,
  phoneLine,
  phoneBundle,
  landlineCalling,
  landlineCallingOptions,
  mobileCalling,
  mobileCallingOptions,
  // techAssistOptions,
  willShowSelectPhoneline,
  willShowLandlineCalling,
  willShowLandlineCallingOption,
  willShowMobileCalling,
  willShowMobileCallingOption,
  willShowPhoneBundle,
  // willShowTechAssist,
  handleSubmit,
  submitFailed,
  invalid,
  cms,
}: ExtrasScreenProps) {
  const showAssistanceLink =
    willShowLandlineCalling || willShowMobileCalling || willShowPhoneBundle;
  return (
    <form onSubmit={handleSubmit}>
      <div className="banner">
        <div className="banner__content line-length-large">
          <h1
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cms && cms.screen && cms.screen.heading,
            }}
          />
        </div>
      </div>
      {/* @ts-ignore */}
      <ProgressTracker />
      <div className="section section--no-bottom section--no-top@small">
        <div className="grid-flex grid--row-spacing-s grid--col-spacing">
          <div className="width-2/3@large">
            <div className="line-length">
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cms && cms.screen && cms.screen.description,
                }}
              />
            </div>
            <div className="section section--stacked">
              <div className="icon-block icon-block--lg">
                <div className="icon-block__image">
                  <Icon type="icon-choose-phone" className="icon-block__svg" />
                </div>
                <div className="icon-block__content">
                  {willShowSelectPhoneline && (
                    // @ts-ignore
                    <InputErrorBlock name={FIELDNAMES.SELECT_PHONELINE}>
                      <InputErrorMessage name={FIELDNAMES.SELECT_PHONELINE} />
                      <div>
                        <h3 className="u-mb-m">Need a home phoneline?</h3>
                        <div className="width-full">
                          <ChoiceInput
                            type="radio"
                            name={FIELDNAMES.SELECT_PHONELINE}
                            value={OPTIONS.SELECT_PHONELINE.NO}
                            label="No thanks"
                          />
                        </div>
                        <div className="width-full">
                          <ChoiceInput
                            type="radio"
                            name={FIELDNAMES.SELECT_PHONELINE}
                            value={phoneLine.provisioning_key}
                            sublabel={phoneLine.description}
                            label="Yes please"
                          />
                        </div>
                      </div>
                    </InputErrorBlock>
                  )}
                  {willShowPhoneBundle && (
                    <div className="form-group form-group--inset">
                      <ChoiceInput
                        type="checkbox"
                        name={FIELDNAMES.PHONE_BUNDLE}
                        value={phoneBundle.provisioning_key}
                        label={phoneBundle.name}
                        sublabel={phoneBundle.description}
                      />
                    </div>
                  )}

                  {willShowLandlineCalling &&
                    landlineCalling &&
                    landlineCallingOptions.length > 0 && (
                      <div className="form-group form-group--inset">
                        <ChoiceInput
                          type="checkbox"
                          name={FIELDNAMES.LANDLINE_CALLING}
                          value={landlineCalling.provisioning_key}
                          label={landlineCalling.name}
                          sublabel={landlineCalling.description}
                        />
                      </div>
                    )}

                  {willShowLandlineCallingOption &&
                    landlineCallingOptions.length > 0 && (
                      <div className="form-group form-group--inset u-mb-l">
                        <div className="form-group form-group--inset">
                          {/* @ts-ignore */}
                          <InputErrorBlock
                            name={FIELDNAMES.LANDLINE_CALLING_OPTION}
                          >
                            <InputErrorMessage
                              name={FIELDNAMES.LANDLINE_CALLING_OPTION}
                            />
                            {landlineCallingOptions.map((router) => (
                              <div
                                className="width-full"
                                key={router.provisioning_key}
                              >
                                <ChoiceInput
                                  type="radio"
                                  name={FIELDNAMES.LANDLINE_CALLING_OPTION}
                                  value={router.provisioning_key}
                                  label={router.name}
                                  sublabel={router.description}
                                />
                              </div>
                            ))}
                          </InputErrorBlock>
                        </div>
                      </div>
                    )}

                  {willShowMobileCalling &&
                    mobileCalling &&
                    mobileCallingOptions.length > 0 && (
                      <div className="form-group form-group--inset">
                        <ChoiceInput
                          type="checkbox"
                          name={FIELDNAMES.MOBILE_CALLING}
                          value={mobileCalling.provisioning_key}
                          label={mobileCalling.name}
                          sublabel={mobileCalling.description}
                        />
                      </div>
                    )}

                  {willShowMobileCallingOption &&
                    mobileCallingOptions.length > 0 && (
                      <div className="form-group form-group--inset">
                        <div className="form-group form-group--inset">
                          {/* @ts-ignore */}
                          <InputErrorBlock
                            name={FIELDNAMES.MOBILE_CALLING_OPTION}
                          >
                            <InputErrorMessage
                              name={FIELDNAMES.MOBILE_CALLING_OPTION}
                            />
                            {mobileCallingOptions.map((router) => (
                              <div
                                className="width-full"
                                key={router.provisioning_key}
                              >
                                <ChoiceInput
                                  type="radio"
                                  name={FIELDNAMES.MOBILE_CALLING_OPTION}
                                  value={router.provisioning_key}
                                  label={router.name}
                                  sublabel={router.description}
                                />
                              </div>
                            ))}
                          </InputErrorBlock>
                        </div>
                      </div>
                    )}
                  {showAssistanceLink && (
                    <p className="label--helptext u-mt">
                      {`Rely on your phone for medical, safety or disability
                      reasons? Learn more about our `}
                      <a
                        href="/support/broadband/calling/vulnerable-consumers-111-calling/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        support for vulnerable customers
                        <span className="u-accessible">
                          , this link opens in a new tab
                        </span>
                      </a>
                      .
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Commented out as part of https://springload-nz.atlassian.net/browse/NNS-62 */}
            {/* {willShowTechAssist && (
              <div className="section section--stacked">
                <div className="icon-block icon-block--lg">
                  <div className="icon-block__image">
                    <Icon
                      type="icon-choose-assist"
                      className="icon-block__svg"
                    />
                  </div>
                  <div className="icon-block__content">
                    <h3>Would you like a home tech assist membership?</h3>
                    <p className="u-mb-m">
                      Our home tech assist memberships give you on-call access
                      to fix tech problems in your home when they pop up (e.g.
                      Wi-Fi coverage, slow computers, setting up new
                      devices/streaming).
                    </p>
                    <InputErrorBlock name={FIELDNAMES.TECH_ASSIST}>
                      <InputErrorMessage name={FIELDNAMES.TECH_ASSIST} />
                      {techAssistOptions.map((option) => (
                        <div
                          className="width-full"
                          key={option.provisioning_key}
                        >
                          <ChoiceInput
                            type="radio"
                            name={FIELDNAMES.TECH_ASSIST}
                            value={option.provisioning_key}
                            label={option.name}
                            sublabel={option.description}
                          />
                        </div>
                      ))}
                    </InputErrorBlock>
                    <p className="u-text-s">
                      <a
                        href="/personal/home-tech-assist/"
                        target="_blank"
                        className="link link--underline"
                      >
                        Read more about our assistance subscriptions
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )} */}
            <div className="section section--stacked">
              <p>
                Looking for a static IP address? Don’t worry, you can add one
                once you’ve signed up, just{' '}
                <a
                  href="/contact-us"
                  target="_blank"
                  className="link link--underline"
                >
                  get in touch
                </a>
                .
              </p>
            </div>
            <div className="section section--stacked">
              <p>
                <button
                  type="button"
                  className="btn btn--hollow-dark"
                  onClick={prevScreen}
                  data-key="BackPlanDetails"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn u-float-right"
                  data-key="NextAccountDetails"
                >
                  Next
                </button>
              </p>
              {submitFailed && invalid && (
                <ErrorMessage
                  className="u-text-right"
                  error={SCREEN_VALIDATION_ERROR_MESSAGE}
                />
              )}
            </div>
            <div className="section section--stacked">
              <div className="line-length">
                <div
                  className="u-text-s"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cms && cms.disclaimer && cms.disclaimer.description,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="width-1/3@large">
            {/* @ts-ignore */}
            <CartSummary />
          </div>
        </div>
      </div>
    </form>
  );
}
