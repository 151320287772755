//

import { createSelector } from 'reselect';
import {
  formValueSelector,
  getFormMeta,
  getFormSyncErrors,
  getFormAsyncErrors,
  getFormSubmitErrors,
  isDirty,
} from 'redux-form';
import { type State, type Address, CONNECTIONS } from '../state/constants';
import type {
  Product,
  Option,
  ProductWithDiscount,
  OptionWithDiscount,
  DiscountProduct,
} from '../state/content/content.constants';
import {
  FORM_NAME,
  FIELDNAMES,
  OPTIONS,
  type Screen,
  type Fieldname,
  type Provider,
  fieldnameToProduct,
  FREE_NO_CONTRACT_KEY,
  SCREENS,
} from './Screen.constants';
import {
  packagesSelector,
  contentSelector,
  contractsSelector,
  productSelector,
  productsSelector,
} from '../state/content/content.selectors';
import {
  findPackageDataCap,
  findPackageSpeed,
  findPackageContract,
  findPackageExtras,
} from '../state/package/package.selectors';
import { addressSelector } from '../state/availability/availability.selectors';
import {
  offerDefaultContractSelector,
  offerDefaultFibreContractSelector,
} from '../state/offer/offer.selectors';

const baseSelector = (state: State) => state.screens;

export const currentScreenSelector: (State) => Screen = createSelector(
  baseSelector,
  (state) => state.currentScreen,
);

/*
 * Top-level form-value selectors.
 */

// Utility form selectors

export const formValuesSelector = formValueSelector(FORM_NAME);

export const isFormDirtySelector = isDirty(FORM_NAME);
const metaSelector = getFormMeta(FORM_NAME);
const syncErrorsSelector = getFormSyncErrors(FORM_NAME);
const asyncErrorsSelector = getFormAsyncErrors(FORM_NAME);
const submitErrorsSelector = getFormSubmitErrors(FORM_NAME);

export const formMetaSelector = createSelector(metaSelector, (meta) => meta);

export const formSyncErrorsSelector = createSelector(
  syncErrorsSelector,
  (errors) => errors,
);
export const formAsyncErrorsSelector = createSelector(
  asyncErrorsSelector,
  (errors) => errors,
);
export const formSubmitErrorsSelector = createSelector(
  submitErrorsSelector,
  (errors) => errors,
);

export const fieldErrorSelector = createSelector(
  formSyncErrorsSelector,
  formAsyncErrorsSelector,
  formSubmitErrorsSelector,
  formMetaSelector,
  (syncErrors, asyncErrors, submitErrors, formMeta) =>
    (fieldname: Fieldname, forceDisplay: boolean = false) => {
      const { touched } = formMeta[fieldname] || { touched: false };
      const error =
        (syncErrors && syncErrors[fieldname]) ||
        (asyncErrors && asyncErrors[fieldname]) ||
        (submitErrors && submitErrors[fieldname]);
      return touched || forceDisplay ? error : null;
    },
);

/* a selector we can use with createSelector and a dynamic field name
 * const exampleSelector = createSelector(
     formFieldValueSelector,
     (fieldValueSelector) => {
         const value = fieldValueSelector(FIELD_NAME)
     }
 )
 */
export const formFieldValueSelector =
  (state: State) => (fieldname: Fieldname) =>
    formValuesSelector(state, fieldname);

export const selectDiscountProduct = (
  originalProduct: Product,
  offerDiscountProducts: Array<DiscountProduct>,
  packageDiscountProducts: Array<DiscountProduct>,
): ProductWithDiscount => {
  const offerDiscountProduct =
    offerDiscountProducts &&
    offerDiscountProducts.find(
      (product) => originalProduct.id === product.replaces_product,
    );
  if (offerDiscountProduct)
    return {
      ...offerDiscountProduct,
      type: 'offer',
      image: offerDiscountProduct.image || originalProduct.image,
      original_product: originalProduct,
    };

  const packageDiscountProduct =
    packageDiscountProducts &&
    packageDiscountProducts.find(
      (product) => originalProduct.id === product.replaces_product,
    );

  if (packageDiscountProduct)
    return {
      ...packageDiscountProduct,
      type: 'package',
      image: packageDiscountProduct.image || originalProduct.image,
      original_product: originalProduct,
    };

  return { ...originalProduct, original_product: undefined };
};

export const selectDiscountProductOptions = (
  originalProducts: Array<Option>,
  offerDiscountProducts: Array<DiscountProduct>,
  packageDiscountProducts: Array<DiscountProduct>,
  offerIsValid: boolean,
): Array<OptionWithDiscount> => {
  const options = originalProducts.map((option): OptionWithDiscount => {
    const offerDiscountProduct =
      offerDiscountProducts &&
      offerDiscountProducts.find(
        (product) => option.id === product.replaces_product,
      );
    if (offerDiscountProduct && offerIsValid)
      return {
        ...offerDiscountProduct,
        type: 'offer',
        image: offerDiscountProduct.image || option.image,
        original_product: option,
      };

    const packageDiscountProduct =
      packageDiscountProducts &&
      packageDiscountProducts.find(
        (product) => option.id === product.replaces_product,
      );

    if (packageDiscountProduct)
      return {
        ...packageDiscountProduct,
        type: 'package',
        image: packageDiscountProduct.image || option.image,
        original_product: option,
      };

    return { ...option, original_product: undefined };
  });

  return options;
};

// Screen 3 - Plan Details

export const chosenBroadbandDataCapSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.DATA_CAP);

export const chosenPackageSelector = createSelector(
  chosenBroadbandDataCapSelector,
  packagesSelector,
  (value, packages) => packages.find((pk) => pk.id === value),
);

export const chosenPackageDiscountProductsSelector = createSelector(
  chosenPackageSelector,
  (pkg) => pkg && pkg.discount_products,
);

// Selects the true selected datacap either chosen directly or through a displayed package
export const chosenPackageDataCapSelector = createSelector(
  chosenBroadbandDataCapSelector,
  chosenPackageSelector,
  contentSelector,
  (value, pkg, content) => {
    if (!pkg) {
      return value;
    }
    // @ts-ignore
    const dataCap = findPackageDataCap(pkg, content);
    // @ts-ignore
    return dataCap ? dataCap.item && dataCap.item.provisioning_key : value;
  },
);

export const chosenBroadbandConnectionSpeedSelector = (state: State): string =>
  formValuesSelector(state, FIELDNAMES.CONNECTION_SPEED);

export const chosenPackageSpeedSelector = createSelector(
  chosenBroadbandConnectionSpeedSelector,
  chosenPackageSelector,
  contentSelector,
  (value, pkg, content) => {
    if (!pkg) {
      return value;
    }
    // @ts-ignore
    const speed = findPackageSpeed(pkg, content);
    // @ts-ignore
    return speed ? speed.item && speed.item.provisioning_key : value;
  },
);

export const chosenPackageExtrasSelector = createSelector(
  chosenPackageSelector,
  contentSelector,
  (pkg, content) => {
    if (!pkg) {
      return null;
    }
    // @ts-ignore
    return findPackageExtras(pkg, content);
  },
);

const contractFieldValueSelector = (state: State): string =>
  formValuesSelector(state, FIELDNAMES.CONTRACT);

export const chosenContractSelector = createSelector(
  chosenPackageSpeedSelector,
  offerDefaultContractSelector,
  contractFieldValueSelector,
  offerDefaultFibreContractSelector,
  (speed, offerDefaultContract, contract, offerFibreContract) => {
    if (offerDefaultContract) {
      return offerDefaultContract.provisioning_key;
    }
    if (
      [CONNECTIONS.adsl, CONNECTIONS.vdsl].includes(
        speed && speed.toLowerCase(),
      )
    ) {
      return contract;
    }
    // if it's a fibre connection it automatically selects the no contract without fee
    return (
      speed &&
      ((offerFibreContract && offerFibreContract.provisioning_key) ||
        FREE_NO_CONTRACT_KEY)
    );
  },
);

export const chosenPackageContractSelector = createSelector(
  chosenContractSelector,
  chosenPackageSelector,
  contentSelector,
  (value, pkg, content) => {
    if (!pkg) {
      return value;
    }
    // @ts-ignore
    const contract = findPackageContract(pkg, content);
    // @ts-ignore
    return contract ? contract.provisioning_key : value;
  },
);

export const chosenContractDataSelector = createSelector(
  contractsSelector,
  chosenContractSelector,
  (contracts, chosenContract) =>
    contracts.find(
      (contract) => contract && contract.provisioning_key === chosenContract,
    ),
);

// Screen 4 Router

export const selectRouterSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.SELECT_ROUTER);

export const routerPaymentSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.ROUTER_PAYMENT);

export const hasIncludedRouterSelector = createSelector(
  selectRouterSelector,
  (routerSelector): boolean =>
    routerSelector && routerSelector !== OPTIONS.INCLUDE_ROUTER.NO,
  // "Router selected?"
  // This is a hardcoded provisioning key agreed upon with
);

export const isRouterMonthlyPaymentSelector = createSelector(
  hasIncludedRouterSelector,
  routerPaymentSelector,
  (hasIncludedRouter, value): boolean =>
    hasIncludedRouter && value !== OPTIONS.ROUTER_PAYMENT.NO,
);

export const routerPaymentMonthsSelector = createSelector(
  routerPaymentSelector,
  (routerPayment) => {
    switch (routerPayment) {
      case OPTIONS.ROUTER_PAYMENT.MONTHLY_PAYMENTS_12:
        return 12;
      case OPTIONS.ROUTER_PAYMENT.MONTHLY_PAYMENTS_24:
        return 24;
      default:
        return 1;
    }
  },
);

// Screen 5 - Extras

export const selectPhonelineSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.SELECT_PHONELINE);

export const landlineCallingSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.LANDLINE_CALLING);

export const landlineCallingOptionSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.LANDLINE_CALLING_OPTION);

export const mobileCallingSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.MOBILE_CALLING);

export const mobileCallingOptionSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.MOBILE_CALLING_OPTION);

export const techAssistSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.TECH_ASSIST);

export const phoneBundleSelectedSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.PHONE_BUNDLE);

/*
 * Screen 6 – Personal Account Details
 */
export const firstNameSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.FIRST_NAME);

export const lastNameSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.LAST_NAME);

export const mobilePhoneSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.MOBILE_PHONE);

export const emailAddressSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.EMAIL_ADDRESS);

export const additionalAuthSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.ADDITIONAL_AUTH);

export const preferredStartDateSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.PREFERRED_START_DATE);

export const preferredStartDateDaySelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.PREFERRED_START_DATE_DETAILS.DD);

export const preferredStartDateMonthSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.PREFERRED_START_DATE_DETAILS.MM);

export const preferredStartDateYearSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.PREFERRED_START_DATE_DETAILS.YYYY);

export const preferredStartDateValueSelector = createSelector(
  preferredStartDateSelector,
  preferredStartDateDaySelector,
  preferredStartDateMonthSelector,
  preferredStartDateYearSelector,
  (preferredStartDate, day, month, year) =>
    preferredStartDate === OPTIONS.PREFERRED_START_DATE.ASAP
      ? OPTIONS.PREFERRED_START_DATE.ASAP
      : year && month && day && `${day}/${month}/${year}`,
);

export const dateOfBirthDaySelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.DATE_OF_BIRTH.DD);

export const dateOfBirthMonthSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.DATE_OF_BIRTH.MM);

export const dateOfBirthYearSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.DATE_OF_BIRTH.YYYY);

export const fullNameSelector = createSelector(
  firstNameSelector,
  lastNameSelector,
  (firstName, lastName) => `${firstName || ''} ${lastName || ''}`,
);

export const dateOfBirthSelector: (State) => string | undefined =
  createSelector(
    dateOfBirthDaySelector,
    dateOfBirthMonthSelector,
    dateOfBirthYearSelector,
    (day, month, year) =>
      day && month && year ? `${day}/${month}/${year}` : null,
  );

export const hardwareDeliverySelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.HARDWARE_DELIVERY);

export const additionalAuthFirstNameSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.AUTH_FIRST_NAME);

export const additionalAuthLastNameSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.AUTH_LAST_NAME);

export const additionalAuthMobileSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.AUTH_CONTACT_NUMBER);

export type AdditionalAuthPayload = {
  firstName: string;
  lastName: string;
  mobilePhone: string;
};

export const additionalAuthDetailsSelector = createSelector(
  additionalAuthSelector,
  additionalAuthFirstNameSelector,
  additionalAuthLastNameSelector,
  additionalAuthMobileSelector,
  (additionalAuth, firstName, lastName, mobilePhone) =>
    additionalAuth === OPTIONS.ADDITIONAL_AUTH.SELECTED
      ? {
          firstName,
          lastName,
          mobilePhone,
        }
      : undefined,
);

export const providerTransferSelector = (state: State): boolean => {
  const providerTransfer = formValuesSelector(
    state,
    FIELDNAMES.PROVIDER_TRANSFER,
  );
  return providerTransfer === OPTIONS.PROVIDER_TRANSFER.YES;
};

/*
 * Screen 7 – Provider Transfer Information
 */
export const chosenProviderSelector = (state: State): Provider =>
  formValuesSelector(state, FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS);

export const chosenOtherProviderSelector = (state: State): Provider =>
  formValuesSelector(state, FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS_OTHER);

export const chosenServiceTypeSelector = (state: State): Provider =>
  formValuesSelector(state, FIELDNAMES.TRANSFERRING_SERVICE_TYPES);

// TODO: Possibly rename this selector because this is more like determining
//   whether it's appropriate to get the chosenProvider or not depends on
//   whether providerTransfer has been selected.
export const providerDetailsSelector = createSelector(
  providerTransferSelector,
  chosenProviderSelector,
  (providerTransfer, chosenProvider) =>
    providerTransfer ? chosenProvider : null,
);

export const providerAccountNumberSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.ACCOUNT_NUMBER);

export const providerPhoneNumberSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.EXISTING_NUMBER);

export const providerKeepPhoneNumberSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.KEEP_EXISTING_NUMBER);

type ProviderDetails = {
  provider: Provider;
  transferPhone: boolean;
  accountNumber: string;
  phoneNumber: string;
  keepPhoneNumber: boolean;
};

export const providerTransferDetailsSelector: (
  state: State,
) => ProviderDetails = createSelector(
  chosenProviderSelector,
  chosenOtherProviderSelector,
  chosenServiceTypeSelector,
  providerAccountNumberSelector,
  providerPhoneNumberSelector,
  providerKeepPhoneNumberSelector,
  (
    provider,
    otherProvider,
    chosenServiceType,
    accountNumber,
    phoneNumber,
    keepPhoneNumber,
  ) => ({
    provider:
      provider === OPTIONS.TRANSFERRING_PROVIDER_DETAILS.OTHER.value
        ? otherProvider
        : provider,
    transferPhone:
      chosenServiceType ===
      OPTIONS.TRANSFERRING_SERVICE_TYPES.BROADBAND_AND_PHONE,
    accountNumber,
    phoneNumber,
    keepPhoneNumber,
  }),
);

/*
 * Screen 8 – Payment Options
 */
export const chosenPaymentMethodSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.PAYMENT_METHOD);

export const bankAccountNameSelector = (state) =>
  formValuesSelector(state, FIELDNAMES.BANK_NAME);

export const bankAccountOtherNameSelector = (state) =>
  formValuesSelector(state, FIELDNAMES.BANK_NAME_OTHER);

export const bankAccountHolderSelector = (state) =>
  formValuesSelector(state, FIELDNAMES.BANK_ACCOUNT_HOLDER);

export const bankAccountPrefixSelector = (state) =>
  formValuesSelector(state, FIELDNAMES.BANK_ACCOUNT_NUMBER.PREFIX);

export const bankAccountBranchSelector = (state) =>
  formValuesSelector(state, FIELDNAMES.BANK_ACCOUNT_NUMBER.BRANCH);

export const bankAccountAccountSelector = (state) =>
  formValuesSelector(state, FIELDNAMES.BANK_ACCOUNT_NUMBER.ACCOUNT);

export const bankAccountSuffixSelector = (state) =>
  formValuesSelector(state, FIELDNAMES.BANK_ACCOUNT_NUMBER.SUFFIX);

export const accountOperatingAuthoritySelector = (state) =>
  formValuesSelector(state, FIELDNAMES.ACCOUNT_OPERATING_AUTHORITY);

export const bankAccountTextSelecltor = createSelector(
  bankAccountPrefixSelector,
  bankAccountBranchSelector,
  bankAccountAccountSelector,
  bankAccountSuffixSelector,
  (prefix, branch, account, suffix) =>
    [prefix, branch, account, suffix].join('-'),
);

export const billDeliveryProductSelector = createSelector(
  productsSelector,
  (products) =>
    productSelector(products, fieldnameToProduct[FIELDNAMES.BILL_DELIVERY]),
);

export const billDeliverySelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.BILL_DELIVERY);

export const chosenBillDeliverySelector: (State) => Product | undefined =
  createSelector(
    billDeliverySelector,
    billDeliveryProductSelector,
    (selection, billDeliveryProduct) => {
      if (selection && selection !== OPTIONS.BILL_DELIVERY.EMAIL) {
        return billDeliveryProduct;
      }
      return null;
    },
  );

export const billDeliveryAddressSelector: (State) => Address | undefined = (
  state: State,
) => {
  let address;
  const billDeliveryOption = billDeliverySelector(state);

  if (billDeliveryOption === OPTIONS.BILL_DELIVERY.SERVICE_ADDRESS) {
    const serviceAddress = addressSelector(state);
    address = {
      name: fullNameSelector(state),
      // @ts-ignore
      line1: serviceAddress.line1,
      // @ts-ignore
      line2: serviceAddress.line2,
      // @ts-ignore
      suburb: serviceAddress.suburb,
      // @ts-ignore
      city: serviceAddress.city,
      // @ts-ignore
      postcode: serviceAddress.postcode,
    };
  } else if (billDeliveryOption === OPTIONS.BILL_DELIVERY.OTHER_ADDRESS) {
    address = {
      name: formValuesSelector(state, FIELDNAMES.BILL_DELIVERY_ADDRESS.NAME),
      line1: formValuesSelector(
        state,
        FIELDNAMES.BILL_DELIVERY_ADDRESS.ADDRESS_LINE_1,
      ),
      line2: formValuesSelector(
        state,
        FIELDNAMES.BILL_DELIVERY_ADDRESS.ADDRESS_LINE_2,
      ),
      suburb: formValuesSelector(
        state,
        FIELDNAMES.BILL_DELIVERY_ADDRESS.SUBURB,
      ),
      city: formValuesSelector(state, FIELDNAMES.BILL_DELIVERY_ADDRESS.CITY),
      postcode: formValuesSelector(
        state,
        FIELDNAMES.BILL_DELIVERY_ADDRESS.POSTCODE,
      ),
    };
  }

  return address;
};

/**
 * Screen 9 – Review and Confirmation
 */

export const contractAgreementSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.CONTRACT_AGREEMENT);
export const otherNotesSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.OTHER_NOTES);
export const csrIdSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.CSR_ID);
export const creditCheckSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.CREDIT_CHECK);

/**
 * Success screen
 */

export const orderIdSelector = createSelector(
  baseSelector,
  // @ts-ignore
  (state) => state.orderId,
);

/**
 * Cart Summary
 */
export const friendNameSelector = (state: State) =>
  formValuesSelector(state, FIELDNAMES.FRIEND_NAME);

/**
 * Progress Tracker
 */
export const skippedScreensSelector = createSelector(
  hasIncludedRouterSelector,
  (hasRouter) => (!hasRouter ? [SCREENS.ChooseRouter] : undefined),
);
