import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, bindActionCreators, type Dispatch } from 'redux';
import PlanDetailsScreen from './PlanDetailsScreen';
import { FORM_NAME } from '../Screen.constants';
import { prevScreen, nextScreen } from '../Screen.actions';
import {
  broadbandDataCapSelector,
  broadbandConnectionSpeedSelector,
  willShowConnectionSpeedsSelector,
  willShowContractSelector,
  planDetailsCMSSelector,
  planListSelector,
} from './PlanDetailsScreen.selectors';
import {
  availableConnectionTypesSelector,
  isFetching as isFetchingAvailabilitySelector,
} from '../../state/availability/availability.selectors';
import {
  contractsSelector,
  isFetchingSelector as isFetchingContentSelector,
} from '../../state/content/content.selectors';
import validate from './PlanDetailsScreen.validation';
import { packageSelector } from '../../state/package/package.selectors';

function mapStateToProps(state) {
  return {
    contracts: contractsSelector(state),
    broadbandDataCap: broadbandDataCapSelector(state),
    broadbandSpeed: broadbandConnectionSpeedSelector(state),
    willShowConnectionSpeed: willShowConnectionSpeedsSelector(state),
    willShowContract: willShowContractSelector(state),
    availableConnectionTypes: availableConnectionTypesSelector(state),
    planList: planListSelector(state),
    isFetchingContent: isFetchingContentSelector(state),
    isFetchingAvailability: isFetchingAvailabilitySelector(state),
    package: packageSelector(state),
    cms: planDetailsCMSSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onSubmit: () => dispatch(nextScreen()),
    ...bindActionCreators(
      {
        prevScreen,
      },
      dispatch,
    ),
  };
}

export default compose(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true, // Don't override user selections
    validate,
  }),
)(
  // @ts-ignore
  PlanDetailsScreen,
);
