//

import { FIELDNAMES } from '../Screen.constants';
import { required } from '../../../../utils/input/validation';

export default (formData) => ({
  [FIELDNAMES.DATA_CAP]:
    required(formData[FIELDNAMES.DATA_CAP]) &&
    'Please choose one of the following:',
  [FIELDNAMES.CONNECTION_SPEED]:
    required(formData[FIELDNAMES.CONNECTION_SPEED]) &&
    'Please choose one of the below speeds:',
  [FIELDNAMES.CONTRACT]:
    required(formData[FIELDNAMES.CONTRACT]) &&
    'Please choose one of the below contract options:',
});
