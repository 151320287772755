import { Component } from 'react';
import queryString from 'query-string';
import { SCREENS, type Screen } from '../screens/Screen.constants';
import ErrorPage from '../ErrorPage/ErrorPage';
import PlanDetailsScreenComponent from '../screens/PlanDetailsScreen/PlanDetailsScreen.container';
import ChooseRouterScreenComponent from '../screens/ChooseRouterScreen/ChooseRouterScreen.container';
import ExtrasScreenComponent from '../screens/ExtrasScreen/ExtrasScreen.container';
import PersonalAccountScreenComponent from '../screens/PersonalAccountScreen/PersonalAccountScreen.container';
import ProviderTransferScreenComponent from '../screens/ProviderTransferScreen/ProviderTransferScreen.container';
import PaymentOptionScreenComponent from '../screens/PaymentOptionScreen/PaymentOptionScreen.container';
import ReviewScreenComponent from '../screens/ReviewScreen/ReviewScreen.container';
import SuccessScreenComponent from '../screens/SuccessScreen/SuccessScreen.container';
import type { ErrorType } from '../ErrorPage/ErrorPage.constants';

type AppProps = {
  currentScreen: Screen;
  initialiseData: (addressId: string, packageId?: string) => void;
  availabilityError?: ErrorType;
  contentError?: ErrorType;
  isFormDirty: boolean;
  initialValues;
};

export default class App extends Component<AppProps> {
  constructor(props: AppProps) {
    super(props);

    const addressId = App.getAddressId();
    const packageId = App.getPackageId();

    // Address ID is required to run the app, all the rest are supplementary
    if (addressId) {
      props.initialiseData(addressId, packageId);
    } else {
      window.location.replace('/address-finder');
    }
  }

  static getParams = queryString.parse(window.location.search);

  static getPackageId() {
    // Aliasing package as pkg to make eslint happy.
    const { package: pkg } = App.getParams;
    return typeof pkg === 'string' && pkg.length > 0 ? pkg : null;
  }

  static getAddressId() {
    const { addressId } = App.getParams;
    return typeof addressId === 'string' && addressId.length > 0
      ? addressId
      : null;
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentDidUpdate(prevProps: AppProps) {
    const { currentScreen } = this.props;
    if (prevProps.currentScreen !== currentScreen) {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (e: Event) => {
    const { isFormDirty, currentScreen } = this.props;

    if (isFormDirty && currentScreen !== SCREENS.Success) {
      e.preventDefault();
      // Chrome requires returnValue to be set.
      // @ts-ignore
      e.returnValue = '';
    }
  };

  render() {
    const { currentScreen, availabilityError, contentError, initialValues } =
      this.props;
    if (availabilityError) {
      return <ErrorPage type={availabilityError} />;
    }
    if (contentError) {
      return <ErrorPage type={contentError} />;
    }
    switch (currentScreen) {
      case SCREENS.PlanDetails:
      default:
        // @ts-ignore
        return <PlanDetailsScreenComponent initialValues={initialValues} />;
      case SCREENS.ChooseRouter:
        // @ts-ignore
        return <ChooseRouterScreenComponent />;
      case SCREENS.Extras:
        // @ts-ignore
        return <ExtrasScreenComponent />;
      case SCREENS.PersonalAccount:
        // @ts-ignore
        return <PersonalAccountScreenComponent />;
      case SCREENS.ProviderTransfer:
        // @ts-ignore
        return <ProviderTransferScreenComponent />;
      case SCREENS.PaymentOption:
        // @ts-ignore
        return <PaymentOptionScreenComponent />;
      case SCREENS.Review:
        // @ts-ignore
        return <ReviewScreenComponent />;
      case SCREENS.Success:
        return <SuccessScreenComponent />;
    }
  }
}
