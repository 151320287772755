//

import { createSelector } from 'reselect';
import { type State, type Address, CONNECTIONS } from '../constants';

export const availabilitySelector = (state: State) => state.availability;

export const isFetching: (State) => boolean = createSelector(
  availabilitySelector,
  // @ts-ignore
  (base) => base && base.fetching,
);

export const addressSelector: (State) => Address = createSelector(
  availabilitySelector,
  (availability) => availability && availability.formatted_address,
);

export const availabilityErrorSelector = createSelector(
  availabilitySelector,
  // @ts-ignore
  (availability) => availability && availability.error,
);

export const availabilityErrorTypeSelector = createSelector(
  availabilitySelector,
  // @ts-ignore
  (availability) => availability && availability.errorType,
);

export const availableTypesSelector = createSelector(
  availabilitySelector,
  (availability) =>
    availability
      ? Object.keys(availability)
          .filter((key) => /^is_/.test(key))
          .filter((key) => availability[key] === true)
          .map((type) => type.replace('is_', ''))
      : null,
);

export const availableConnectionTypesSelector = createSelector(
  availableTypesSelector,
  (availableTypes) =>
    availableTypes.filter(
      (type) =>
        type === CONNECTIONS.adsl ||
        type === CONNECTIONS.vdsl ||
        type === CONNECTIONS.fibre,
    ),
);
