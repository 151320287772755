//

import { SubmissionError } from 'redux-form';
import { FIELDNAMES } from '../Screen.constants';
import { required } from '../../../../utils/input/validation';

export const validate = (formData) => ({
  [FIELDNAMES.TRANSFERRING_SERVICE_TYPES]:
    required(formData[FIELDNAMES.TRANSFERRING_SERVICE_TYPES]) &&
    'Please choose what services to transfer',
  [FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS]:
    required(formData[FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS]) &&
    'Please choose your current provider below',
  [FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS_OTHER]:
    required(formData[FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS_OTHER]) &&
    'Please enter the name of your current provider below',
  [FIELDNAMES.EXISTING_NUMBER]:
    required(formData[FIELDNAMES.EXISTING_NUMBER]) &&
    'Please enter your existing phone number below',
});

export const asyncValidate = ({ data: { errors } }) => {
  if (Object.keys(errors).length > 0) {
    throw new SubmissionError({
      [FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS]: errors.provider,
      [FIELDNAMES.ACCOUNT_NUMBER]: errors.account_number,
      [FIELDNAMES.EXISTING_NUMBER]: errors.existing_phone_number,
      [FIELDNAMES.TRANSFERRING_SERVICE_TYPES]: errors.has_existing_phone,
      [FIELDNAMES.KEEP_EXISTING_NUMBER]: errors.keep_phone_number,
    });
  }
};
