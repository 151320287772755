import Icon, { type IconType } from '../../../../components/Icon/Icon';
import './iconBlock.scss';

type Props = {
  icon: IconType;
  title: string;
  size?: 'sm' | 'lg';
  children?: JSX.Element;
};

const IconBlock = ({ icon, title, size = 'lg', children }: Props) => (
  <div className={`icon-block icon-block--mb icon-block--${size}`}>
    <div className="icon-block__image">
      <Icon type={icon} className="icon-block__svg" />
    </div>
    <div className="icon-block__content">
      <h4 className="u-mb-0">{title}</h4>
      {children}
    </div>
  </div>
);

export default IconBlock;
