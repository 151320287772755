import { Component } from 'react';
import CartSummary from '../../components/CartSummary/CartSummary.container';
import ProgressTracker from '../../components/ProgressTracker/ProgressTracker.container';
import PlanCard from '../../components/PlanCard/PlanCard';
import {
  FIELDNAMES,
  SCREEN_VALIDATION_ERROR_MESSAGE,
} from '../Screen.constants';
import type { Option, Contract } from '../../state/content/content.constants';
import { CONNECTIONS } from '../../state/constants';
import ChoiceInput from '../../components/ChoiceInput/ChoiceInput';
import { type CMSPlanDetails } from '../../state/content/content.constants';
import {
  type Package,
  type PackageState,
  PACKAGE_NOT_AVAILABLE_ERROR,
} from '../../state/package/package.constants';
import FieldInputError from '../../components/InputError/InputErrorMessage.container';
import InputErrorBlock from '../../components/InputError/InputErrorBlock.container';
import ErrorMessage from '../../components/InputError/InputErrorMessage';
import Icon from '../../../../components/Icon/Icon';

type PlanDataCap = Option & {
  isPackage: false;
};

type PlanPackage = Package & {
  isPackage: true;
};

type PlanList = PlanDataCap | PlanPackage;

type PlanDetailsScreenProps = {
  prevScreen: () => void;
  broadbandSpeed: Array<Option>;
  contracts: Array<Contract>;
  willShowConnectionSpeed: boolean;
  willShowContract: boolean;
  availableConnectionTypes: (keyof typeof CONNECTIONS)[];
  planList: Array<PlanList>;
  isFetchingContent: boolean;
  isFetchingAvailability: boolean;
  cms: CMSPlanDetails;
  handleSubmit: () => void;
  submitFailed: boolean;
  invalid: boolean;
  package: PackageState;
};

class PlanDetailsScreen extends Component<PlanDetailsScreenProps> {
  props: PlanDetailsScreenProps;

  static mapping = (value: string) => {
    switch (value) {
      case CONNECTIONS.adsl:
        return 1;
      case CONNECTIONS.vdsl:
        return 2;
      case CONNECTIONS.fibre:
        return 3;
      default:
        return 0;
    }
  };

  static sortConnectionTypes(
    connectionTypes: Array<keyof typeof CONNECTIONS>,
  ): Array<keyof typeof CONNECTIONS> {
    return connectionTypes.sort((typeA, typeB) => {
      const typeAValue = PlanDetailsScreen.mapping(typeA);
      const typeBValue = PlanDetailsScreen.mapping(typeB);

      if (typeAValue > typeBValue) return 1;
      if (typeAValue < typeBValue) return -1;

      return 0;
    });
  }

  render() {
    const {
      prevScreen,
      broadbandSpeed,
      contracts,
      willShowConnectionSpeed,
      willShowContract,
      availableConnectionTypes,
      planList,
      isFetchingContent,
      isFetchingAvailability,
      cms,
      handleSubmit,
      submitFailed,
      invalid,
      package: selectedPkg,
    } = this.props;

    let headingKey;

    // @ts-ignore
    if (selectedPkg.error === PACKAGE_NOT_AVAILABLE_ERROR) {
      headingKey = 'heading__package_not_available';
    } else {
      const sortedConnectionTypes: Array<keyof typeof CONNECTIONS> =
        PlanDetailsScreen.sortConnectionTypes(availableConnectionTypes);
      headingKey = `heading__${sortedConnectionTypes.join('_')}`;
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className="banner">
          <div className="banner__content line-length-large">
            {!isFetchingContent &&
              !isFetchingAvailability &&
              !selectedPkg.fetching && (
                <h1
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cms && cms.screen && cms.screen.heading,
                  }}
                />
              )}
          </div>
        </div>
        {/* @ts-ignore */}
        <ProgressTracker />
        <div className="section section--no-bottom section--no-top@small">
          {isFetchingContent || isFetchingAvailability ? (
            <Icon
              title="Fetching contents..."
              type="icon-loading"
              className="i--full-width i--sm-height"
            />
          ) : (
            <div className="grid-flex grid--row-spacing-s grid--col-spacing">
              <div className="width-2/3@large">
                <FieldInputError name={FIELDNAMES.DATA_CAP} />
                {planList.length > 0 && (
                  <div className="section section--no-top section--stacked">
                    <InputErrorBlock name={FIELDNAMES.DATA_CAP}>
                      <div className="line-length">
                        <h3
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html:
                              cms && cms[headingKey] && cms[headingKey].heading,
                          }}
                        />
                        <p
                          className="u-mb-m"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html:
                              cms &&
                              cms.heading__package &&
                              cms.heading__package.description,
                          }}
                        />
                        {planList.map((option) => (
                          <PlanCard
                            id={`${
                              option.isPackage
                                ? option.id
                                : // @ts-ignore
                                  option.provisioning_key
                            }`}
                            // @ts-ignore
                            key={
                              option.isPackage
                                ? option.id
                                : // @ts-ignore
                                  option.provisioning_key
                            }
                            title={option.name}
                            description={option.description}
                            name={FIELDNAMES.DATA_CAP}
                            amount={option.price_amount}
                            value={
                              option.isPackage
                                ? option.id
                                : // @ts-ignore
                                  option.provisioning_key
                            }
                            frequency={option.payment_frequency}
                          />
                        ))}
                      </div>
                    </InputErrorBlock>
                  </div>
                )}

                {willShowConnectionSpeed && (
                  <div className="section section--stacked">
                    <div className="line-length">
                      <InputErrorBlock name={FIELDNAMES.CONNECTION_SPEED}>
                        <FieldInputError name={FIELDNAMES.CONNECTION_SPEED} />
                        <h3 className="u-mb-m">Choose your speed</h3>
                        {broadbandSpeed
                          ? broadbandSpeed.map((option): JSX.Element | null => {
                              return (
                                <PlanCard
                                  id={`${option.provisioning_key}`}
                                  key={option.provisioning_key}
                                  type="speed"
                                  title={option.name}
                                  name={FIELDNAMES.CONNECTION_SPEED}
                                  amountExtra={option.price_amount}
                                  description={option.description}
                                  value={option.provisioning_key}
                                  cms={cms}
                                />
                              );
                            })
                          : null}
                      </InputErrorBlock>
                    </div>
                  </div>
                )}
                {willShowContract && (
                  <div className="section section--stacked">
                    <div className="line-length">
                      <InputErrorBlock name={FIELDNAMES.CONTRACT}>
                        <FieldInputError name={FIELDNAMES.CONTRACT} />
                        <h3
                          className="u-mb-xxs"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html:
                              cms && cms.contracts && cms.contracts.heading,
                          }}
                        />
                        <p
                          className="u-mb-m"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html:
                              cms && cms.contracts && cms.contracts.description,
                          }}
                        />
                        {contracts &&
                          contracts.map((option) =>
                            option.show_in_picker ? (
                              <div
                                className="width-full"
                                key={option.provisioning_key}
                              >
                                <ChoiceInput
                                  id={`${option.provisioning_key}`}
                                  type="radio"
                                  name={FIELDNAMES.CONTRACT}
                                  value={option.provisioning_key}
                                  label={option.name}
                                  sublabel={option.description}
                                />
                              </div>
                            ) : null,
                          )}
                      </InputErrorBlock>
                    </div>
                  </div>
                )}
                <div className="section section--stacked">
                  <div className="line-length">
                    <p>
                      <button
                        type="button"
                        className="btn btn--hollow-dark"
                        onClick={prevScreen}
                        data-key="BackAddressFinder"
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="btn u-float-right"
                        data-key="NextExtras"
                      >
                        Next
                      </button>
                    </p>
                    {submitFailed && invalid && (
                      <ErrorMessage
                        className="u-text-right"
                        error={SCREEN_VALIDATION_ERROR_MESSAGE}
                      />
                    )}
                  </div>
                </div>
                <div className="section section--stacked">
                  <div className="line-length">
                    <div
                      className="u-text-s u-mb-m"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html:
                          cms &&
                          cms.speed__disclaimer &&
                          cms.speed__disclaimer.description,
                      }}
                    />
                    <div
                      className="u-text-s"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html:
                          cms && cms.disclaimer && cms.disclaimer.description,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="width-1/3@large">
                {/* @ts-ignore */}
                <CartSummary />
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default PlanDetailsScreen;
