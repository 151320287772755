import classNames from 'classnames';
import { Field } from 'redux-form';
import { normalizeNumbers } from '../../../../utils/input/normalize';
import { FIELDNAMES } from '../../screens/Screen.constants';

import './bank-acc-field.scss';

type BankAccField = {
  error?: string | undefined;
};

const BankAccField = ({ error }) => (
  <div className="bank-acc-field">
    <div>
      <div className="label--helptext">Prefix</div>
      <Field
        type="text"
        component="input"
        name={FIELDNAMES.BANK_ACCOUNT_NUMBER.PREFIX}
        className={classNames(
          'input input--xs input--spacing',
          error && 'input--error',
        )}
        minLength={2}
        maxLength={2}
        size="2"
        placeholder="00"
        normalize={normalizeNumbers}
        aria-label="Prefix"
      />
    </div>
    <div>
      <div className="label--helptext">Branch</div>
      <Field
        type="text"
        component="input"
        name={FIELDNAMES.BANK_ACCOUNT_NUMBER.BRANCH}
        className={classNames(
          'input input--sm input--spacing',
          error && 'input--error',
        )}
        minLength={4}
        maxLength={4}
        size="4"
        placeholder="0000"
        normalize={normalizeNumbers}
        aria-label="Branch"
      />
    </div>
    <div>
      <div className="label--helptext">Account</div>
      <Field
        type="text"
        component="input"
        name={FIELDNAMES.BANK_ACCOUNT_NUMBER.ACCOUNT}
        className={classNames(
          'input input--md input--spacing',
          error && 'input--error',
        )}
        minLength={7}
        maxLength={7}
        placeholder="0000000"
        size="7"
        normalize={normalizeNumbers}
        aria-label="Account"
      />
    </div>
    <div>
      <div className="label--helptext">Suffix</div>
      <Field
        type="text"
        component="input"
        name={FIELDNAMES.BANK_ACCOUNT_NUMBER.SUFFIX}
        className={classNames(
          'input input--sm input--spacing',
          error && 'input--error',
        )}
        minLength={3}
        maxLength={3}
        placeholder="000"
        size="3"
        normalize={normalizeNumbers}
        aria-label="Suffix"
      />
    </div>
  </div>
);

export default BankAccField;
