import { connect } from 'react-redux';
import { bindActionCreators, type Dispatch } from 'redux';
import ProgressTracker from './ProgressTracker';
import { goToScreen } from '../../screens/Screen.actions';
import {
  currentScreenSelector,
  skippedScreensSelector,
} from '../../screens/Screen.selectors';
import type { State } from '../../state/constants';

function mapStateToProps(state: State) {
  return {
    currentScreen: currentScreenSelector(state),
    skipped: skippedScreensSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return bindActionCreators(
    {
      goToScreen,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressTracker);
