import big from 'big.js';
import { createSelector } from 'reselect';
import { CONNECTIONS } from '../../state/constants';
import {
  productsSelector,
  optionsSelector,
  optionSelector,
  extrasSelector,
  assetsSelector,
} from '../../state/content/content.selectors';
import { offerDiscountProductsSelector } from '../../state/offer/offer.selectors';
import { isValidOfferSelector } from '../../state/offer/offer.validation.selectors';
import { unfilteredBroadbandConnectionSpeedSelector } from '../PlanDetailsScreen/PlanDetailsScreen.selectors';
import { FIELDNAMES, fieldnameToProduct } from '../Screen.constants';
import {
  chosenPackageDiscountProductsSelector,
  chosenPackageSpeedSelector,
  selectDiscountProductOptions,
  selectRouterSelector,
} from '../Screen.selectors';

const { SELECT_ROUTER } = FIELDNAMES;

export const extraInfoSelector = createSelector(
  extrasSelector,
  (extra) => extra,
);

export const isChosenSpeedFibreSelector = createSelector(
  chosenPackageSpeedSelector,
  unfilteredBroadbandConnectionSpeedSelector,
  (chosenSpeed, speeds) => {
    const speed = optionSelector(speeds, chosenSpeed);
    return speed && speed.availability_required === CONNECTIONS.fibre;
  },
);

// **** OPTIONS *****

export const routerOptionsSelector = createSelector(
  isChosenSpeedFibreSelector,
  productsSelector,
  offerDiscountProductsSelector,
  chosenPackageDiscountProductsSelector,
  isValidOfferSelector,
  (
    isFibre,
    products,
    offerDiscountProducts,
    chosenPackageDiscountProducts,
    isOfferValid,
  ) => {
    const options = optionsSelector(
      products,
      fieldnameToProduct[SELECT_ROUTER],
    );
    let filteredOptions = options;
    if (isFibre) {
      // Don't display NF18ACV router if signing up to fibre
      filteredOptions = options.filter(
        (opt) => !opt.provisioning_key.includes('NF18ACV'),
      );
    } else {
      // Don't display mesh router options if not signing up to fibre
      filteredOptions = options.filter(
        (opt) => !opt.provisioning_key.includes('-M4-'),
      );
    }
    return selectDiscountProductOptions(
      filteredOptions,
      offerDiscountProducts,
      chosenPackageDiscountProducts,
      isOfferValid,
    );
  },
);

export const routerOptionsDefaultSelector = createSelector(
  routerOptionsSelector,
  (options) => {
    const found = options.find(
      (option) =>
        // @ts-ignore
        option.is_default === true,
    );
    return found && found.provisioning_key;
  },
);

export const routerPriceSelector = createSelector(
  routerOptionsSelector,
  selectRouterSelector,
  (options, userSelection: string) => {
    const selectedOption = optionSelector(
      // @ts-ignore
      options,
      userSelection,
    );
    return (selectedOption && selectedOption.price_amount) || '0.00';
  },
);

export const routerPriceMonthsSelector = createSelector(
  routerPriceSelector,
  (routerPrice) => (months: number) =>
    big(routerPrice)
      .div(months || 1) // 12 || 24 monthly payments
      .toString(),
);

export const willShowRouterPaymentSelector = createSelector(
  routerPriceSelector,
  (routerPrice) => routerPrice !== '0.00',
);

export const willShow24MonthsPaymentSelector: (State) => boolean =
  createSelector(selectRouterSelector, (router): boolean => {
    // Only multipacks (BNDL) of the TP-Link M4s offer 24mo
    return router && router.includes("TP-LNK-BNDL")
  });

export const noRouterImageSelector = createSelector(
  assetsSelector,
  (assets): string => assets?.['plan_picker_no_router'],
);
