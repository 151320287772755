import {
  FETCH_AVAILABILITY,
  FETCH_AVAILABILITY_SUCCESS,
  FETCH_AVAILABILITY_FAILED,
  type FetchAvailabilitySuccessAction,
  type FetchAvailabilityFailedAction,
} from './availability.actions';
import type { AvailabilityState as State } from './availability.constants';

const DEFAULT: State = {
  fetching: false,
};

const handlers = {
  [FETCH_AVAILABILITY](state: State): State {
    return {
      ...state,
      fetching: true,
    };
  },
  [FETCH_AVAILABILITY_SUCCESS](
    state: State,
    { payload }: FetchAvailabilitySuccessAction,
  ): State {
    return {
      ...state,
      ...payload,
      fetching: false,
    };
  },
  [FETCH_AVAILABILITY_FAILED](
    state,
    { error, errorType }: FetchAvailabilityFailedAction,
  ): State {
    return {
      ...state,
      error,
      errorType,
      fetching: false,
    };
  },
};

export default function (
  state: State = DEFAULT,
  action: { type: string },
): State {
  return handlers[action.type] ? handlers[action.type](state, action) : state;
}
