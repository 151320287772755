import { Fragment } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';
import ChoiceInput from '../../components/ChoiceInput/ChoiceInput';
import {
  FIELDNAMES,
  OPTIONS,
  type Provider,
  type ServiceTypes,
  SCREEN_VALIDATION_ERROR_MESSAGE,
} from '../Screen.constants';
import CartSummary from '../../components/CartSummary/CartSummary.container';
import ProgressTracker from '../../components/ProgressTracker/ProgressTracker.container';
import Icon from '../../../../components/Icon/Icon';
import { type CMSProviderTransfer } from '../../state/content/content.constants';
import { normalizeNumbers } from '../../../../utils/input/normalize';
import InputErrorBlock from '../../components/InputError/InputErrorBlock.container';
import InputErrorMessage from '../../components/InputError/InputErrorMessage.container';
import ErrorMessage from '../../components/InputError/InputErrorMessage';
import InputWrapper from '../../components/InputWrapper/InputWrapper.container';

type ProviderTransferScreenProps = {
  prevScreen: () => void;
  phonelineSelected: boolean;
  chosenProvider: Provider;
  chosenServiceType: ServiceTypes;
  cms: CMSProviderTransfer;
  handleSubmit: () => void;
  submitFailed: boolean;
  invalid: boolean;
  submitting: boolean;
};

export default function ProviderTransferScreen({
  prevScreen,
  phonelineSelected,
  chosenProvider,
  chosenServiceType,
  cms,
  handleSubmit,
  submitFailed,
  invalid,
  submitting,
}: ProviderTransferScreenProps) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="banner">
        <div className="banner__content line-length-large">
          <h1
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cms && cms.screen && cms.screen.heading,
            }}
          />
        </div>
      </div>
      {/* @ts-ignore */}
      <ProgressTracker />
      <div className="section section--no-bottom section--no-top@small">
        <div className="grid-flex grid--row-spacing-s grid--col-spacing">
          <div className="width-2/3@large">
            <div className="line-length">
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cms && cms.screen && cms.screen.description,
                }}
              />
            </div>
            <div className="section section--no-top section--stacked">
              <div className="icon-block icon-block--lg">
                <div className="icon-block__image">
                  <Icon
                    type="icon-existing-provider"
                    className="icon-block__svg"
                  />
                </div>
                <div className="icon-block__content">
                  <InputErrorBlock name={FIELDNAMES.TRANSFERRING_SERVICE_TYPES}>
                    <InputErrorMessage
                      name={FIELDNAMES.TRANSFERRING_SERVICE_TYPES}
                    />
                    <h3>What services do you currently have?</h3>
                    <p className="u-mb-m">
                      We need these details to ensure your move across to NOW
                      goes smoothly.
                    </p>
                    {phonelineSelected && (
                      <div className="width-full u-mb-l">
                        <ChoiceInput
                          type="radio"
                          name={FIELDNAMES.TRANSFERRING_SERVICE_TYPES}
                          value={
                            OPTIONS.TRANSFERRING_SERVICE_TYPES.BROADBAND_ONLY
                          }
                          label="Broadband only"
                        />
                        <ChoiceInput
                          type="radio"
                          name={FIELDNAMES.TRANSFERRING_SERVICE_TYPES}
                          value={
                            OPTIONS.TRANSFERRING_SERVICE_TYPES
                              .BROADBAND_AND_PHONE
                          }
                          label="Broadband and home phone line"
                        />
                      </div>
                    )}
                  </InputErrorBlock>
                  <div className="grid-flex grid--row-spacing-s grid--col-spacing-s">
                    <div className="width-2/3@large">
                      <InputWrapper
                        name={FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS}
                        label="Choose your current provider"
                        content={({ error }) => (
                          <div className="select">
                            <Field
                              name={FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS}
                              component="select"
                              type="select"
                              className={classNames(
                                'input',
                                error && 'input--error',
                              )}
                            >
                              <option value="">Please choose</option>
                              {Object.keys(
                                OPTIONS.TRANSFERRING_PROVIDER_DETAILS,
                              ).map((key) => (
                                <option
                                  key={key}
                                  value={
                                    OPTIONS.TRANSFERRING_PROVIDER_DETAILS[key]
                                      .value
                                  }
                                >
                                  {
                                    OPTIONS.TRANSFERRING_PROVIDER_DETAILS[key]
                                      .label
                                  }
                                </option>
                              ))}
                            </Field>
                          </div>
                        )}
                      />
                    </div>
                    {chosenProvider ===
                      OPTIONS.TRANSFERRING_PROVIDER_DETAILS.OTHER.value && (
                      <div className="width-2/3@large">
                        <InputWrapper
                          name={FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS_OTHER}
                          label="Other provider"
                          content={({ error }) => (
                            <Field
                              name={
                                FIELDNAMES.TRANSFERRING_PROVIDER_DETAILS_OTHER
                              }
                              component="input"
                              type="text"
                              className={classNames(
                                'input',
                                error && 'input--error',
                              )}
                            />
                          )}
                        />
                      </div>
                    )}
                    {phonelineSelected &&
                      chosenServiceType ===
                        OPTIONS.TRANSFERRING_SERVICE_TYPES
                          .BROADBAND_AND_PHONE && (
                        <Fragment>
                          <div className="width-2/3@large">
                            <label>
                              <span className="label">
                                Existing account number
                              </span>
                              <p className="u-mb-m">
                                Don’t worry if you don’t have this information
                                right now, we will be in touch to get it from
                                you at a later date.
                              </p>
                              <Field
                                type="text"
                                component="input"
                                name={FIELDNAMES.ACCOUNT_NUMBER}
                                className="input"
                              />
                            </label>
                          </div>
                          <div className="width-2/3@large">
                            <InputWrapper
                              name={FIELDNAMES.EXISTING_NUMBER}
                              label="Existing phone
                                                    number"
                              content={({ error }) => (
                                <Field
                                  type="text"
                                  component="input"
                                  name={FIELDNAMES.EXISTING_NUMBER}
                                  className={classNames(
                                    'input u-mb',
                                    error && 'input--error',
                                  )}
                                  normalize={normalizeNumbers}
                                />
                              )}
                            />
                          </div>
                          <div className="width-full">
                            <ChoiceInput
                              type="checkbox"
                              name={FIELDNAMES.KEEP_EXISTING_NUMBER}
                              value={OPTIONS.KEEP_EXISTING_NUMBER.YES}
                              label="I would like to try to keep my phone number"
                            />
                          </div>
                        </Fragment>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="section section--stacked">
              <div className="line-length">
                <p>
                  <button
                    type="button"
                    className="btn btn--hollow-dark"
                    onClick={prevScreen}
                    data-key="BackAccountDetails"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn u-float-right"
                    disabled={submitting}
                    data-key="NextPayment"
                  >
                    {submitting ? (
                      <Icon
                        title="Validating..."
                        type="icon-loading"
                        className="i--medium i--vertical-middle"
                      />
                    ) : (
                      'Next'
                    )}
                  </button>
                  <p />
                </p>
                {submitFailed && invalid && (
                  <ErrorMessage
                    className="u-text-right"
                    error={SCREEN_VALIDATION_ERROR_MESSAGE}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="width-1/3@large">
            {/* @ts-ignore */}
            <CartSummary />
          </div>
        </div>
      </div>
    </form>
  );
}
