import { type Contract, type Product } from './content.constants';
import { type ErrorType } from '../../ErrorPage/ErrorPage.constants';

export const FETCH_CONTENT = 'Screen@FETCH_CONTENT';
export const FETCH_CONTENT_SUCCESS = 'Screen@FETCH_CONTENT_SUCCESS';
export const FETCH_CONTENT_FAILED = 'Screen@FETCH_CONTENT_FAILED';

export type FetchContentAction = {
  type: typeof FETCH_CONTENT;
};

export function fetchContent(): FetchContentAction {
  return { type: FETCH_CONTENT };
}

export type FetchContentPayload = {
  products: Array<Product>;
  contracts: Array<Contract>;
};

export type FetchContentSuccessAction = {
  type: typeof FETCH_CONTENT_SUCCESS;
  payload: FetchContentPayload;
};

export function fetchContentSuccess(
  payload: FetchContentPayload,
): FetchContentSuccessAction {
  return { type: FETCH_CONTENT_SUCCESS, payload };
}

export type FetchContentFailedAction = {
  type: typeof FETCH_CONTENT_FAILED;
  error: string;
  errorType: ErrorType;
};

export function fetchContentFailed(
  error: string,
  errorType: ErrorType,
): FetchContentFailedAction {
  return { type: FETCH_CONTENT_FAILED, error, errorType };
}
