export type IconType =
  | 'icon-search'
  | 'icon-loading'
  | 'icon-checked'
  | 'icon-arrowdown'
  | 'icon-upload'
  | 'icon-download'
  | 'icon-choose-assist'
  | 'icon-choose-router'
  | 'icon-choose-phone'
  | 'icon-choose-extras'
  | 'icon-choose-device'
  | 'icon-personal-details'
  | 'icon-add-authority'
  | 'icon-delivery'
  | 'icon-pay'
  | 'icon-receive-bill'
  | 'icon-start-date'
  | 'icon-switch-provider'
  | 'icon-existing-provider'
  | 'icon-cancel';

export default function Icon({
  type,
  title,
  className = '',
}: {
  type: IconType;
  title?: string;
  className: string;
}) {
  return (
    <svg className={`i ${className}`}>
      {title && <title>{title}</title>}
      <use xlinkHref={`#${type}`} />
    </svg>
  );
}
