import { all, fork } from 'redux-saga/effects';
import screenSagas from '../screens/Screen.sagas';
import contentSagas from './content/content.sagas';
import availabilitySagas from './availability/availability.sagas';
import offerSagas from './offer/offer.sagas';
import packageSagas from './package/package.sagas';
import appSagas from '../App/App.sagas';

export default function* sagas() {
    yield all([
        fork(appSagas),
        fork(screenSagas),
        fork(contentSagas),
        fork(availabilitySagas),
        fork(offerSagas),
        fork(packageSagas),
    ]);
}
