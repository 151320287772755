import { Fragment, useEffect } from 'react';
import queryString from 'query-string';
import './trackingPixels.scss';

type TrackingPixelProps = {
  orderId: string;
  contract: string;
  price: any;
};

const GlimpTrackerPixel = () => (
  <iframe
    width="0px"
    height="0px"
    frameBorder="0"
    aria-hidden="true"
    title="Hidden"
    src="https://glimp.co.nz/broadband-pixel?provider=Now"
  />
);

export default function TrackingPixels({
  orderId,
  contract,
  price,
}: TrackingPixelProps) {
  useEffect(() => {
    let queryParams;
    try {
      queryParams = queryString.parse(window.location.search);
    } catch (e) {
      console.error('Unable to parse query string', e, queryParams);
      return;
    }

    // The following code is commented out logic that would exit early if the URL
    // doesn't contain a param.
    //
    // const BROADBAND_COMPARE_URL = 'BroadbandCompare.co.nz';
    // const isBroadBandCompare = queryParams.affiliate_name === BROADBAND_COMPARE_URL;
    // if (!isBroadBandCompare) {
    //     console.log("Not adding Roeye tracking because queryParams.affiliate_name !== " + BROADBAND_COMPARE_URL);
    //     console.log("queryParams=", queryParams)
    //     return;
    // };

    // For the purposes of debugging they can provide additional
    // URL params to set fields sent to Roeye
    const afid = queryParams.overrideAfid || orderId;
    const afprice = queryParams.overrideAfprice || price.toString();

    const data = {
      afid: afid, // (required) unique order or sale ID
      afgoal: `lead_${
        contract === '12' ? '12' : contract === '24' ? '24' : '0'
      }`, // (required) goal name. Can be 'lead', 'sale', 'signup' or other
      afsecure: '86ad593ef8b17321f43820c129e65105', // (required) security hash provided by S.L.I.C.E. Digital manager
      afprice: afprice, // (optional) total order sum. Sale programs - required, lead programs - optional
      afcomment: '', // (optional) any comments for this order
    };

    console.log(
      'Using roeye tracker (check <head> last child for <script>). The data that Roeye will use is',
      data,
    );
    console.log({ contract, contractType: typeof contract });

    Object.assign(window, data);

    const script = document.createElement('script');

    script.src = 'https://track.roeye.co.nz/track.js';
    script.async = true;

    if (document.head) {
      document.head.appendChild(script);
    }
  }, []);

  return (
    <Fragment>
      <GlimpTrackerPixel />
    </Fragment>
  );
}
