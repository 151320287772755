import { FIELDNAMES } from '../Screen.constants';
import { required } from '../../../../utils/input/validation';

export default (formData) => ({
  [FIELDNAMES.PAYMENT_METHOD]:
    required(formData[FIELDNAMES.PAYMENT_METHOD]) &&
    'Please choose a payment method below:',
  [FIELDNAMES.BANK_NAME]:
    required(formData[FIELDNAMES.BANK_NAME]) && 'Please choose a bank below',
  [FIELDNAMES.BANK_NAME_OTHER]:
    required(formData[FIELDNAMES.BANK_NAME_OTHER]) &&
    'Please enter the name of your bank below',
  [FIELDNAMES.BANK_ACCOUNT_HOLDER]:
    required(formData[FIELDNAMES.BANK_ACCOUNT_HOLDER]) &&
    'Please enter your name below',
  [FIELDNAMES.BANK_ACCOUNT_NUMBER.PREFIX]:
    required(formData[FIELDNAMES.BANK_ACCOUNT_NUMBER.PREFIX]) &&
    'Please enter your account number',
  [FIELDNAMES.BANK_ACCOUNT_NUMBER.BRANCH]:
    required(formData[FIELDNAMES.BANK_ACCOUNT_NUMBER.BRANCH]) &&
    'Please enter your account number',
  [FIELDNAMES.BANK_ACCOUNT_NUMBER.ACCOUNT]:
    required(formData[FIELDNAMES.BANK_ACCOUNT_NUMBER.ACCOUNT]) &&
    'Please enter your account number',
  [FIELDNAMES.BANK_ACCOUNT_NUMBER.SUFFIX]:
    required(formData[FIELDNAMES.BANK_ACCOUNT_NUMBER.SUFFIX]) &&
    'Please enter your account number',
  [FIELDNAMES.ACCOUNT_OPERATING_AUTHORITY]:
    required(formData[FIELDNAMES.ACCOUNT_OPERATING_AUTHORITY]) &&
    'Please accept the authorisation confirmation',
  [FIELDNAMES.BILL_DELIVERY]:
    required(formData[FIELDNAMES.BILL_DELIVERY]) &&
    'Please choose how you would like to receive you bill below',
  [FIELDNAMES.BILL_DELIVERY_ADDRESS.NAME]:
    required(formData[FIELDNAMES.BILL_DELIVERY_ADDRESS.NAME]) &&
    'Please enter your delivery address',
  [FIELDNAMES.BILL_DELIVERY_ADDRESS.ADDRESS_LINE_1]:
    required(formData[FIELDNAMES.BILL_DELIVERY_ADDRESS.ADDRESS_LINE_1]) &&
    'Please enter your delivery address',
  [FIELDNAMES.BILL_DELIVERY_ADDRESS.SUBURB]:
    required(formData[FIELDNAMES.BILL_DELIVERY_ADDRESS.SUBURB]) &&
    'Please enter your delivery suburb',
  [FIELDNAMES.BILL_DELIVERY_ADDRESS.CITY]:
    required(formData[FIELDNAMES.BILL_DELIVERY_ADDRESS.CITY]) &&
    'Please enter your delivery city',
  [FIELDNAMES.BILL_DELIVERY_ADDRESS.POSTCODE]:
    required(formData[FIELDNAMES.BILL_DELIVERY_ADDRESS.POSTCODE]) &&
    'Please enter your delivery postcode',
});
