//

import { connect } from 'react-redux';
import SuccessScreen from './SuccessScreen';
import { type State } from '../../state/constants';
import { successCMSSelector } from './SuccessScreen.selectors';

function mapStateToProps(state: State) {
  return {
    cms: successCMSSelector(state),
  };
}

export default connect(mapStateToProps)(SuccessScreen);
