//

import {
  FETCH_OFFER,
  SET_OFFER,
  FETCH_OFFER_SUCCESS,
  FETCH_OFFER_FAILED,
  type SetOfferAction,
  type FetchOfferFailedAction,
} from './offer.actions';
import type { OfferState as State } from './offer.constants';

const DEFAULT: State = {
  offer: null,
  fetching: false,
};

const handlers = {
  [FETCH_OFFER](state: State): State {
    return {
      ...state,
      fetching: true,
      error: undefined,
    };
  },
  [SET_OFFER](state: State, { payload }: SetOfferAction): State {
    return {
      ...state,
      offer: payload,
      error: undefined,
    };
  },
  [FETCH_OFFER_SUCCESS](state: State): State {
    return {
      ...state,
      fetching: false,
      error: undefined,
    };
  },
  [FETCH_OFFER_FAILED](state, { error }: FetchOfferFailedAction): State {
    return {
      ...state,
      error,
      fetching: false,
    };
  },
};

export default function (
  state: State = DEFAULT,
  action: { type: string },
): State {
  return handlers[action.type] ? handlers[action.type](state, action) : state;
}
