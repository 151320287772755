import { type Screen } from './Screen.constants';

export const GO_TO_SCREEN = 'Screen@GO_TO_SCREEN';
export const PREV_SCREEN = 'Screen@PREV_SCREEN';
export const NEXT_SCREEN = 'Screen@NEXT_SCREEN';
export const CAPTURE_FORM_DETAILS = 'Screen@CAPTURE_FORM_DETAILS';
export const ORDER_ID = 'Screen@ORDER_ID';
export const SUBMIT_SCREEN = 'Screen@SUBMIT_SCREEN';
export const SUBMIT_SCREEN_SUCCESS = 'Screen@SUBMIT_SCREEN_SUCCESS';
export const SUBMIT_SCREEN_FAILED = 'Screen@SUBMIT_SCREEN_FAILED';

export type GoToScreenAction = {
  type: typeof GO_TO_SCREEN;
  screen: Screen;
};

export function goToScreen(screen: Screen): GoToScreenAction {
  return { type: GO_TO_SCREEN, screen };
}

export type PrevScreenAction = {
  type: typeof PREV_SCREEN;
};

export function prevScreen(): PrevScreenAction {
  return { type: PREV_SCREEN };
}

export type NextScreenAction = {
  type: typeof NEXT_SCREEN;
};

export function nextScreen(): NextScreenAction {
  return { type: NEXT_SCREEN };
}

export type CaptureFormDetailsAction = {
  type: typeof CAPTURE_FORM_DETAILS;
};

export function captureFormDetails(): CaptureFormDetailsAction {
  return { type: CAPTURE_FORM_DETAILS };
}

export type StoreOrderId = {
  type: typeof ORDER_ID;
  orderId: string;
};

export function storeOrderId(orderId: string): StoreOrderId {
  return { type: ORDER_ID, orderId };
}

export type SubmitScreenAction = {
  type: typeof SUBMIT_SCREEN;
  form?: any;
};

export type FormHandlerPayload = {
  resolve: Function;
  reject: Function;
};

export function submitScreen(form: FormHandlerPayload): SubmitScreenAction {
  return {
    type: SUBMIT_SCREEN,
    form,
  };
}

export type SubmitScreenSuccessAction = {
  type: typeof SUBMIT_SCREEN_SUCCESS;
  orderId: string;
};

export function submitScreenSuccess(
  orderId: string,
): SubmitScreenSuccessAction {
  return {
    type: SUBMIT_SCREEN_SUCCESS,
    orderId,
  };
}

export type SubmitScreenFailedAction = {
  type: typeof SUBMIT_SCREEN_FAILED;
  error: string;
};

export function submitScreenFailed(error: string): SubmitScreenFailedAction {
  return {
    type: SUBMIT_SCREEN_FAILED,
    error,
  };
}
