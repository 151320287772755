import type { Offer } from './offer.constants';

export const FETCH_OFFER = 'Offer@FETCH_OFFER';
export const SET_OFFER = 'Offer@SET_OFFER';
export const FETCH_OFFER_SUCCESS = 'Offer@FETCH_OFFER_SUCCESS';
export const FETCH_OFFER_FAILED = 'Offer@FETCH_OFFER_FAILED';

export type FetchOfferAction = {
  type: typeof FETCH_OFFER;
  promoCode: string;
};

export function fetchOffer(promoCode: string): FetchOfferAction {
  return { type: FETCH_OFFER, promoCode };
}

export type SetOfferAction = {
  type: typeof SET_OFFER;
  payload: Offer;
};

export function setOffer(payload: Offer): SetOfferAction {
  return {
    type: SET_OFFER,
    payload,
  };
}
export type FetchOfferSuccessAction = {
  type: typeof FETCH_OFFER_SUCCESS;
};

export function fetchOfferSuccess(): FetchOfferSuccessAction {
  return {
    type: FETCH_OFFER_SUCCESS,
  };
}

export type FetchOfferFailedAction = {
  type: typeof FETCH_OFFER_FAILED;
  error: string;
};

export function fetchOfferFailed(error: string): FetchOfferFailedAction {
  return { type: FETCH_OFFER_FAILED, error };
}
