import { createSelector } from 'reselect';
import Big from 'big.js';
import {
  productsSelector,
  optionsSelector,
  optionSelector,
  contractsSelector,
  packagesSelector,
  cmsSelector,
} from '../../state/content/content.selectors';
import { availableTypesSelector } from '../../state/availability/availability.selectors';
import { CONNECTIONS } from '../../state/constants';
import {
  chosenBroadbandDataCapSelector,
  chosenBroadbandConnectionSpeedSelector,
} from '../Screen.selectors';
import {
  FIELDNAMES,
  fieldnameToProduct,
  NO_CONTRACT_KEY,
} from '../Screen.constants';
import { isPackageAvailable } from '../../state/package/package.selectors';
import { offerContractsSelector } from '../../state/offer/offer.selectors';

export const broadbandDataCapSelector = createSelector(
  productsSelector,
  (products) =>
    optionsSelector(products, fieldnameToProduct[FIELDNAMES.DATA_CAP]),
);

export const unfilteredBroadbandConnectionSpeedSelector = createSelector(
  productsSelector,
  (products) =>
    optionsSelector(products, fieldnameToProduct[FIELDNAMES.CONNECTION_SPEED]),
);

export const broadbandConnectionSpeedSelector = createSelector(
  unfilteredBroadbandConnectionSpeedSelector,
  availableTypesSelector,
  chosenBroadbandDataCapSelector,
  broadbandDataCapSelector,
  (options, availableTypes, chosenDataCap, dataCaps) => {
    let filteredTypes = options.filter((type) => {
      if (!availableTypes) return false;

      // We're filtering the results based on the availability of the
      // following: Fibre, VDSL, ADSL
      // 1. If Fibre is available:
      //   - Check if VDSL is available too, then include VDSL in the list.
      //   - Otherwise, if ADSL is available, it will be included in the list.
      // 2. If Fibre is NOT available:
      //   - Check if VDSL and ADSL are available and include them if they do.
      if (availableTypes.includes(CONNECTIONS.fibre)) {
        return availableTypes.includes(CONNECTIONS.vdsl)
          ? type.availability_required !== CONNECTIONS.adsl &&
              availableTypes.includes(type.availability_required)
          : availableTypes.includes(type.availability_required);
      }

      return availableTypes.includes(type.availability_required);
    });

    const dataCap = optionSelector(dataCaps, chosenDataCap);
    // Default to Fibre 100 if Data Cap is chosen instead of Packages
    if (dataCap) {
      filteredTypes = filteredTypes.filter(
        (speed) =>
          speed.availability_required !== CONNECTIONS.fibre ||
          speed.provisioning_key === '100/20' ||
          speed.provisioning_key === '300/100',
      );
    }

    return filteredTypes;
  },
);

export const contractsDefaultSelector = createSelector(
  contractsSelector,
  (contracts) => {
    const found = contracts.find(
      (contract) =>
        // @ts-ignore
        contract.is_default === true,
    );
    return found && found.provisioning_key;
  },
);

export const planDetailsCMSSelector = createSelector(
  cmsSelector,
  (cms) => cms && cms.plan_details,
);

export const availablePackagesSelector = createSelector(
  packagesSelector,
  availableTypesSelector,
  (packages, availableTypes) =>
    packages.filter(
      (pkg) => pkg && isPackageAvailable(pkg, availableTypes), // Making sure that all availability_required are satisfied.
    ),
);

export const displayablePackagesSelector = createSelector(
  availablePackagesSelector,
  (availablePackages) => availablePackages.filter((pkg) => pkg.show_in_picker),
);

export const planListSelector = createSelector(
  broadbandDataCapSelector,
  displayablePackagesSelector,
  (dataCaps, packages) => {
    const options = [
      ...dataCaps.map((cap) => ({ ...cap, isPackage: false })),
      ...packages.map((pack) => ({ ...pack, isPackage: true })),
    ];
    return options.sort((a, b) => Big(a.price_amount).minus(b.price_amount));
  },
);

// *** Stages display ******

export const willShowConnectionSpeedsSelector = createSelector(
  chosenBroadbandDataCapSelector,
  broadbandDataCapSelector,
  (chosenDataCap, dataCaps) =>
    dataCaps
      .map((data) => data && data.provisioning_key)
      .includes(chosenDataCap),
);

export const willShowContractSelector = createSelector(
  willShowConnectionSpeedsSelector,
  broadbandConnectionSpeedSelector,
  chosenBroadbandConnectionSpeedSelector,
  offerContractsSelector,
  (
    willShowConnectionSpeed,
    connectionSpeeds,
    chosenConnectionSpeed,
    offerContracts,
  ) => {
    if (
      Array.isArray(offerContracts) &&
      // If Offer doesn't allow no contract, hide the selector
      !offerContracts.some(
        (contract) => contract.provisioning_key === NO_CONTRACT_KEY,
      )
    ) {
      return false;
    }

    if (!willShowConnectionSpeed) return false;

    const speed = optionSelector(connectionSpeeds, chosenConnectionSpeed);
    return [CONNECTIONS.adsl, CONNECTIONS.vdsl].includes(
      speed && speed.availability_required.toLowerCase(),
    );
  },
);
