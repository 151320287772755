import classnames from 'classnames';
import './input-error.scss';

type Props = {
  className?: string;
  error: string;
};

const InputErrorMessage = ({ className, error }: Props) => (
  <p className={classnames('input-error__message', className)} role="alert">
    {error}
  </p>
);

export default InputErrorMessage;
