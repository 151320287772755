import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialiseData } from './App.actions';
import { FIELDNAMES, DEFAULTS, SCREENS } from '../screens/Screen.constants';
import {
  currentScreenSelector,
  isFormDirtySelector,
} from '../screens/Screen.selectors';
import { availabilityErrorTypeSelector } from '../state/availability/availability.selectors';
import { contentErrorTypeSelector } from '../state/content/content.selectors';
import {
  phoneBundleDefaultSelector,
  landlineCallingOptionsDefaultSelector,
  mobileCallingOptionsDefaultSelector,
  techAssistOptionsDefaultSelector,
} from '../screens/ExtrasScreen/ExtrasScreen.selectors';
import { contractsDefaultSelector } from '../screens/PlanDetailsScreen/PlanDetailsScreen.selectors';
import {
  packageSelector,
  packageInfoSelector,
} from '../state/package/package.selectors';
import { offerDefaultsSelector } from '../state/offer/offer.selectors';
import App from './App';
import { routerOptionsDefaultSelector } from '../screens/ChooseRouterScreen/ChooseRouterScreen.selectors';

const SKIP_TO_SUCCESS_SCREEN = 'theSecretOfMySuccessScreen';

function mapStateToProps(state) {
  const apiDefaults = {
    [FIELDNAMES.CONTRACT]: contractsDefaultSelector(state),
    [FIELDNAMES.SELECT_ROUTER]: routerOptionsDefaultSelector(state),
    [FIELDNAMES.PHONE_BUNDLE]: phoneBundleDefaultSelector(state),
    [FIELDNAMES.LANDLINE_CALLING_OPTION]:
      landlineCallingOptionsDefaultSelector(state),
    [FIELDNAMES.MOBILE_CALLING_OPTION]:
      mobileCallingOptionsDefaultSelector(state),
    [FIELDNAMES.TECH_ASSIST]: techAssistOptionsDefaultSelector(state),
  };

  const pkg = packageSelector(state);
  const { dataCap, speed, contract, includedPackage } =
    packageInfoSelector(state);

  /**
   * There are 3 different cases to determine what dataCap we preselect:
   * Say the packages with id AAA, BBB and CCC are shown in the picker.
   *
   * 1. The url ends with packageId=AAA.
   *   - Since the package is shown in the picker, we preselect it.
   * 2. The url ends with packageId=FFF and the package has included_package: 'BBB'
   *   - Since the package is not shown in the picker, we look at its included_package, so we preselect package BBB in the picker.
   * 3. The url ends with packageId=GGG and has no included_package
   *   - Since the package is not shown in the picker and has no included_package, we preselect the included_data_cap.
   */
  const packageDefaults = {
    [FIELDNAMES.DATA_CAP]:
      pkg && pkg.show_in_picker ? pkg && pkg.id : includedPackage || dataCap,
    [FIELDNAMES.CONNECTION_SPEED]: speed,
    [FIELDNAMES.CONTRACT]: contract,
  };

  const offerDefaults = offerDefaultsSelector(state);

  const initialValues = {
    ...DEFAULTS,
    ...apiDefaults,
    ...(App.getPackageId() && packageDefaults), // Only set package defaults when it is present in the query params
    ...(App.getPackageId() && offerDefaults),
  };

  let currentScreenOverride = undefined;

  if (document.location.search.indexOf(SKIP_TO_SUCCESS_SCREEN) !== -1) {
    currentScreenOverride = SCREENS.Success;
  }

  return {
    currentScreen: currentScreenOverride || currentScreenSelector(state),
    availabilityError: availabilityErrorTypeSelector(state),
    contentError: contentErrorTypeSelector(state),
    isFormDirty: isFormDirtySelector(state),
    initialValues,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      initialiseData,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
