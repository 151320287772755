import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, bindActionCreators, type Dispatch } from 'redux';
import PaymentOptionScreen from './PaymentOptionScreen';
import { prevScreen, nextScreen, goToScreen } from '../Screen.actions';
import { FORM_NAME } from '../Screen.constants';
import {
  emailAddressSelector,
  chosenPaymentMethodSelector,
  bankAccountNameSelector,
  providerTransferSelector,
  billDeliveryProductSelector,
} from '../Screen.selectors';
import { addressSelector } from '../../state/availability/availability.selectors';
import { type State } from '../../state/constants';
import {
  paymentOptionCMSSelector,
  willShowBillDeliveryDetailsSelector,
} from './PaymentOptionScreen.selectors';
import validate from './PaymentOptionScreen.validate';

function mapStateToProps(state: State) {
  return {
    emailAddress: emailAddressSelector(state),
    address: addressSelector(state),
    providerTransfer: providerTransferSelector(state),
    chosenPaymentMethod: chosenPaymentMethodSelector(state),
    chosenBank: bankAccountNameSelector(state),
    billDeliveryProduct: billDeliveryProductSelector(state),
    willShowBillDeliveryDetails: willShowBillDeliveryDetailsSelector(state),
    cms: paymentOptionCMSSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onSubmit: () => dispatch(nextScreen()),
    ...bindActionCreators(
      {
        prevScreen,
        goToScreen,
      },
      dispatch,
    ),
  };
}

export default compose(
  connect(
    mapStateToProps,
    // @ts-ignore
    mapDispatchToProps,
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate,
  }),
)(PaymentOptionScreen);
