//

import { connect } from 'react-redux';
import { bindActionCreators, type Dispatch } from 'redux';
import PromoCode from './PromoCode';
import {
  offerSelector,
  offerErrorSelector,
  isFetchingOfferSelector,
} from '../../state/offer/offer.selectors';
import { fetchOffer } from '../../state/offer/offer.actions';
import type { State } from '../../state/constants';

function mapStateToProps(state: State) {
  return {
    offer: offerSelector(state),
    offerError: offerErrorSelector(state),
    isFetching: isFetchingOfferSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return bindActionCreators(
    {
      fetchOffer,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoCode);
