//

import { put, takeLatest, select, call } from 'redux-saga/effects';
import {
  FETCH_PACKAGE,
  type FetchPackageAction,
  fetchPackageSuccess,
  fetchPackageFailed,
} from './package.actions';
import { get } from '../../../../utils/http/http';
import { setOffer } from '../offer/offer.actions';
import { availableTypesSelector } from '../availability/availability.selectors';
import { isPackageAvailable } from './package.selectors';
import { PACKAGE_NOT_AVAILABLE_ERROR } from './package.constants';

export async function getPackage(id: string) {
  const { data } = await get({
    path: `/acquisitions/api/v1/packages/${id}/`,
  });
  return data;
}

function* fetchPackage({ id }: FetchPackageAction) {
  try {
    const pkg = yield call(getPackage, id);
    const availability = yield select(availableTypesSelector);
    if (isPackageAvailable(pkg, availability)) {
      yield put(fetchPackageSuccess(pkg));
      if (pkg && pkg.included_offer) {
        yield put(setOffer(pkg.included_offer));
      }
    } else {
      // @ts-ignore
      throw new Error(PACKAGE_NOT_AVAILABLE_ERROR);
    }
  } catch (e) {
    yield put(fetchPackageFailed(e.message));
  }
}

export default function* saga() {
  yield takeLatest(FETCH_PACKAGE, fetchPackage);
}
