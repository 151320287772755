// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tracking-pixel {
  position: absolute;
  left: -200vw;
}`, "",{"version":3,"sources":["webpack://./core/static_src/js/apps/planPicker/components/TrackingPixels/trackingPixels.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF","sourcesContent":[".tracking-pixel {\n  position: absolute;\n  left: -200vw;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
