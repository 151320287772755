//

import {
  FETCH_CONTENT,
  FETCH_CONTENT_SUCCESS,
  FETCH_CONTENT_FAILED,
  type FetchContentSuccessAction,
  type FetchContentFailedAction,
} from './content.actions';
import type { ContentState } from './content.constants';

const DEFAULT = {
  products: [],
  contracts: [],
  packages: [],
  cms: {},
  now_office: false,
  fetching: false,
  assets: {},
};

const handlers = {
  [FETCH_CONTENT](state: ContentState) {
    return {
      ...state,
      fetching: true,
    };
  },
  [FETCH_CONTENT_SUCCESS](
    state: ContentState,
    { payload }: FetchContentSuccessAction,
  ) {
    return {
      ...state,
      ...payload,
      fetching: false,
    };
  },
  [FETCH_CONTENT_FAILED](
    state: ContentState,
    { error, errorType }: FetchContentFailedAction,
  ) {
    return {
      ...state,
      error,
      errorType,
      fetching: false,
    };
  },
};

export default function (
  state: ContentState = DEFAULT,
  action: { type: string },
) {
  return handlers[action.type] ? handlers[action.type](state, action) : state;
}
