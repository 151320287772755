import { ReactNode } from 'react';
import classnames from 'classnames';
import './input-error.scss';

type Props = {
  className?: string;
  error?: string;
  children: ReactNode;
  size?: 'sm';
};

const InputErrorBlock = ({ className, size, error, children }: Props) => (
  <div
    className={classnames(
      'input-error__block',
      error && 'input-error__block--error',
      size && `input-error__block--${size}`,
      className,
    )}
  >
    {children}
  </div>
);

export default InputErrorBlock;
