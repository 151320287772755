import axios from 'axios';

export type queryParamProps = Object;

export type getProps = {
  path: string;
  params?;
  headers?;
};

export type postProps = {
  path: string;
  payload?: any;
  params?;
  headers?;
};

export type putProps = {
  path: string;
  payload?: any;
  headers?;
};

export type patchProps = {
  path: string;
  payload?: any;
  headers?;
};

export type deleteProps = {
  path: string;
  params?;
  headers?;
};

const csrfTokenElement: HTMLInputElement =
  document.querySelector<HTMLInputElement>('[name=csrfmiddlewaretoken]');

const csrfToken = csrfTokenElement && csrfTokenElement.value;

axios.defaults.headers.common['X-CSRFToken'] = csrfToken;

export const client = axios.create({
  responseType: 'json',
});

export const get = ({ path, params = {}, headers = {} }: getProps) =>
  client.get(path, {
    params,
    headers,
  });

export const post = ({ path, payload, params = {}, headers = {} }: postProps) =>
  client.post(path, payload, {
    params,
    headers,
  });

export const put = ({ path, payload, headers = {} }: putProps) =>
  client.put(path, payload, {
    headers,
  });

export const patch = ({ path, payload, headers = {} }: patchProps) =>
  client.patch(path, payload, {
    headers,
  });

export const http = {
  get,
  post,
  put,
  patch,
  delete: ({ path, params = {}, headers = {} }: deleteProps) =>
    client.delete(path, {
      params,
      headers,
    }),
};
