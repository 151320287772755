import { errors, type ErrorType } from './ErrorPage.constants';

type Props = {
  type: ErrorType;
};

const ErrorPage = ({ type }: Props) => (
  // TODO: Get an actual design
  <div>
    <div className="banner">
      <div className="banner__content line-length-large">
        <h1>{errors[type].title}</h1>
      </div>
    </div>

    <div className="section section--no-top@small">
      <div className="grid-flex grid--row-spacing-s grid--col-spacing">
        <div className="width-2/3@large">
          <div className="line-length">
            <p>{errors[type].description}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ErrorPage;
