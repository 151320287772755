import { Fragment } from 'react';
import { formatMoney } from '../../../../utils/display/number';
import { type Offer, type OfferError } from '../../state/offer/offer.constants';

type Props = {
  offer: Offer;
  offerError?: OfferError;
};

export default function Promo({ offer, offerError }: Props) {
  if (!offer) return null;
  return (
    <Fragment>
      <div className="card-summary__offer">
        <div className="card-summary__content">
          <div className="card-summary__col--left">
            <p>{offer.description}</p>
          </div>
          {offer.discount_amount !== '0.00' && (
            <div className="card-summary__col--right">
              <p className="u-text-medium">
                -$
                {formatMoney(offer.discount_amount)}
              </p>
            </div>
          )}
        </div>
      </div>
      {offerError && (
        <div className="card-summary__offer-invalid">
          {offerError.expired ? (
            'This offer has expired'
          ) : (
            <span>
              {`This offer has been invalidated. It's only available
                            for`}
              {offerError.invalidFields &&
                Object.keys(offerError.invalidFields).map((field) => (
                  <Fragment key={field}>
                    {' '}
                    <span>
                      {offerError.invalidFields[field]
                        .map((option) => option.name)
                        .join(' or ')}
                    </span>
                    ;
                  </Fragment>
                ))}
            </span>
          )}
        </div>
      )}
    </Fragment>
  );
}
