//

import { connect } from 'react-redux';
// import { bindActionCreators, type Dispatch } from 'redux';
import Promo from './Promo';
import { offerSelector } from '../../state/offer/offer.selectors';
import { offerInvalidErrorSelector } from '../../state/offer/offer.validation.selectors';
import type { State } from '../../state/constants';

function mapStateToProps(state: State) {
  return {
    offer: offerSelector(state),
    offerError: offerInvalidErrorSelector(state),
  };
}

// function mapDispatchToProps(dispatch: Dispatch<any>) {
//     return bindActionCreators(
//         {
//             goToScreen,
//         },
//         dispatch,
//     );
// }

export default connect(mapStateToProps)(Promo);
