import { Field } from 'redux-form';

type Props = {
  className?: string;
  label?: string;
  helptext?: string;
  name: string;
  placeholder: string;
};

const TextArea = ({
  className = 'full-width',
  label,
  name,
  helptext,
  placeholder,
}: Props) => (
  <div className={className}>
    <label className={!label && 'u-accessible'} htmlFor={`id-${name}`}>
      <span className="label">{label || placeholder}</span>
      <span className="label--helptext">{helptext}</span>
    </label>
    <Field
      id={`id-${name}`}
      className="textarea"
      name={name}
      props={{
        placeholder,
      }}
      component="textarea"
    />
  </div>
);

export default TextArea;
