import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App/App.container';
import store from './state/store';

const SELECTOR = '[data-plan-picker-app]';

window.addEventListener('DOMContentLoaded', () => {
  const wrapper = document.querySelector<HTMLElement>(SELECTOR);

  if (wrapper) {
    const root = createRoot(wrapper!);
    root.render(
      <Provider store={store}>
        <App />
      </Provider>,
    );
  } else {
    console.info(
      'No plan picker on the page that matches selector: ',
      SELECTOR,
    );
  }
});
