//

import { FIELDNAMES } from '../Screen.constants';
import { required } from '../../../../utils/input/validation';

export default (formData) => ({
  [FIELDNAMES.SELECT_PHONELINE]:
    required(formData[FIELDNAMES.SELECT_PHONELINE]) &&
    'Please tell us whether you would like a phone line:',
  [FIELDNAMES.LANDLINE_CALLING_OPTION]:
    required(formData[FIELDNAMES.LANDLINE_CALLING_OPTION]) &&
    'Please tell us which calling pack you would like:',
  [FIELDNAMES.MOBILE_CALLING_OPTION]:
    required(formData[FIELDNAMES.MOBILE_CALLING_OPTION]) &&
    'Please tell us which mobile calling pack you would like:',
});
