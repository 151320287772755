import classNames from 'classnames';
import { formatMoney } from '../../../../utils/display/number';

type DollarGroupProps = {
  amount: string;
  size: 'large' | 'small';
  paymentFrequency?: string;
  className?: string;
};

export default function DollarGroup({
  amount,
  size,
  className = '',
  paymentFrequency = 'per month',
}: DollarGroupProps) {
  return (
    <span
      className={classNames(
        'dollars__group',
        size && `dollars--${size}`,
        className,
      )}
    >
      <span className="dollars__symbol">$</span>
      <span className="dollars__amount">{formatMoney(amount)}</span>
      <span className="dollars__period">{paymentFrequency}</span>
    </span>
  );
}
