import { ReactNode, Component } from 'react';
import classnames from 'classnames';
import './input-wrapper.scss';

type Props = {
  label?: string;
  helpText?: string;
  error?: string;
  content: (props: { error?: string }) => any;
  multi?: boolean;
  name?: string;
  names?: Array<string>;
};

type DivProps = {
  children: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

type LabelProps = {
  children: ReactNode;
} & React.HTMLAttributes<HTMLLabelElement>;

// createElement needs both tag name and props when called but props are added later when used in JSX
const Div = ({ children, ...props }: DivProps) => (
  <div {...props}>{children}</div>
);
const Label = ({ children, ...props }: LabelProps) => (
  <label {...props}>{children}</label>
);

// This component needs to be a class, otherwise content becomes a new instance
// every time the parent component re-renders, and loses input focus
// See https://reactjs.org/docs/render-props.html#caveats
// eslint-disable-next-line
class InputWrapper extends Component<Props> {
  static defaultProps = {
    label: '',
  };

  render() {
    const { label, helpText, error, content, multi, name, names } = this.props;
    const Wrapper = multi ? Div : Label;
    const id =
      (name && `label-${name}`) || (names && `label-${names.join('-')}`);
    return (
      <Wrapper
        className={classnames('input-wrapper', error && 'input-wrapper--error')}
        role={multi ? 'group' : undefined}
        aria-labelledby={multi && id ? id : undefined}
      >
        {label && (
          <span id={id} className="label">
            {label}
            {helpText && (
              <span id={id} className="label--helptext">
                {helpText}
              </span>
            )}
          </span>
        )}
        {error && (
          <span className="input-wrapper__message input-wrapper__message--error">
            {error}
          </span>
        )}
        {content({ error })}
      </Wrapper>
    );
  }
}
export default InputWrapper;
