//
import { createSelector } from 'reselect';
import { type OfferError } from './offer.constants';

import { type State } from '../constants';

import { FIELDNAMES } from '../../screens/Screen.constants';
import {
  formFieldValueSelector,
  chosenPackageDataCapSelector,
  chosenPackageSpeedSelector,
  chosenPackageContractSelector,
} from '../../screens/Screen.selectors';
import {
  offerSelector,
  offerDataCapsSelector,
  offerConnectionsSpeedsSelector,
  offerContractsSelector,
  offerRequiredProductsSelector,
  findFieldnameFromPathData,
} from './offer.selectors';

// eslint-disable-next-line
export const offerInvalidErrorSelector: (State) => OfferError | undefined =
  createSelector(
    offerSelector,
    chosenPackageDataCapSelector,
    chosenPackageSpeedSelector,
    chosenPackageContractSelector,
    offerDataCapsSelector,
    offerConnectionsSpeedsSelector,
    offerContractsSelector,
    offerRequiredProductsSelector,
    formFieldValueSelector,
    (
      offer,
      dataCap,
      speed,
      contract,
      offerDataCaps,
      offerConnectionsSpeeds,
      offerContracts,
      requiredProducts,
      getFormValue,
    ) => {
      if (!offer) return null;
      const currentDate = new Date();
      const endDate = offer.end_date ? new Date(offer.end_date) : null;
      if (endDate && endDate < currentDate) {
        return {
          expired: true,
        };
      }
      const invalidFields = {};
      if (
        offerDataCaps &&
        dataCap &&
        !offerDataCaps.some((cap) => cap.provisioning_key === dataCap)
      ) {
        invalidFields[FIELDNAMES.DATA_CAP] = offerDataCaps;
      } else if (
        offerConnectionsSpeeds &&
        speed &&
        !offerConnectionsSpeeds.some(
          (offerSpeed) => offerSpeed.provisioning_key === speed,
        )
      ) {
        invalidFields[FIELDNAMES.CONNECTION_SPEED] = offerConnectionsSpeeds;
      }
      if (
        offerContracts &&
        contract &&
        !offerContracts.some(
          (contractData) => contractData.provisioning_key === contract,
        )
      ) {
        invalidFields[FIELDNAMES.CONTRACT] = offerContracts;
      }
      if (requiredProducts && requiredProducts.length > 0) {
        requiredProducts.forEach(({ path: pathData, item, items }) => {
          const fieldname = findFieldnameFromPathData(pathData);
          if (!fieldname) return;
          const value = getFormValue(fieldname);
          if (
            !value || // product checkbox is not checked or no option is selected
            (pathData.type !== 'product' &&
              !items.find(
                (opt) => opt.provisioning_key === value, // value doesn't match any of the options
              ))
          ) {
            invalidFields[fieldname] = item ? [item] : items;
          }
        });
      }
      return Object.keys(invalidFields).length > 0 ? { invalidFields } : null;
    },
  );

export const isValidOfferSelector = createSelector(
  offerInvalidErrorSelector,
  (error) => !error,
);
