import { connect } from 'react-redux';
import { bindActionCreators, type Dispatch } from 'redux';
import CartSummary from './CartSummary';
import {
  addressSelector,
  planSelector,
  // packageSpeedSelectedSelector,
  speedSelector,
  routerSelector,
  routerMonthlyPaymentSelector,
  phoneLineSelector,
  phoneBundleSelector,
  chosenLandlineOptionSelector,
  chosenMobileOptionSelector,
  contractSelector,
  techAssistOptionSelector,
  monthlyPaymentSelector,
  oneOffPaymentSelector,
  willShowContractInSummarySelector,
  willShowExtrasSelector,
  willShowOneOffPaymentSelector,
  willShowMonthlyPromo,
  willShowOneOffPromo,
  willShowRouterSelector,
  willPayRouterMonthlySelector,
  routerDeliveryFeeSelector,
  willShowRouterDeliveryFeeSelector,
  oneOffFeeContractNameSelector,
  willShowTechAssistSelector,
} from './CartSummary.selectors';

import { goToScreen } from '../../screens/Screen.actions';
import {
  currentScreenSelector,
  chosenPackageSelector,
  chosenPackageExtrasSelector,
  chosenBillDeliverySelector,
  routerPaymentMonthsSelector,
} from '../../screens/Screen.selectors';
import {
  chosenSpeedSelector,
  chosenDataCapSelector,
} from '../../state/payload/payload.selectors';
import type { State } from '../../state/constants';

function mapStateToProps(state: State) {
  return {
    address: addressSelector(state),
    currentScreen: currentScreenSelector(state),
    contract: contractSelector(state),
    packagedPlan: chosenPackageSelector(state),
    packageDataCap: chosenDataCapSelector(state),
    packageSpeed: chosenSpeedSelector(state),
    packageExtras: chosenPackageExtrasSelector(state),
    plan: planSelector(state),
    speed: speedSelector(state),
    router: routerSelector(state),
    routerMonthlyPayment: routerMonthlyPaymentSelector(state),
    phoneline: phoneLineSelector(state),
    phoneBundle: phoneBundleSelector(state),
    landlineOption: chosenLandlineOptionSelector(state),
    mobileOption: chosenMobileOptionSelector(state),
    techAssist: techAssistOptionSelector(state),
    showTechAssist: willShowTechAssistSelector(state),
    monthlyPayment: monthlyPaymentSelector(state),
    billDelivery: chosenBillDeliverySelector(state),
    oneOffPayment: oneOffPaymentSelector(state),
    showExtras: willShowExtrasSelector(state),
    showOneOffPayment: willShowOneOffPaymentSelector(state),
    showContract: willShowContractInSummarySelector(state),
    showMonthlyPromo: willShowMonthlyPromo(state),
    showOneOffPromo: willShowOneOffPromo(state),
    showRouter: willShowRouterSelector(state),
    payRouterMonthly: willPayRouterMonthlySelector(state),
    routerPaymentMonths: routerPaymentMonthsSelector(state),
    routerDeliveryFee: routerDeliveryFeeSelector(state),
    showRouterDeliveryFee: willShowRouterDeliveryFeeSelector(state),
    oneOffFeeContractName: oneOffFeeContractNameSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return bindActionCreators(
    {
      goToScreen,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  // @ts-expect-error
  CartSummary,
);
