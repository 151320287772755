import Icon from '../../../../components/Icon/Icon';
import { type CMSPlanDetails } from '../../state/content/content.constants';

type SpeedInfoProps = {
  title: string;
  value: string;
  cms?: CMSPlanDetails;
};

export default function Speed({ title, value, cms }: SpeedInfoProps) {
  let [download, upload] = ['0', '0'];
  const speeds = [
    { title: 'Fibre 300', values: cms?.speeds__fibre_300.heading.split('/') },
    { title: 'VDSL', values: cms?.speeds__vdsl.heading.split('/') },
    { title: 'ADSL', values: cms?.speeds__adsl.heading.split('/') },
  ];

  if (cms && cms.speeds__adsl && cms.speeds__vdsl && cms.speeds__fibre_300) {
    speeds.map((speed) => {
      if (speed.title == title) {
        download = speed.values ? speed.values[0] : '';
        upload = speed.values ? speed.values[1] : '';
      }
    });
  } else {
    [download, upload] = value.split('/');

    if (value.toLowerCase() === 'adsl') {
      download = '20';
      upload = '1';
    } else if (value.toLowerCase() === 'vdsl') {
      download = '70';
      upload = '10';
    }
  }

  return (
    <p className="u-text-s btn-group">
      <span className="u-text-medium icon-text icon-text--small">
        <Icon type="icon-download" className="radio-picker__svg" />
        <span>{`${download}mbps download`}</span>
      </span>
      <span className="u-text-medium icon-text icon-text--small">
        <Icon type="icon-upload" className="radio-picker__svg" />
        <span>{`${upload}mbps upload*`}</span>
      </span>
    </p>
  );
}
