//

import big from 'big.js';

type Big = typeof big;

type BigNumber = Big | string | number;

// eslint-disable-next-line import/prefer-default-export
export const formatMoney = (
  amount: BigNumber,
  includeTrailingZeroes?: boolean,
): string => {
  const result: string = big(amount).toFixed(2);
  return includeTrailingZeroes ? result : result.replace(/\.00$/, '');
};
