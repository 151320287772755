import classNames from 'classnames';
import { Field } from 'redux-form';
import ChoiceInput from '../../components/ChoiceInput/ChoiceInput';

type RouterCardPaymentOption = {
  name: string;
  value: string;
  label: string;
};

type RouterCardProps = {
  id: string;
  title: string;
  description: string;
  value: string;
  name: string;
  amount?: string;
  amountExtra?: string;
  frequency?: string;
  type?: 'package' | 'normal' | 'speed';
  link?: string;
  linkText?: string;
  imgSrc?: string;
  imgAlt?: string;
  noRouter?: boolean;
  paymentOptions?: RouterCardPaymentOption[];
  ribbon?: string;
};

function RouterCard({
  id,
  title,
  description,
  value,
  name,
  link,
  linkText,
  imgSrc,
  imgAlt,
  paymentOptions,
  ribbon,
}: RouterCardProps) {
  return (
    <div className="radio-picker radio-picker--router" data-key={title}>
      <Field
        className="radio__input"
        component="input"
        type="radio"
        name={name}
        value={value}
        id={id}
        props={{
          'data-key': `Radio${title}`,
        }}
      />

      <label
        htmlFor={id}
        className={classNames({
          'radio-picker__label': true,
          'radio-picker__label--selected': true,
          'radio-picker__label--ribbon': !!ribbon,
        })}
      >
        <div className="radio-picker__content radio-picker__content--blue">
          {imgSrc && (
            <img src={imgSrc} alt={imgAlt} className="radio-picker__image" />
          )}
          <div className="radio-picker__description">
            <p className="radio-picker__title radio-picker__title--bold">
              {title}
            </p>
            <p className="u-text-s">{description}</p>
            {link && (
              <a
                href={link}
                target="_blank"
                className="link link--underline u-text-s"
              >
                {linkText}
              </a>
            )}
          </div>
          {ribbon && <div className="radio-picker__ribbon">{ribbon}</div>}
        </div>
      </label>
      {paymentOptions && (
        <div className="radio-picker__options">
          {paymentOptions.map((opt, i) => (
            <ChoiceInput key={i} type="radio" {...opt} />
          ))}
        </div>
      )}
    </div>
  );
}

RouterCard.defaultProps = {
  amount: '',
  frequency: '',
  type: 'normal',
};

export default RouterCard;
