import { createSelector } from 'reselect';
import queryString from 'query-string';
import { type PlanPickerPayload } from './payload.constants';
import { type State, type Address } from '../constants';
import { offerSelector } from '../offer/offer.selectors';
import { offerInvalidErrorSelector } from '../offer/offer.validation.selectors';
import {
  chosenPackageDataCapSelector,
  providerTransferDetailsSelector,
  chosenContractDataSelector,
  csrIdSelector,
  friendNameSelector,
  contractAgreementSelector,
  otherNotesSelector,
  chosenPaymentMethodSelector,
  bankAccountHolderSelector,
  bankAccountTextSelecltor,
  accountOperatingAuthoritySelector,
  chosenPackageSpeedSelector,
  selectRouterSelector,
  techAssistSelector,
  billDeliverySelector,
  billDeliveryAddressSelector,
  chosenPackageExtrasSelector,
  chosenBillDeliverySelector,
  creditCheckSelector,
  orderIdSelector,
  routerPaymentMonthsSelector,
} from '../../screens/Screen.selectors';
import { OPTIONS } from '../../screens/Screen.constants';
import {
  broadbandDataCapSelector,
  broadbandConnectionSpeedSelector,
} from '../../screens/PlanDetailsScreen/PlanDetailsScreen.selectors';
import {
  techAssistOptionsSelector,
  phonelineSelectedSelector,
  phoneLineSelector,
} from '../../screens/ExtrasScreen/ExtrasScreen.selectors';
import { personalAccountPayloadSelector } from '../../screens/PersonalAccountScreen/PersonalAccountScreen.selectors';
import {
  phoneBundleSelector,
  chosenLandlineOptionSelector,
  chosenMobileOptionSelector,
} from '../../components/CartSummary/CartSummary.selectors';
import { bankNameSelector } from '../../screens/ReviewScreen/ReviewScreen.selectors';
import { routerOptionsSelector } from '../../screens/ChooseRouterScreen/ChooseRouterScreen.selectors';

export const chosenDataCapSelector = createSelector(
  broadbandDataCapSelector,
  chosenPackageDataCapSelector,
  (dataCaps, userSelection) =>
    dataCaps.find(
      (dataCap) => dataCap && dataCap.provisioning_key === userSelection,
    ),
);

export const chosenSpeedSelector = createSelector(
  broadbandConnectionSpeedSelector,
  chosenPackageSpeedSelector,
  (speeds, userSelection) =>
    speeds.find((speed) => speed && speed.provisioning_key === userSelection),
);

export const chosenRouterSelector = createSelector(
  routerOptionsSelector,
  selectRouterSelector,
  (routers, userSelection) =>
    routers.find((router) => router.provisioning_key === userSelection),
);

export const chosenTechAssistSelector = createSelector(
  techAssistOptionsSelector,
  techAssistSelector,
  (techAssistOptions, userSelection) =>
    techAssistOptions.find(
      (option) => option.provisioning_key === userSelection,
    ),
);

export const chosenPhoneLineSelector = createSelector(
  phonelineSelectedSelector,
  phoneLineSelector,
  (phoneLineSelected, phoneLine) => phoneLineSelected && phoneLine,
);

type CartPayload = Array<string>;

// $FlowFixMe
export const cartPayloadSelector: (State) => CartPayload = createSelector(
  // Plan Details
  chosenDataCapSelector,
  chosenSpeedSelector,
  // Extras
  chosenRouterSelector,
  chosenPhoneLineSelector,
  phoneBundleSelector,
  chosenLandlineOptionSelector,
  chosenMobileOptionSelector,
  chosenTechAssistSelector,
  chosenBillDeliverySelector,
  chosenPackageExtrasSelector,
  (
    dataCap,
    speed,
    router,
    phoneLine,
    phoneBundle,
    landline,
    mobile,
    techAssist,
    billDelivery,
    packageExtras,
  ) => {
    const chosenExtrasParentIds = packageExtras
      ? packageExtras.map(
          (
            extra, // @ts-ignore
          ) => extra.parent,
        )
      : [];
    const chosenExtrasIds = packageExtras
      ? packageExtras.map((extra) => extra.id)
      : [];

    return [
      dataCap && dataCap.id,
      speed && speed.id,
      router && router.id,
      phoneLine && phoneLine.id,
      phoneBundle && phoneBundle.id,
      landline && landline.id,
      mobile && mobile.id,
      techAssist &&
        !chosenExtrasParentIds.includes(
          // @ts-ignore
          techAssist.parent,
        ) &&
        techAssist.id,
      billDelivery && billDelivery.id,
      ...chosenExtrasIds,
    ].filter((item) => !!item);
  },
);

type PaymentDetailsPayload = {
  paymentMethod: string;
  bankName: string;
  accountHolder: string;
  accountNumber: string;
  authConfirmation: boolean;
  billDelivery: {
    method: 'post' | 'email';
    deliveryAddress?: Address;
  };
};

export const paymentDetailsPayloadSelector: (
  state: State,
) => PaymentDetailsPayload = createSelector(
  chosenPaymentMethodSelector,
  bankNameSelector,
  bankAccountHolderSelector,
  bankAccountTextSelecltor,
  accountOperatingAuthoritySelector,
  billDeliverySelector,
  billDeliveryAddressSelector,
  (
    paymentMethod,
    bankName,
    accountHolder,
    accountNumber,
    authConfirmation,
    billDelivery,
    billDeliveryAddress,
  ) => ({
    paymentMethod,
    bankName,
    accountHolder,
    accountNumber,
    authConfirmation,
    billDelivery: {
      method: billDelivery === OPTIONS.BILL_DELIVERY.EMAIL ? 'email' : 'post',
      deliveryAddress:
        billDelivery !== OPTIONS.BILL_DELIVERY.EMAIL
          ? billDeliveryAddress
          : null,
    },
  }),
);

type ReferencePayload = {
  contract?: string;
  csr: string;
  offer?: string;
  friendName?: string;
  addressId?: string;
  creditCheck: boolean;
};

export const referencePayloadSelector: (State) => ReferencePayload =
  createSelector(
    chosenContractDataSelector,
    csrIdSelector,
    offerSelector,
    offerInvalidErrorSelector,
    friendNameSelector,
    creditCheckSelector,
    (contract, csrId, offer, offerInvalid, friendName, creditCheck) => {
      const { addressId } = queryString.parse(window.location.search);

      return {
        contract: contract && contract.id,
        csr: csrId,
        offer: offerInvalid === null ? offer && offer.id : null,
        friendName:
          offerInvalid === null ? offer && offer.id && friendName : null,
        addressId: typeof addressId === 'string' ? addressId : null,
        creditCheck,
      };
    },
  );

// @ts-ignore
export const planPickerPayloadSelector: (State) => PlanPickerPayload =
  createSelector(
    cartPayloadSelector,
    routerPaymentMonthsSelector,
    personalAccountPayloadSelector,
    providerTransferDetailsSelector,
    paymentDetailsPayloadSelector,
    contractAgreementSelector,
    otherNotesSelector,
    referencePayloadSelector,
    orderIdSelector,
    (
      cart,
      routerPaymentMonths,
      personalAccount,
      providerDetails,
      paymentDetails,
      contractAgreement,
      otherNotes,
      reference,
      orderId,
    ) => {
      const payload = {
        cart,
        router_monthly_payment: routerPaymentMonths,
        first_name: personalAccount && personalAccount.firstName,
        last_name: personalAccount && personalAccount.lastName,
        email: personalAccount && personalAccount.email,
        mobile_number: personalAccount && personalAccount.mobilePhone,
        date_of_birth: personalAccount && personalAccount.dateOfBirth,
        additional_authority: personalAccount &&
          personalAccount.additionalAuth && {
            first_name: personalAccount.additionalAuth.firstName,
            last_name: personalAccount.additionalAuth.lastName,
            mobile: personalAccount.additionalAuth.mobilePhone,
          },
        connection_date: personalAccount.connectionDate,
        hardware_pickup: personalAccount.pickup,
        hardware_delivery_address: {
          name:
            personalAccount &&
            personalAccount.deliveryAddress &&
            // @ts-ignore
            personalAccount.deliveryAddress.name,
          line1:
            personalAccount &&
            personalAccount.deliveryAddress &&
            // @ts-ignore
            personalAccount.deliveryAddress.line1,
          line2:
            personalAccount &&
            personalAccount.deliveryAddress &&
            // @ts-ignore
            personalAccount.deliveryAddress.line2,
          suburb:
            personalAccount &&
            personalAccount.deliveryAddress &&
            // @ts-ignore
            personalAccount.deliveryAddress.suburb,
          city:
            personalAccount &&
            personalAccount.deliveryAddress &&
            // @ts-ignore
            personalAccount.deliveryAddress.city,
          postcode:
            personalAccount &&
            personalAccount.deliveryAddress &&
            // @ts-ignore
            personalAccount.deliveryAddress.postcode,
        },
        existing_provider: personalAccount && personalAccount.transfer,
        existing_provider_details: {
          provider: providerDetails && providerDetails.provider,
          has_existing_phone: providerDetails && providerDetails.transferPhone,
          account_number: providerDetails && providerDetails.accountNumber,
          existing_phone_number: providerDetails && providerDetails.phoneNumber,
          keep_phone_number: providerDetails && providerDetails.keepPhoneNumber,
        },
        payment_details: {
          payment_method: paymentDetails.paymentMethod,
          bank_name: paymentDetails.bankName,
          account_holder_name: paymentDetails.accountHolder,
          account_number: paymentDetails.accountNumber,
          authority_consent: paymentDetails.authConfirmation,
        },
        bill_delivery: {
          method: paymentDetails.billDelivery.method,
          delivery_address: paymentDetails.billDelivery.deliveryAddress
            ? {
                // @ts-ignore
                name: paymentDetails.billDelivery.deliveryAddress.name,
                // @ts-ignore
                line1: paymentDetails.billDelivery.deliveryAddress.line1,
                // @ts-ignore
                line2: paymentDetails.billDelivery.deliveryAddress.line2,
                // @ts-ignore
                suburb: paymentDetails.billDelivery.deliveryAddress.suburb,
                // @ts-ignore
                city: paymentDetails.billDelivery.deliveryAddress.city,
                // @ts-ignore
                postcode: paymentDetails.billDelivery.deliveryAddress.postcode,
              }
            : {},
        },
        contract_agreement: contractAgreement,
        additional_notes: otherNotes,
        contract: reference && reference.contract,
        now_sales_rep_id: reference && reference.csr,
        offer: reference && reference.offer,
        friend_name: reference && reference.friendName,
        address_id: reference && reference.addressId,
        credit_check: reference && reference.creditCheck,
        order_id: orderId,
      };

      return payload;
    },
  );
