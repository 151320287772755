//

export const INITIALISE_DATA = 'App@INITIALISE_DATA';
export const SET_DEFAULT = 'App@SET_DEFAULT';

export type InitialiseDataAction = {
  type: typeof INITIALISE_DATA;
  addressId: string;
  packageId?: string;
};

export function initialiseData(
  addressId: string,
  packageId?: string,
): InitialiseDataAction {
  return {
    type: INITIALISE_DATA,
    addressId,
    packageId,
  };
}
