//

import { createSelector } from 'reselect';
import intersection from 'lodash/intersection';
import { type State } from '../constants';
import { type Offer } from './offer.constants';
import {
  fieldnameToProduct,
  FREE_NO_CONTRACT_KEY,
} from '../../screens/Screen.constants';
import {
  contentSelector,
  findItemByUuid,
  contractSelector,
  contractsSelector,
} from '../content/content.selectors';
import { availableTypesSelector } from '../availability/availability.selectors';
import { packageSelector } from '../package/package.selectors';

export const baseSelector = (state: State) => state.offer;

export const offerErrorSelector = createSelector(
  baseSelector,
  (state) => state && state.error,
);

export const isFetchingOfferSelector = createSelector(
  baseSelector,
  (state) => state.fetching,
);

export const offerSelector: (State) => Offer | undefined = createSelector(
  baseSelector,
  (state) => state && state.offer,
);

export const offerDiscountProductsSelector = createSelector(
  offerSelector,
  (offer) => offer && offer.discount_products,
);

export const offerDataCapsSelector = createSelector(
  offerSelector,
  contentSelector,
  (offer, content) => {
    if (offer) {
      return offer.allowed_data_caps
        .map((cap) => findItemByUuid(content, cap))
        .reduce((caps: any[], capData) => {
          // @ts-ignore
          if (capData) caps.push(capData.item);
          return caps;
        }, []);
    }
    return null;
  },
);

export const offerConnectionsSpeedsSelector = createSelector(
  offerSelector,
  contentSelector,
  (offer, content) => {
    if (offer) {
      return offer.allowed_connection_types
        .concat(offer.allowed_fibre_speeds)
        .map((connection) => findItemByUuid(content, connection))
        .reduce((connections: any[], connectionData) => {
          if (
            connectionData &&
            // @ts-ignore
            connectionData.item.provisioning_key !== 'Fibre'
          )
            // @ts-ignore
            connections.push(connectionData.item);
          return connections;
        }, []);
    }
    return null;
  },
);

export const offerContractsSelector = createSelector(
  offerSelector,
  contentSelector,
  (offer, content) => {
    if (offer) {
      return offer.allowed_contract_terms
        .map((contract) => findItemByUuid(content, contract))
        .reduce((contracts: any[], contractData) => {
          // @ts-ignore
          if (contractData) contracts.push(contractData.item);
          return contracts;
        }, []);
    }
    return null;
  },
);

// If offer has only one allowed contract, make it the default one
export const offerDefaultContractSelector = createSelector(
  offerContractsSelector,
  (offerContracts) =>
    Array.isArray(offerContracts) && offerContracts.length === 1
      ? offerContracts[0]
      : null,
);

export const offerDefaultFibreContractSelector = createSelector(
  offerSelector,
  contentSelector,
  contractsSelector,
  (offer, content, contracts) => {
    if (offer) {
      // Temporary fix
      // TODO: revert when we work out a better backend logic
      // When FREE_NO_CONTRACT is allowed in the offer, fibre should use that
      // Otherwise it's impossible to make an offer that allows users to choose
      // between fibre and adsl/vdsl and let them choose a contract for adsl/vdsl
      // Without this fix it would enforce 12 months contract if it is allowed
      const freeNoContract = contractSelector(contracts, FREE_NO_CONTRACT_KEY);
      if (
        freeNoContract &&
        offer.allowed_contract_terms.includes(freeNoContract.id)
      ) {
        return freeNoContract;
      }

      const contract = findItemByUuid(content, offer.default_fibre_contract);
      return contract && contract.item;
    }
    return null;
  },
);

const groupByPath = (dataArray: Array<Object | undefined>) => {
  const groups: Record<string, any> = {};
  dataArray.forEach((element) => {
    if (element) {
      // @ts-ignore
      const key = `${element.path.type}-${element.path.product}-${element.path.characteristic}`;
      groups[key] = {
        // @ts-ignore
        path: element.path,
        items: [
          ...(groups[key] ? groups[key].items : []),
          // @ts-ignore
          element.item,
        ],
      };
    }
  });
  return Object.keys(groups).map((key) => groups[key]); // Flow is not happy with Object.values()
};

export const offerRequiredProductsSelector = createSelector(
  offerSelector,
  contentSelector,
  (offer, content) => {
    if (offer) {
      return offer.required_products
        .map((id) => findItemByUuid(content, id))
        .concat(
          groupByPath(
            offer.required_products_characteristics.map((id) =>
              findItemByUuid(content, id),
            ),
          ),
        )
        .reduce((items, itemData) => {
          if (itemData) items.push(itemData);
          return items;
        }, []);
    }
    return null;
  },
);

export const findFieldnameFromPathData = (pathData) =>
  Object.keys(fieldnameToProduct).find(
    (key) =>
      pathData.type === fieldnameToProduct[key].type &&
      pathData.product === fieldnameToProduct[key].product &&
      (fieldnameToProduct[key].type === 'product' ||
        // @ts-ignore
        pathData.characteristic === fieldnameToProduct[key].characteristic),
  );

export const offerDefaultsSelector: (State) => Object = createSelector(
  packageSelector,
  offerSelector,
  offerRequiredProductsSelector,
  (pkg, offer, products) => {
    const defaults = {};

    if (!products) return defaults;

    if (
      (pkg && pkg.included_offer && pkg.included_offer.id) ===
      (offer && offer.id)
    ) {
      products.forEach((product) => {
        const fieldName = findFieldnameFromPathData(product.path);

        if (!fieldName) return;

        if (product.item) {
          defaults[fieldName] = product.item.provisioning_key;
        } else if (product.items && product.items.length === 1) {
          defaults[fieldName] = product.items[0].provisioning_key;
        }
      });
    }

    return defaults;
  },
);

export const offerAvailabilitySelector = createSelector(
  availableTypesSelector,
  offerDataCapsSelector,
  offerConnectionsSpeedsSelector,
  offerRequiredProductsSelector,
  (availability, dataCaps, connectionSpeeds, requiredProducts) => {
    const availableTypes = [...availability, 'any']; // so we can test for products that don't care
    const dataCapRequirements = dataCaps
      ? dataCaps
          .map((cap) => cap.availability_required)
          .filter((requirement) => !!requirement)
      : [];
    if (
      dataCapRequirements.length > 0 &&
      intersection(availableTypes, dataCapRequirements).length === 0
    ) {
      return false;
    }
    const speedsRequirements = connectionSpeeds
      ? connectionSpeeds
          .map((cap) => cap.availability_required)
          .filter((requirement) => !!requirement)
      : [];
    if (
      speedsRequirements.length > 0 &&
      intersection(availableTypes, speedsRequirements).length === 0
    ) {
      return false;
    }
    const productsRequirements = requiredProducts
      ? requiredProducts
          .reduce((list, productData) => {
            if (productData.item && productData.item.availability_required) {
              list.push(productData.item.availability_required);
            } else if (productData.items) {
              list.push(
                productData.items
                  // if availability_required is empty it doesn't care
                  .map((item) => item.availability_required || 'any'),
              );
            }
            return list;
          }, [])
          .filter((requirement) => requirement && requirement.length > 0)
      : [];
    if (
      productsRequirements.length > 0 &&
      productsRequirements.find(
        (requirement) =>
          requirement &&
          !availableTypes.includes(requirement) &&
          requirement.length > 0 &&
          intersection(availableTypes, requirement).length === 0,
      )
    ) {
      return false;
    }

    return true;
  },
);
