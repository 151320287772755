import classNames from 'classnames';
import { Field } from 'redux-form';
import DollarGroup from '../DollarGroup/DollarGroup';
import DollarGroupSmall from '../DollarGroup/DollarGroupSmall';
import SpeedInfo from '../SpeedInfo/SpeedInfo';
import { type CMSPlanDetails } from '../../state/content/content.constants';

type PlanCardProps = {
  id: string;
  title: string;
  description: string;
  value: string;
  name: string;
  amount?: string;
  amountExtra?: string;
  frequency?: string;
  type?: 'package' | 'normal' | 'speed';
  cms?: CMSPlanDetails;
};
function PlanCard({
  id,
  title,
  description,
  value,
  name,
  amount,
  amountExtra,
  frequency,
  type,
  cms,
}: PlanCardProps) {
  return (
    <div className="radio-picker" data-key={title}>
      <Field
        className="radio__input"
        component="input"
        type="radio"
        name={name}
        value={value}
        id={id}
        props={{
          'data-key': `Radio${title}`,
        }}
      />
      <label
        htmlFor={id}
        className={classNames({
          'radio-picker__label': true,
          'radio-picker__label--green': true,
        })}
      >
        <div className="radio-picker__content">
          <div className="radio-picker__selector">
            <span className="radio__custom-input" />
            <span className="radio-picker__title">{title}</span>
          </div>
          {amount && (
            <div className="radio-picker__amount">
              <DollarGroup
                size="small"
                amount={`${amount}`}
                paymentFrequency={frequency}
              />
            </div>
          )}
          {amountExtra && (
            <div className="radio-picker__amount">
              <DollarGroupSmall
                amount={`${amountExtra}`}
                paymentFrequency={frequency}
              />
            </div>
          )}
          <div className="radio-picker__description">
            <p className="u-text-s u-mb-xxs">{description}</p>
            {type === 'speed' && (
              <SpeedInfo title={title} cms={cms} value={value} />
            )}
          </div>
        </div>
      </label>
    </div>
  );
}

PlanCard.defaultProps = {
  amount: '',
  frequency: '',
  type: 'normal',
};

export default PlanCard;
