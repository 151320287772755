import { createSelector } from 'reselect';
import {
  additionalAuthSelector,
  preferredStartDateSelector,
  hasIncludedRouterSelector,
  hardwareDeliverySelector,
  firstNameSelector,
  lastNameSelector,
  emailAddressSelector,
  mobilePhoneSelector,
  dateOfBirthSelector,
  additionalAuthDetailsSelector,
  type AdditionalAuthPayload,
  preferredStartDateValueSelector,
  fullNameSelector,
  formValuesSelector,
  providerTransferSelector,
} from '../Screen.selectors';
import { OPTIONS, FIELDNAMES } from '../Screen.constants';
import { cmsSelector } from '../../state/content/content.selectors';
import { type State, type Address } from '../../state/constants';
import { addressSelector } from '../../components/CartSummary/CartSummary.selectors';

export const psersonAccountCMSSelector = createSelector(
  cmsSelector,
  (cms) => cms && cms.personal_account,
);

export const willShowAdditionalAuthDetails = createSelector(
  additionalAuthSelector,
  (additionalAuth): boolean =>
    additionalAuth === OPTIONS.ADDITIONAL_AUTH.SELECTED,
);

export const willShowPreferredStartDate = (): boolean => true;

export const willShowPreferredStartDateDetails = createSelector(
  preferredStartDateSelector,
  /*
   * "Preferred start date?"
   */
  (preferredStartDate): boolean =>
    preferredStartDate === OPTIONS.PREFERRED_START_DATE.A_SPECIFIC_DATE,
);

export const willShowHardwareDelivery = createSelector(
  hasIncludedRouterSelector,
  /*
   * "Step 15 will not display if the user has not selected a router (Step 5)"
   */
  (hasIncludedRouter): boolean => hasIncludedRouter,
);

export const willShowHardwareDeliveryDetails = createSelector(
  hasIncludedRouterSelector,
  hardwareDeliverySelector,
  /*
   * "Step 15 will not display if the user has not selected a router (Step 5)"
   * +
   * "Other address selected?"
   */
  (hasIncludedRouter, hardwareDelivery): boolean =>
    hasIncludedRouter &&
    hardwareDelivery === OPTIONS.HARDWARE_DELIVERY.ANOTHER_ADDRESS,
);

export const willShowProviderTransfer = (): boolean => true;

/* Payload */

export const hardwareDeliveryAddressSelector: (State) => Address | undefined = (
  state: State,
) => {
  let address;
  const hardwareDeliveryOption = hardwareDeliverySelector(state);

  if (hardwareDeliveryOption === OPTIONS.HARDWARE_DELIVERY.SERVICE_ADDRESS) {
    const serviceAddress = addressSelector(state);
    address = {
      name: fullNameSelector(state),
      // @ts-ignore
      line1: serviceAddress.line1,
      // @ts-ignore
      line2: serviceAddress.line2,
      // @ts-ignore
      suburb: serviceAddress.suburb,
      // @ts-ignore
      city: serviceAddress.city,
      // @ts-ignore
      postcode: serviceAddress.postcode,
    };
  } else if (
    hardwareDeliveryOption === OPTIONS.HARDWARE_DELIVERY.ANOTHER_ADDRESS
  ) {
    address = {
      name: formValuesSelector(
        state,
        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.NAME,
      ),
      line1: formValuesSelector(
        state,
        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.ADDRESS_LINE_1,
      ),
      line2: formValuesSelector(
        state,
        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.ADDRESS_LINE_2,
      ),
      suburb: formValuesSelector(
        state,
        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.SUBURB,
      ),
      city: formValuesSelector(
        state,
        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.CITY,
      ),
      postcode: formValuesSelector(
        state,
        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.POSTCODE,
      ),
    };
  }

  return address;
};

type PersonalAccountPayload = {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  dateOfBirth?: string;
  additionalAuth?: AdditionalAuthPayload;
  connectionDate: string;
  pickup: boolean;
  deliveryAddress: Address;
  transfer: boolean;
};

export const personalAccountPayloadSelector: (State) => PersonalAccountPayload =
  createSelector(
    firstNameSelector,
    lastNameSelector,
    emailAddressSelector,
    mobilePhoneSelector,
    dateOfBirthSelector,
    additionalAuthDetailsSelector,
    preferredStartDateValueSelector,
    hardwareDeliverySelector,
    hardwareDeliveryAddressSelector,
    providerTransferSelector,
    (
      firstName,
      lastName,
      email,
      mobilePhone,
      dateOfBirth,
      additionalAuth,
      preferredStartDate,
      hardwareDelivery,
      hardwareDeliveryAddress,
      transfer,
    ) => ({
      firstName,
      lastName,
      email,
      mobilePhone,
      dateOfBirth,
      additionalAuth: additionalAuth && {
        firstName: additionalAuth.firstName,
        lastName: additionalAuth.lastName,
        mobilePhone: additionalAuth.mobilePhone,
      },
      connectionDate: preferredStartDate || '',
      pickup: hardwareDelivery === OPTIONS.HARDWARE_DELIVERY.PICK_UP,
      deliveryAddress: {
        // @ts-ignore
        name: hardwareDeliveryAddress && hardwareDeliveryAddress.name,
        // @ts-ignore
        line1: hardwareDeliveryAddress && hardwareDeliveryAddress.line1,
        // @ts-ignore
        line2: hardwareDeliveryAddress && hardwareDeliveryAddress.line2,
        // @ts-ignore
        suburb: hardwareDeliveryAddress && hardwareDeliveryAddress.suburb,
        // @ts-ignore
        city: hardwareDeliveryAddress && hardwareDeliveryAddress.city,
        // @ts-ignore
        postcode: hardwareDeliveryAddress && hardwareDeliveryAddress.postcode,
      },
      transfer,
    }),
  );
