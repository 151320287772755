import { Fragment } from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';
import {
  FIELDNAMES,
  OPTIONS,
  SCREEN_VALIDATION_ERROR_MESSAGE,
} from '../Screen.constants';
import ChoiceInput from '../../components/ChoiceInput/ChoiceInput';
import CartSummary from '../../components/CartSummary/CartSummary.container';
import Icon from '../../../../components/Icon/Icon';
import ProgressTracker from '../../components/ProgressTracker/ProgressTracker.container';
import { type Address } from '../../state/constants';
import { type CMSPersonalAccount } from '../../state/content/content.constants';
import { normalizeNumbers } from '../../../../utils/input/normalize';
import ErrorMessage from '../../components/InputError/InputErrorMessage';
import InputErrorMessage from '../../components/InputError/InputErrorMessage.container';
import InputErrorBlock from '../../components/InputError/InputErrorBlock.container';
import InputWrapper from '../../components/InputWrapper/InputWrapper.container';

type PersonalAccountScreenProps = {
  prevScreen: () => void;
  address: Address;
  phonelineSelected: boolean;
  providerTransfer: boolean;
  willShowAdditionalAuthDetails: boolean;
  willShowPreferredStartDateDetails: boolean;
  willShowHardwareDelivery: boolean;
  willShowHardwareDeliveryDetails: boolean;
  cms: CMSPersonalAccount;
  captureFormDetails: () => void;
  handleSubmit: () => void;
  submitFailed: boolean;
  submitting: boolean;
  invalid: boolean;
};

export default function PersonalAccountScreen({
  prevScreen,
  address,
  phonelineSelected,
  providerTransfer,
  willShowAdditionalAuthDetails,
  willShowPreferredStartDateDetails,
  willShowHardwareDelivery,
  willShowHardwareDeliveryDetails,
  cms,
  captureFormDetails,
  handleSubmit,
  submitFailed,
  submitting,
  invalid,
}: PersonalAccountScreenProps) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="banner">
        <div className="banner__content line-length-large">
          <h1
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cms && cms.screen && cms.screen.heading,
            }}
          />
        </div>
      </div>
      {/* @ts-ignore */}
      <ProgressTracker />
      <div className="section section--no-bottom section--no-top@small">
        <div className="grid-flex grid--row-spacing-s grid--col-spacing">
          <div className="width-2/3@large">
            <div className="line-length">
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cms && cms.screen && cms.screen.description,
                }}
              />
            </div>
            <div className="section section--no-top section--stacked line-length">
              <div className="icon-block icon-block--lg">
                <div className="icon-block__image">
                  <Icon
                    type="icon-personal-details"
                    className="icon-block__svg"
                  />
                </div>
                <div className="icon-block__content">
                  <h3 className="u-mb-m">Your details</h3>
                  <div className="grid-flex grid--row-spacing-m grid--col-spacing-s">
                    <div className="width-1/2@medium">
                      <InputWrapper
                        label="First name"
                        name={FIELDNAMES.FIRST_NAME}
                        content={({ error }) => (
                          <Field
                            type="text"
                            name={FIELDNAMES.FIRST_NAME}
                            component="input"
                            className={classnames(
                              'input',
                              error && 'input--error',
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="width-1/2@medium">
                      <InputWrapper
                        label="Last name"
                        name={FIELDNAMES.LAST_NAME}
                        content={({ error }) => (
                          <Field
                            type="text"
                            name={FIELDNAMES.LAST_NAME}
                            component="input"
                            className={classnames(
                              'input',
                              error && 'input--error',
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="width-2/3@medium">
                      <InputWrapper
                        label="Email"
                        name={FIELDNAMES.EMAIL_ADDRESS}
                        helpText="(Your bill will be sent to this email)"
                        content={({ error }) => (
                          <Field
                            type="email"
                            component="input"
                            onBlur={captureFormDetails}
                            name={FIELDNAMES.EMAIL_ADDRESS}
                            className={classnames(
                              'input',
                              error && 'input--error',
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="width-2/3@medium">
                      <InputWrapper
                        label="Contact mobile number"
                        name={FIELDNAMES.MOBILE_PHONE}
                        content={({ error }) => (
                          <Field
                            type="tel"
                            component="input"
                            onBlur={captureFormDetails}
                            name={FIELDNAMES.MOBILE_PHONE}
                            className={classnames(
                              'input',
                              error && 'input--error',
                            )}
                            normalize={normalizeNumbers}
                          />
                        )}
                      />
                    </div>
                    <div className="width-full">
                      <InputWrapper
                        label="Date of birth"
                        multi
                        names={[
                          FIELDNAMES.DATE_OF_BIRTH.DD,
                          FIELDNAMES.DATE_OF_BIRTH.MM,
                          FIELDNAMES.DATE_OF_BIRTH.YYYY,
                        ]}
                        content={({ error }) => (
                          <Fragment>
                            <Field
                              type="text"
                              component="input"
                              name={FIELDNAMES.DATE_OF_BIRTH.DD}
                              className={classnames(
                                'input',
                                'input--sm',
                                'input--spacing',
                                error && 'input--error',
                              )}
                              minLength={2}
                              maxLength={2}
                              size="2"
                              placeholder="DD"
                              normalize={normalizeNumbers}
                              aria-label="Day"
                            />
                            <Field
                              type="text"
                              component="input"
                              name={FIELDNAMES.DATE_OF_BIRTH.MM}
                              className={classnames(
                                'input',
                                'input--xs',
                                'input--spacing',
                                error && 'input--error',
                              )}
                              minLength={2}
                              maxLength={2}
                              size="2"
                              placeholder="MM"
                              normalize={normalizeNumbers}
                              aria-label="Month"
                            />
                            <Field
                              type="text"
                              component="input"
                              name={FIELDNAMES.DATE_OF_BIRTH.YYYY}
                              className={classnames(
                                'input',
                                'input--sm',
                                'input--spacing',
                                error && 'input--error',
                              )}
                              minLength={4}
                              maxLength={4}
                              placeholder="YYYY"
                              size="4"
                              normalize={normalizeNumbers}
                              aria-label="Year"
                            />
                          </Fragment>
                        )}
                      />
                      <p className="u-text-xs u-mt">
                        We may use your contact details to get in touch, to
                        ensure you’re able to sign-up successfully. Read more in
                        our{' '}
                        <a
                          href="/terms-conditions/privacy-policy"
                          className="link--plain u-text-underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section section--stacked line-length">
              <div className="icon-block icon-block--lg">
                <div className="icon-block__image">
                  <Icon type="icon-add-authority" className="icon-block__svg" />
                </div>
                <div className="icon-block__content">
                  <InputErrorBlock name={FIELDNAMES.ADDITIONAL_AUTH}>
                    <InputErrorMessage name={FIELDNAMES.ADDITIONAL_AUTH} />
                    <h3 className="u-mb-xxs">
                      Want to add an additional authority to the account?
                    </h3>
                    <p className="u-mb-m">
                      This person will have full access to your account,
                      including the ability to make changes.
                    </p>
                    <div className="width-full">
                      <ChoiceInput
                        name={FIELDNAMES.ADDITIONAL_AUTH}
                        type="radio"
                        label="No thanks"
                        value={OPTIONS.ADDITIONAL_AUTH.NOT_SELECTED}
                      />
                    </div>
                    <div className="width-full">
                      <ChoiceInput
                        name={FIELDNAMES.ADDITIONAL_AUTH}
                        type="radio"
                        label="Yes please"
                        value={OPTIONS.ADDITIONAL_AUTH.SELECTED}
                      />
                    </div>
                    {willShowAdditionalAuthDetails && (
                      <div className="form-group form-group--inset">
                        <div className="grid-flex grid--row-spacing-s grid--col-spacing-s">
                          <div className="width-1/2@medium">
                            <InputWrapper
                              label="First name"
                              name={FIELDNAMES.AUTH_FIRST_NAME}
                              content={({ error }) => (
                                <Field
                                  type="text"
                                  name={FIELDNAMES.AUTH_FIRST_NAME}
                                  component="input"
                                  className={classnames(
                                    'input',
                                    error && 'input--error',
                                  )}
                                />
                              )}
                            />
                          </div>
                          <div className="width-1/2@medium">
                            <InputWrapper
                              label="Last name"
                              name={FIELDNAMES.AUTH_LAST_NAME}
                              content={({ error }) => (
                                <Field
                                  type="text"
                                  name={FIELDNAMES.AUTH_LAST_NAME}
                                  component="input"
                                  className={classnames('input', {
                                    'input--error': error,
                                  })}
                                />
                              )}
                            />
                          </div>
                          <div className="width-2/3@medium">
                            <InputWrapper
                              label="Contact number"
                              name={FIELDNAMES.AUTH_CONTACT_NUMBER}
                              content={({ error }) => (
                                <Field
                                  type="tel"
                                  component="input"
                                  name={FIELDNAMES.AUTH_CONTACT_NUMBER}
                                  normalize={normalizeNumbers}
                                  className={classnames(
                                    'input',
                                    error && 'input--error',
                                  )}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </InputErrorBlock>
                </div>
              </div>
            </div>

            <div className="section section--stacked line-length">
              <div className="icon-block icon-block--lg">
                <div className="icon-block__image">
                  <Icon type="icon-start-date" className="icon-block__svg" />
                </div>
                <div className="icon-block__content">
                  <InputErrorBlock name={FIELDNAMES.PREFERRED_START_DATE}>
                    <InputErrorMessage name={FIELDNAMES.PREFERRED_START_DATE} />
                    <h3 className="u-mb-xxs">
                      When would you like to get connected?
                    </h3>
                    <p className="u-mb-m">
                      We’ll try our hardest to get you connected on your ideal
                      target date (sometimes it’s not always possible). One of
                      the team will be in touch to confirm.
                    </p>
                    <div className="width-full">
                      <ChoiceInput
                        name={FIELDNAMES.PREFERRED_START_DATE}
                        type="radio"
                        label="ASAP. This typically takes 5-10 working days."
                        value={OPTIONS.PREFERRED_START_DATE.ASAP}
                      />
                      <ChoiceInput
                        name={FIELDNAMES.PREFERRED_START_DATE}
                        type="radio"
                        label="A specific date"
                        value={OPTIONS.PREFERRED_START_DATE.A_SPECIFIC_DATE}
                      />
                    </div>
                    {willShowPreferredStartDateDetails && (
                      <div className="form-group form-group--inset">
                        <InputWrapper
                          label="Preferred start date"
                          multi
                          names={[
                            FIELDNAMES.PREFERRED_START_DATE_DETAILS.DD,
                            FIELDNAMES.PREFERRED_START_DATE_DETAILS.MM,
                            FIELDNAMES.PREFERRED_START_DATE_DETAILS.YYYY,
                          ]}
                          content={({ error }) => (
                            <Fragment>
                              <Field
                                type="text"
                                component="input"
                                name={
                                  FIELDNAMES.PREFERRED_START_DATE_DETAILS.DD
                                }
                                className={classnames(
                                  'input',
                                  'input--xs',
                                  'input--spacing',
                                  error && 'input--error',
                                )}
                                minLength={2}
                                maxLength={2}
                                size="2"
                                placeholder="DD"
                                normalize={normalizeNumbers}
                                aria-label="Day"
                              />
                              <Field
                                type="text"
                                component="input"
                                name={
                                  FIELDNAMES.PREFERRED_START_DATE_DETAILS.MM
                                }
                                className={classnames(
                                  'input',
                                  'input--xs',
                                  'input--spacing',
                                  error && 'input--error',
                                )}
                                minLength={2}
                                maxLength={2}
                                size="2"
                                placeholder="MM"
                                normalize={normalizeNumbers}
                                aria-label="Month"
                              />
                              <Field
                                type="text"
                                component="input"
                                name={
                                  FIELDNAMES.PREFERRED_START_DATE_DETAILS.YYYY
                                }
                                className={classnames(
                                  'input',
                                  'input--sm',
                                  'input--spacing',
                                  error && 'input--error',
                                )}
                                minLength={4}
                                maxLength={4}
                                placeholder="YYYY"
                                size="4"
                                normalize={normalizeNumbers}
                                aria-label="Year"
                              />
                            </Fragment>
                          )}
                        />
                      </div>
                    )}
                  </InputErrorBlock>
                </div>
              </div>
            </div>
            {willShowHardwareDelivery && (
              <div className="section section--stacked line-length">
                <div className="icon-block icon-block--lg">
                  <div className="icon-block__image">
                    <Icon type="icon-delivery" className="icon-block__svg" />
                  </div>
                  <div className="icon-block__content">
                    <InputErrorBlock name={FIELDNAMES.HARDWARE_DELIVERY}>
                      <InputErrorMessage name={FIELDNAMES.HARDWARE_DELIVERY} />
                      <h3 className="u-mb-xxs">Hardware delivery</h3>
                      <p className="u-mb-m">Please send my hardware to</p>
                      <div className="width-full">
                        <ChoiceInput
                          name={FIELDNAMES.HARDWARE_DELIVERY}
                          type="radio"
                          label={
                            address &&
                            Object.values(address)
                              .filter((line) => !!line)
                              .join(', ')
                          }
                          value={OPTIONS.HARDWARE_DELIVERY.SERVICE_ADDRESS}
                        />
                        <ChoiceInput
                          name={FIELDNAMES.HARDWARE_DELIVERY}
                          type="radio"
                          label="Another address"
                          value={OPTIONS.HARDWARE_DELIVERY.ANOTHER_ADDRESS}
                        />
                        {willShowHardwareDeliveryDetails && (
                          <div className="form-group form-group--inset">
                            <div className="grid-flex grid--row-spacing-m grid--col-spacing-s">
                              <div className="width-1/2@medium">
                                <InputWrapper
                                  label="Name"
                                  name={
                                    FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.NAME
                                  }
                                  content={({ error }) => (
                                    <Field
                                      type="text"
                                      component="input"
                                      name={
                                        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS
                                          .NAME
                                      }
                                      className={classnames(
                                        'input',
                                        error && 'input--error',
                                      )}
                                    />
                                  )}
                                />
                              </div>
                              <div className="width-1/2@medium">
                                <InputWrapper
                                  label="Address line 1"
                                  name={
                                    FIELDNAMES.HARDWARE_DELIVERY_ADDRESS
                                      .ADDRESS_LINE_1
                                  }
                                  content={({ error }) => (
                                    <Field
                                      type="text"
                                      component="input"
                                      name={
                                        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS
                                          .ADDRESS_LINE_1
                                      }
                                      className={classnames(
                                        'input',
                                        error && 'input--error',
                                      )}
                                    />
                                  )}
                                />
                              </div>
                              <div className="width-1/2@medium">
                                <InputWrapper
                                  label="Address line 2 (optional)"
                                  name={
                                    FIELDNAMES.HARDWARE_DELIVERY_ADDRESS
                                      .ADDRESS_LINE_2
                                  }
                                  content={({ error }) => (
                                    <Field
                                      type="text"
                                      component="input"
                                      name={
                                        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS
                                          .ADDRESS_LINE_2
                                      }
                                      className={classnames(
                                        'input',
                                        error && 'input--error',
                                      )}
                                    />
                                  )}
                                />
                              </div>
                              <div className="width-1/3@medium">
                                <InputWrapper
                                  label="Suburb"
                                  name={
                                    FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.SUBURB
                                  }
                                  content={({ error }) => (
                                    <Field
                                      type="text"
                                      component="input"
                                      name={
                                        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS
                                          .SUBURB
                                      }
                                      className={classnames(
                                        'input',
                                        error && 'input--error',
                                      )}
                                    />
                                  )}
                                />
                              </div>
                              <div className="width-1/3@medium">
                                <InputWrapper
                                  label="City"
                                  name={
                                    FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.CITY
                                  }
                                  content={({ error }) => (
                                    <Field
                                      type="text"
                                      component="input"
                                      name={
                                        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS
                                          .CITY
                                      }
                                      className={classnames(
                                        'input',
                                        error && 'input--error',
                                      )}
                                    />
                                  )}
                                />
                              </div>
                              <div className="width-1/3@medium">
                                <InputWrapper
                                  label="Postcode"
                                  name={
                                    FIELDNAMES.HARDWARE_DELIVERY_ADDRESS
                                      .POSTCODE
                                  }
                                  content={({ error }) => (
                                    <Field
                                      type="text"
                                      component="input"
                                      name={
                                        FIELDNAMES.HARDWARE_DELIVERY_ADDRESS
                                          .POSTCODE
                                      }
                                      normalize={normalizeNumbers}
                                      className={classnames(
                                        'input',
                                        error && 'input--error',
                                      )}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <ChoiceInput
                          name={FIELDNAMES.HARDWARE_DELIVERY}
                          type="radio"
                          label="Pick up from our Napier hub"
                          value={OPTIONS.HARDWARE_DELIVERY.PICK_UP}
                        />
                      </div>
                    </InputErrorBlock>
                  </div>
                </div>
              </div>
            )}

            <div className="section section--stacked line-length">
              <div className="icon-block icon-block--lg">
                <div className="icon-block__image">
                  <Icon
                    type="icon-switch-provider"
                    className="icon-block__svg"
                  />
                </div>
                <div className="icon-block__content">
                  <InputErrorBlock name={FIELDNAMES.PROVIDER_TRANSFER}>
                    <InputErrorMessage name={FIELDNAMES.PROVIDER_TRANSFER} />
                    <h3 className="u-mb-m">
                      {phonelineSelected
                        ? 'Are you transferring from another broadband and phone provider?'
                        : 'Are you transferring from another broadband provider?'}
                    </h3>
                    <div className="width-full">
                      <ChoiceInput
                        name={FIELDNAMES.PROVIDER_TRANSFER}
                        type="radio"
                        label="No"
                        value={OPTIONS.PROVIDER_TRANSFER.NO}
                      />
                      <ChoiceInput
                        name={FIELDNAMES.PROVIDER_TRANSFER}
                        type="radio"
                        label="Yes"
                        value={OPTIONS.PROVIDER_TRANSFER.YES}
                      />
                    </div>
                  </InputErrorBlock>
                </div>
              </div>
            </div>

            <div className="section section--stacked line-length">
              <p>
                <button
                  type="button"
                  className="btn btn--hollow-dark"
                  onClick={prevScreen}
                  data-key="BackExtras"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn u-float-right"
                  disabled={submitting}
                  data-key={
                    providerTransfer ? 'NextProviderTransfer' : 'NextPayment'
                  }
                >
                  {submitting ? (
                    <Icon
                      title="Validating..."
                      type="icon-loading"
                      className="i--medium i--vertical-middle"
                    />
                  ) : (
                    'Next'
                  )}
                </button>
              </p>
              {submitFailed && invalid && (
                <ErrorMessage
                  className="u-text-right"
                  error={SCREEN_VALIDATION_ERROR_MESSAGE}
                />
              )}
            </div>
            <div className="section section--stacked">
              <div className="line-length">
                <div
                  className="u-text-s"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: cms && cms.disclaimer && cms.disclaimer.description,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="width-1/3@large">
            {/* @ts-ignore */}
            <CartSummary />
          </div>
        </div>
      </div>
    </form>
  );
}
