//

import { FIELDNAMES, OPTIONS } from '../Screen.constants';
import {
  required,
  requiredAvailable,
} from '../../../../utils/input/validation';
import { type ChooseRouterProps } from './ChooseRouterScreen';

export default (formData, props: ChooseRouterProps) => {
  if (formData[FIELDNAMES.SELECT_ROUTER] === OPTIONS.INCLUDE_ROUTER.NO) {
    return {};
  }

  // Should not be able to select the 24 month option when not shown
  const invalid24MountSelection =
    !props.willShow24MonthsPaymentSelector &&
    formData[FIELDNAMES.ROUTER_PAYMENT] ===
      OPTIONS.ROUTER_PAYMENT.MONTHLY_PAYMENTS_24;

  return {
    [FIELDNAMES.SELECT_ROUTER]:
      (requiredAvailable(
        formData[FIELDNAMES.SELECT_ROUTER],
        props.routerOptions,
      ) &&
        'Please choose one of the following router options:') ||
      ((required(formData[FIELDNAMES.ROUTER_PAYMENT]) ||
        invalid24MountSelection) &&
        'Please choose one of the following payment options:'),
  };
};
