import { connect } from 'react-redux';
import InputWrapper from './InputWrapper';
import { type Fieldname } from '../../screens/Screen.constants';
import { type State } from '../../state/constants';
import { fieldErrorSelector } from '../../screens/Screen.selectors';

type Props = { name?: Fieldname; names?: Array<Fieldname> };

function mapStateToProps(state: State, { name, names }: Props) {
  let error;
  if (name) {
    error = fieldErrorSelector(state)(name);
  }
  if (names) {
    const errors = names.map((n) => fieldErrorSelector(state)(n));
    errors.some((err) => {
      error = err;
      return !!err;
    });
  }
  return {
    error,
  };
}

export default connect(mapStateToProps)(InputWrapper);
