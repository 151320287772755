import { Component } from 'react';
import type { Offer } from '../../state/offer/offer.constants';

type Props = {
  offer: Offer;
  offerError?: string;
  fetchOffer: (string) => void;
  isFetching: boolean;
};

type State = {
  isExpanded: boolean;
  promoCode: string;
  hasSubmitted: boolean;
  isSameCode: boolean;
};

class PromoCode extends Component<Props, State> {
  state = {
    isExpanded: false,
    promoCode: '',
    hasSubmitted: false,
    isSameCode: false,
  };

  toggleExpand = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  handleChange = (event: Event) => {
    // @ts-ignore
    const promoCode = event.target.value;
    this.setState({
      promoCode: promoCode ? promoCode.trim() : '',
      isSameCode: false,
    });
  };

  handleKeyPress = (e: Event) => {
    // @ts-ignore
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSumbit();
    }
  };

  handleSumbit = () => {
    const { promoCode } = this.state;
    const { fetchOffer, isFetching, offer } = this.props;

    if (offer && promoCode.toLowerCase() === offer.promo_code.toLowerCase()) {
      this.setState({ isSameCode: true });
    } else if (promoCode && !isFetching) {
      fetchOffer(promoCode);
      this.setState({ hasSubmitted: true });
    }
  };

  render() {
    const { offer, offerError, isFetching } = this.props;
    const { isExpanded, promoCode, hasSubmitted, isSameCode } = this.state;
    return (
      <div className="card-summary__highlight">
        <p>
          <button
            onClick={this.toggleExpand}
            type="button"
            className="icon-text link link--underline link--underline-white u-text-s"
            id="enter-promo-code"
          >
            {offer ? 'Enter new promo code' : 'Promo code? Enter it here'}
          </button>
        </p>
        {isExpanded && (
          <div>
            {offer && (
              <p className="label--warning" id="promo-code-warning">
                Entering a promo code will override your offer
              </p>
            )}
            <input
              type="text"
              // @ts-ignore
              onChange={this.handleChange}
              value={promoCode}
              // @ts-ignore
              onKeyPress={this.handleKeyPress}
              className="input input--rounded input--inline-small"
              aria-describedby={
                (offerError && hasSubmitted && 'promo-code-error') ||
                (offer && 'promo-code-warning')
              }
              aria-labelledby="enter-promo-code"
              disabled={isFetching}
            />
            <button
              type="button"
              onClick={this.handleSumbit}
              className="btn btn--small btn--yellow"
              disabled={!promoCode || isFetching}
            >
              Submit
            </button>
            {offerError && hasSubmitted && !isFetching && (
              <p className="label--warning" id="promo-code-error">
                {offerError}
              </p>
            )}
            {isSameCode && (
              <p>The code you entered is already your current promo code</p>
            )}
          </div>
        )}
      </div>
    );
  }
}

// Reset the component when the request was successful in which case the offer has changed
const PromoCodeWrapper = ({ offer, ...props }: Props) => (
  <PromoCode key={offer ? offer.id : 'none'} offer={offer} {...props} />
);

export default PromoCodeWrapper;
