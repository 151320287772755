import { SubmissionError } from 'redux-form';
import { FIELDNAMES } from '../Screen.constants';
import { required } from '../../../../utils/input/validation';

export const syncValidate = (formData) => ({
  [FIELDNAMES.FIRST_NAME]:
    required(formData[FIELDNAMES.FIRST_NAME]) &&
    'Please enter your first name below',
  [FIELDNAMES.LAST_NAME]:
    required(formData[FIELDNAMES.LAST_NAME]) &&
    'Please enter your last name below',
  [FIELDNAMES.EMAIL_ADDRESS]:
    required(formData[FIELDNAMES.EMAIL_ADDRESS]) &&
    'Please enter your email below',
  [FIELDNAMES.MOBILE_PHONE]:
    required(formData[FIELDNAMES.MOBILE_PHONE]) &&
    'Please enter your contact number below',
  [FIELDNAMES.DATE_OF_BIRTH.DD]:
    required(formData[FIELDNAMES.DATE_OF_BIRTH.DD]) &&
    'Please enter your date of birth below',
  [FIELDNAMES.DATE_OF_BIRTH.MM]:
    required(formData[FIELDNAMES.DATE_OF_BIRTH.MM]) &&
    'Please enter your date of birth below',
  [FIELDNAMES.DATE_OF_BIRTH.YYYY]:
    required(formData[FIELDNAMES.DATE_OF_BIRTH.YYYY]) &&
    'Please enter your date of birth below',
  [FIELDNAMES.AUTH_FIRST_NAME]:
    required(formData[FIELDNAMES.AUTH_FIRST_NAME]) &&
    'Please enter their first name below',
  [FIELDNAMES.AUTH_LAST_NAME]:
    required(formData[FIELDNAMES.AUTH_LAST_NAME]) &&
    'Please enter their last name below',
  [FIELDNAMES.AUTH_CONTACT_NUMBER]:
    required(formData[FIELDNAMES.AUTH_CONTACT_NUMBER]) &&
    'Please enter their contact number below',
  [FIELDNAMES.PREFERRED_START_DATE]:
    required(formData[FIELDNAMES.PREFERRED_START_DATE]) &&
    'Please choose a start date below',
  [FIELDNAMES.PREFERRED_START_DATE_DETAILS.DD]:
    required(formData[FIELDNAMES.PREFERRED_START_DATE_DETAILS.DD]) &&
    'Please enter your preferred start date below',
  [FIELDNAMES.PREFERRED_START_DATE_DETAILS.MM]:
    required(formData[FIELDNAMES.PREFERRED_START_DATE_DETAILS.MM]) &&
    'Please enter your preferred start date below',
  [FIELDNAMES.PREFERRED_START_DATE_DETAILS.YYYY]:
    required(formData[FIELDNAMES.PREFERRED_START_DATE_DETAILS.YYYY]) &&
    'Please enter your preferred start date below',
  [FIELDNAMES.HARDWARE_DELIVERY]:
    required(formData[FIELDNAMES.HARDWARE_DELIVERY]) &&
    'Please choose a delivery option below',
  [FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.NAME]:
    required(formData[FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.NAME]) &&
    'Please enter your delivery address',
  [FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.ADDRESS_LINE_1]:
    required(formData[FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.ADDRESS_LINE_1]) &&
    'Please enter your delivery address',
  [FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.SUBURB]:
    required(formData[FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.SUBURB]) &&
    'Please enter your delivery suburb',
  [FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.CITY]:
    required(formData[FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.CITY]) &&
    'Please enter your delivery city',
  [FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.POSTCODE]:
    required(formData[FIELDNAMES.HARDWARE_DELIVERY_ADDRESS.POSTCODE]) &&
    'Please enter your delivery postcode',
  [FIELDNAMES.PROVIDER_TRANSFER]:
    required(formData[FIELDNAMES.PROVIDER_TRANSFER]) &&
    'Please choose an option below',
});

type Response = {
  data: {
    errors: {
      date_of_birth?: string;
      email?: string;
      first_name?: string;
      last_name?: string;
      mobile_number?: string;
      connection_date?: string;
    };
  };
};

export const asyncValidate = (response: Response) => {
  const { data } = response;

  if (Object.keys(data && data.errors).length === 0) {
    return data;
  }

  /* eslint-disable camelcase */
  const {
    date_of_birth,
    email,
    first_name,
    last_name,
    mobile_number,
    connection_date,
  } = data.errors;
  const error = {};

  if (date_of_birth) {
    error[FIELDNAMES.DATE_OF_BIRTH.YYYY] = date_of_birth;
    error[FIELDNAMES.DATE_OF_BIRTH.DD] = date_of_birth;
    error[FIELDNAMES.DATE_OF_BIRTH.MM] = date_of_birth;
  }

  if (connection_date) {
    error[FIELDNAMES.PREFERRED_START_DATE_DETAILS.YYYY] = connection_date;
    error[FIELDNAMES.PREFERRED_START_DATE_DETAILS.DD] = connection_date;
    error[FIELDNAMES.PREFERRED_START_DATE_DETAILS.MM] = connection_date;
  }

  if (email) error[FIELDNAMES.EMAIL_ADDRESS] = email;
  if (first_name) error[FIELDNAMES.FIRST_NAME] = first_name;
  if (last_name) error[FIELDNAMES.LAST_NAME] = last_name;
  if (mobile_number) error[FIELDNAMES.MOBILE_PHONE] = mobile_number;
  /* eslint-enable camelcase */

  throw new SubmissionError({
    ...error,
  });
};
