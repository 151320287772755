import { FIELDNAMES } from '../Screen.constants';
import { required } from '../../../../utils/input/validation';

export default (formData) => ({
  [FIELDNAMES.FRIEND_NAME]:
    required(formData[FIELDNAMES.FRIEND_NAME]) &&
    'Please specify who referred you',
  [FIELDNAMES.CONTRACT_AGREEMENT]:
    required(formData[FIELDNAMES.CONTRACT_AGREEMENT]) &&
    'Please accept the agreements',
  [FIELDNAMES.CREDIT_CHECK]:
    required(formData[FIELDNAMES.CREDIT_CHECK]) &&
    'Please accept the agreements',
});
