import { Fragment } from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';
import CartSummary from '../../components/CartSummary/CartSummary.container';
import Textarea from '../../components/Form/Textarea/Textarea';
import IconBlock from '../../components/IconBlock/IconBlock';
import {
  FIELDNAMES,
  SCREENS,
  OPTIONS,
  type Screen,
  SCREEN_VALIDATION_ERROR_MESSAGE
} from '../Screen.constants';
import { type AdditionalAuthPayload } from '../Screen.selectors';
import ChoiceInput from '../../components/ChoiceInput/ChoiceInput';
import ProgressTracker from '../../components/ProgressTracker/ProgressTracker.container';
import { type CMSReview } from '../../state/content/content.constants';
import { type Offer } from '../../state/offer/offer.constants';
import InputWrapper from '../../components/InputWrapper/InputWrapper.container';
import ErrorMessage from '../../components/InputError/InputErrorMessage';
import Icon from '../../../../components/Icon/Icon';
import InputErrorBlock from '../../components/InputError/InputErrorBlock.container';
import InputErrorMessage from '../../components/InputError/InputErrorMessage.container';

type ReviewScreenProps = {
  prevScreen: () => void;
  goToScreen: (Screen) => void;
  handleSubmit: () => void;
  submitting: boolean;
  fullName: string;
  mobilePhone: string;
  emailAddress: string;
  dateOfBirth: string;
  additionalAuthDetails?: AdditionalAuthPayload;
  chosenPaymentMethod: typeof OPTIONS.PAYMENT_METHOD[keyof typeof OPTIONS.PAYMENT_METHOD];
  bankName: string;
  bankAccountHolder: string;
  bankAccount: string;
  isNowOffice: boolean;
  cms: CMSReview;
  submitFailed: boolean;
  invalid: boolean;
  offer: Offer;
  showContractCheckbox: boolean;
  choosenContract: number;
  directDebitTermsPDF: string;
};


export default function ReviewScreen({
  prevScreen,
  goToScreen,
  handleSubmit,
  submitting,
  fullName,
  mobilePhone,
  emailAddress,
  dateOfBirth,
  additionalAuthDetails,
  chosenPaymentMethod,
  bankName,
  bankAccountHolder,
  bankAccount,
  isNowOffice,
  cms,
  submitFailed,
  invalid,
  offer,
  showContractCheckbox,
  choosenContract,
  directDebitTermsPDF,
}: ReviewScreenProps) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="banner">
        <div className="banner__content line-length-large">
          <h1
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cms && cms.screen && cms.screen.heading,
            }}
          />
        </div>
      </div>
      {/* @ts-ignore */}
      <ProgressTracker />
      <div className="section section--no-top@small">
        <div className="grid-flex grid--row-spacing-s grid--col-spacing-xxl">
          <div className="width-3/7@large">
            {/* @ts-ignore */}
            <CartSummary />
          </div>
          <div className="width-1/2@medium width-2/7@large">
            <IconBlock icon="icon-personal-details" title="Your details">
              <button
                type="button"
                className="link link--underline u-text-s"
                onClick={() => {
                  goToScreen(SCREENS.PersonalAccount);
                }}
              >
                Edit
              </button>
            </IconBlock>
            <p>
              <strong>Personal details</strong>
              <br />
              {fullName}
              <br /> {mobilePhone}
              <br /> {emailAddress}
              <br />
              {dateOfBirth}
            </p>
            <p>
              <strong>Additional authority</strong>
              <br />
              {additionalAuthDetails ? (
                <Fragment>
                  {additionalAuthDetails.firstName}{' '}
                  {additionalAuthDetails.lastName}
                  <br />
                  {additionalAuthDetails.mobilePhone}
                </Fragment>
              ) : (
                'None'
              )}
            </p>
          </div>
          <div className="width-1/2@medium width-2/7@large">
            <IconBlock icon="icon-pay" title="Payment details" size="lg">
              <button
                type="button"
                className="link link--underline u-text-s"
                onClick={() => {
                  goToScreen(SCREENS.PaymentOption);
                }}
              >
                Edit
              </button>
            </IconBlock>
            <p>
              <strong>Direct Debit Instruction</strong>
              {chosenPaymentMethod === OPTIONS.PAYMENT_METHOD.DIRECT_DEBIT ? (
                <Fragment>
                  <br />
                  {bankName}, {bankAccountHolder}
                  <br /> {bankAccount}
                  <br /> Pay monthly
                  <br />{new Date().toLocaleDateString()}
                </Fragment>
              ) : (
                <Fragment>
                  <br />
                  NA
                </Fragment>
              )}
            </p>
            <p>
              <strong>Confirmation Details:</strong>
              <br />
              {emailAddress}
            </p>
            <p>
              <strong>Creditor Details:</strong>
              <br />
              NOW NZ
            </p>
            <p>
              <strong>Direct Debit Authorisation Code:</strong>
              <br />
              1220559
              <br />
              See full direct debit {' '}
              <a
                href={directDebitTermsPDF}
                className="link--plain u-text-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
            </p>
          </div>
          <div className="width-2/3@large">
            <div className="line-length">
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cms && cms.screen && cms.screen.description,
                }}
              />
            </div>
            {isNowOffice && (
              <div className="line-length">
                <h3>CSR ID</h3>
                <Field
                  type="text"
                  component="input"
                  name={FIELDNAMES.CSR_ID}
                  className="input"
                />
              </div>
            )}
            <div className="section section--stacked line-length">
              <h3>Anything else we need to know?</h3>
              <p className="u-text-s">
                If you need to tell us anything else relating to your connection
                please enter it below.
              </p>
              <Textarea
                name={FIELDNAMES.OTHER_NOTES}
                helptext=""
                className="width-full u-mb-xl"
                placeholder="Enter your notes here"
              />
            </div>
            {offer && offer.is_referral_code && (
              <div className="section section--stacked line-length">
                <h3>Who referred you to NOW?</h3>
                <p className="u-text-s">
                  To ensure your friend also gets $100 account credit, let us
                  know who sent you.
                </p>
                <InputWrapper
                  label="Friend’s name"
                  name={FIELDNAMES.FRIEND_NAME}
                  content={({ error }) => (
                    <Field
                      type="text"
                      component="input"
                      name={FIELDNAMES.FRIEND_NAME}
                      className={classnames('input', error && 'input--error')}
                    />
                  )}
                />
              </div>
            )}

            <div className="section section--stacked line-length">
              <h3>Our agreement</h3>
              <p className="u-text-s u-mb-m">
                We like to be transparent and upfront, so all the things you
                need to know are in our{' '}
                <a
                  href="/terms-conditions"
                  className="link--underline"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
                .
              </p>
              <InputErrorBlock
                names={[FIELDNAMES.CONTRACT_AGREEMENT, FIELDNAMES.CREDIT_CHECK]}
                size="sm"
              >
                <InputErrorMessage
                  names={[
                    FIELDNAMES.CONTRACT_AGREEMENT,
                    FIELDNAMES.CREDIT_CHECK,
                  ]}
                />
                <ChoiceInput
                  type="checkbox"
                  name={FIELDNAMES.CONTRACT_AGREEMENT}
                  value={OPTIONS.CONTRACT_AGREEMENT.YES}
                  label={
                    showContractCheckbox
                      ? `I agree to a ${choosenContract} month contract and the NOW terms and conditions`
                      : 'I agree to the NOW Terms and Conditions'
                  }
                  sublabel=""
                />
                <ChoiceInput
                  type="checkbox"
                  name={FIELDNAMES.CREDIT_CHECK}
                  value={OPTIONS.CREDIT_CHECK.YES}
                  label="I agree to NOW using my details for the purpose of a credit check"
                  sublabel=""
                />
              </InputErrorBlock>
              <p className="u-text-s u-mb-m">
                By signing and/or providing us with a valid instruction in respect to your Direct Debit Instruction, you
                have understood and agreed to the terms and conditions governing the debit arrangements between you and
                NOW NZ as set out in this Instruction. You agree to execute this document by electronic signature and you
                are aware that by electronically signing this document you are executing a legally binding document.
              </p>
            </div>
            <div className="section section--stacked">
              <div className="line-length">
                <p>
                  <button
                    type="button"
                    className="btn btn--hollow-dark"
                    onClick={prevScreen}
                    data-key="BackPayment"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn u-float-right"
                    disabled={submitting}
                    data-key="NextSuccess"
                    id="signup"
                  >
                    {submitting ? (
                      <Icon
                        title="Submitting..."
                        type="icon-loading"
                        className="i--medium i--vertical-middle"
                      />
                    ) : (
                      'Confirm'
                    )}
                  </button>
                </p>
                {submitFailed && invalid && (
                  <ErrorMessage
                    className="u-text-right"
                    error={SCREEN_VALIDATION_ERROR_MESSAGE}
                  />
                )}
              </div>
            </div>
            <div className="line-length">
              <div
                className="u-text-s"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cms && cms.disclaimer && cms.disclaimer.description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
