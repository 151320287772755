//

import { type Package } from './package.constants';

export const FETCH_PACKAGE = 'Package@FETCH_PACKAGE';
export const FETCH_PACKAGE_SUCCESS = 'Package@FETCH_PACKAGE_SUCCESS';
export const FETCH_PACKAGE_FAILED = 'Package@FETCH_PACKAGE_FAILED';

export type FetchPackageAction = {
  type: typeof FETCH_PACKAGE,
  id: string,
};

export function fetchPackage(id: string): FetchPackageAction {
  return { type: FETCH_PACKAGE, id };
}

export type FetchPackageSuccessAction = {
  type: typeof FETCH_PACKAGE_SUCCESS,
  payload: Package,
};

export function fetchPackageSuccess(
  payload: Package,
): FetchPackageSuccessAction {
  return { type: FETCH_PACKAGE_SUCCESS, payload };
}

export type FetchPackageFailedAction = {
  type: typeof FETCH_PACKAGE_FAILED,
  error: string,
};

export function fetchPackageFailed(error: string): FetchPackageFailedAction {
  return { type: FETCH_PACKAGE_FAILED, error };
}
