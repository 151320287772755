import type { ErrorType } from '../../ErrorPage/ErrorPage.constants';

export const FETCH_AVAILABILITY = 'Screen@FETCH_AVAILABILITY';
export const FETCH_AVAILABILITY_SUCCESS = 'Screen@FETCH_AVAILABILITY_SUCCESS';
export const FETCH_AVAILABILITY_FAILED = 'Screen@FETCH_AVAILABILITY_FAILED';

export type FetchAvailabilityAction = {
  type: typeof FETCH_AVAILABILITY;
  addressId: string;
};

export function fetchAvailability(addressId: string): FetchAvailabilityAction {
  return { type: FETCH_AVAILABILITY, addressId };
}

export type FetchAvailabilitySuccessAction = {
  type: typeof FETCH_AVAILABILITY_SUCCESS;
  payload;
};

export function fetchAvailabilitySuccess(
  payload,
): FetchAvailabilitySuccessAction {
  return {
    type: FETCH_AVAILABILITY_SUCCESS,
    payload,
  };
}

export type FetchAvailabilityFailedAction = {
  type: typeof FETCH_AVAILABILITY_FAILED;
  error: string;
  errorType: ErrorType;
};

export function fetchAvailabilityFailed(
  error: string,
  errorType: ErrorType,
): FetchAvailabilityFailedAction {
  return { type: FETCH_AVAILABILITY_FAILED, error, errorType };
}
