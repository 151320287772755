//

import { createSelector } from 'reselect';
import { cmsSelector } from '../../state/content/content.selectors';

// eslint-disable-next-line import/prefer-default-export
export const successCMSSelector = createSelector(
  cmsSelector,
  (cms) => cms && cms.success,
);
